import styled from '@emotion/styled';

import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from 'lib/dropdown';
import { dialogBoxShadow } from 'theme/utils/commonStyles';

export const Content = styled(DropdownMenuContent)`
  z-index: 3000;
  min-width: 260px;
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.dina.borderResting};
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  ${dialogBoxShadow};
  animation: 0.2s ease-in-out 0s 1 normal none running animate-in;
  animation: 0.2s ease-in-out 0s 1 normal none running fade-out-0;
  animation: 0.2s ease-in-out 0s 1 normal none running zoom-out-95;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-top-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-right-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-left-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-bottom-2;
`;

export const Label = styled(DropdownMenuLabel)`
  ${({ theme }) => theme.typography.dina.h7};
  display: flex;
  align-items: center;
  padding-block: 4px;
`;

export const Separator = styled(DropdownMenuSeparator)<{ horizontalInset?: boolean }>`
  margin-left: ${({ horizontalInset }) => (horizontalInset ? '0' : '-12px')};
  margin-right: ${({ horizontalInset }) => (horizontalInset ? '0' : '-12px')};
  margin-block: 6px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.borderResting};
`;

export const MenuItem = styled(DropdownMenuItem)`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  padding: 6px 8px;
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  outline: none;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const Checkbox = styled('input')`
  height: 18px;
  width: 18px;
  margin-right: 8px;
`;
