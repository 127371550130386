import styled from '@emotion/styled';

import { InstanceWithRightCollapsiblePane } from 'features/instance/Instance';
import { HStack } from 'layouts/box/Box';
import { Instance } from 'types';

import type { PreviewProps } from '../utils/childMap';

const StyledHStack = styled(HStack)`
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
`;

export default function InstanceDrawer({ member, onClose }: Readonly<PreviewProps>) {
  if (!member) {
    return <div>No preview found</div>;
  }

  return (
    <StyledHStack height="100%">
      <InstanceWithRightCollapsiblePane
        instance={member as Instance}
        autoUpdate
        isSearchItem
        view="preview"
        onClose={onClose}
        onOpen={onClose}
      />
    </StyledHStack>
  );
}
