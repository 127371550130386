import { useCallback, useContext, useEffect, useMemo } from 'react';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import UserContext from 'contexts/UserContext';
import useGetOrdersWithMdf from 'features/print/hooks/useGetOrdersWithMdf';
import { OrderWithLabel } from 'features/sidepanel/ComponentUtils';
import { useGetOrdersForEntities } from 'screens/space/api/useGetOrdersAndForms';
import { GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

import { getOrderContent } from './useCopyPlanning';
import useCopyText from './useCopyText';
import useGetUser from './useGetUser';

const useCopyTasks = (tasks: OrderWithLabel[]) => {
  const { groups } = useContext(UserContext);
  const { getUserTitle, getUser } = useGetUser();
  const { onCopy: copyToClipboard } = useCopyText();

  const { mdfs, mdfsSeparated, loading, error } = useGetMdfs({ all: true });

  const mIds = useMemo(() => tasks.map((task) => task.mId), [tasks]);

  const {
    orders: subTasks,
    loading: subTasksLoading,
    error: subTasksError,
    refetch: refetchOrders,
  } = useGetOrdersForEntities(mIds, GetOrderEnum.Resource, TaskStatusEnum.all);

  useEffect(() => {
    refetchOrders().then(
      () => {},
      () => {},
    );
  }, [tasks, refetchOrders]);

  const { ordersWithMdf: tasksWithMdf } = useGetOrdersWithMdf(tasks, mdfs, mdfsSeparated);
  const { ordersWithMdf: subtasksWithMdf } = useGetOrdersWithMdf(subTasks, mdfs, mdfsSeparated);

  const onCopy = useCallback(async () => {
    if (subTasksLoading || loading || !subtasksWithMdf || subTasksError || error) return;

    const allContent = `${tasksWithMdf
      .map((task, index) => {
        return getOrderContent({
          order: task,
          groups,
          index,
          subMdfs: mdfsSeparated.subTypes,
          subOrders: subtasksWithMdf,
          getUserTitle,
          getUser,
        });
      })
      .join('\n')}`;

    await copyToClipboard(allContent, 'Items copied to clipboard');
  }, [
    copyToClipboard,
    error,
    getUserTitle,
    groups,
    loading,
    mdfsSeparated.subTypes,
    subTasksError,
    subTasksLoading,
    subtasksWithMdf,
    tasksWithMdf,
  ]);

  return { onCopy, loading: loading || subTasksLoading };
};

export default useCopyTasks;
