import { useCallback, useEffect, useState } from 'react';
import { DocumentNode } from '@apollo/client';

import { Block } from 'api/mdfBlocks/types';
import useApolloSubscription from 'hooks/useApolloSubscription';
import useBatchGetMembers from 'hooks/useBatchGetRundownInstances';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'operations/subscriptions/notifyMemberUpdate';
import updateCacheWithTransaction from 'operations/utils/cache/notification/transaction/updateCacheWithTransaction';
import { Rundown } from 'types/graphqlTypes';

interface ApolloClientType {
  query: (props: {
    query: DocumentNode;
    variables: { input: Record<string, string> };
    fetchPolicy: string;
  }) => void;
}

interface SubscriptionData {
  data: {
    notifyMemberUpdateSubscription: Rundown | Block;
  };
}
interface LoadInstancesProps {
  rundownId: string;
}

export default function useLoadInstances({ rundownId }: LoadInstancesProps) {
  const [loadItems] = useBatchGetMembers();
  const [isLoading, setLoading] = useState(true);

  // Subscribe
  const [subscribe, unSubscribe] = useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: rundownId,
    },
    skip: !rundownId,
    source: 'useLoadInstances',
    onSubscriptionData: ({
      client,
      subscriptionData,
    }: {
      client: ApolloClientType;
      subscriptionData: SubscriptionData;
    }) => {
      const item = subscriptionData.data.notifyMemberUpdateSubscription;
      if (item) updateCacheWithTransaction(client, item);
    },
  });

  // Load instances by id. Populates the cache.
  const loadInstances = useCallback(
    async (instanceIds: string[]) => {
      setLoading(true);
      await loadItems(instanceIds);
      setLoading(false);
    },
    [loadItems],
  );

  // Subscribe on rundownId change
  useEffect(() => {
    subscribe();
    return () => {
      unSubscribe();
    };
  }, [rundownId]);

  return { loadInstances, isLoading };
}
