import useCustomMemo from 'hooks/useCustomMemo';
import { Order, OrderWithMdf } from 'types/forms/forms';
import { Mdf } from 'types/graphqlTypes';
import { getDefaultValues } from 'utils/mdf/utils';

const useGetOrdersWithMdf = (
  orders: Order[],
  mdfs: Mdf[],
  mdfsSeparated: {
    defaults: Mdf[];
    custom: Mdf[];
    subTypes: Mdf[];
    instances: Mdf[];
  },
) => {
  const mdfsMap = useCustomMemo(
    () =>
      mdfs.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {} as { [key: string]: Mdf }),
    [mdfs],
  );

  const ordersWithMdf: OrderWithMdf[] = useCustomMemo(() => {
    return orders.map((order) => {
      const { mdfId } = order;
      const mdf = mdfsMap[mdfId];

      if (mdf) {
        const defaultValues = getDefaultValues(order.metadata, mdf, mdfsSeparated.subTypes);
        return { ...order, mdf, metadata: { ...order.metadata, ...defaultValues } };
      }

      return { ...order, mdf } as OrderWithMdf;
    });
  }, [mdfsMap, mdfsSeparated.subTypes, orders]);

  return { ordersWithMdf };
};

export default useGetOrdersWithMdf;
