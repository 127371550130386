import { useCallback } from 'react';

import Debounce from 'components/debounce';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import MetadataComponent from 'components/metadata';
import useToast from 'components/toast/useToast';
import useInstanceCore from 'features/instance/hooks/useInstanceCore';
import useInstanceMdf from 'features/instance/hooks/useInstanceMdf';
import useInstanceMetadata from 'features/instance/hooks/useInstanceMetadata';
import { useInstanceMolecule } from 'features/instance/store/instance';
import { MMetaDataField } from 'types/graphqlTypes';

function Metadata() {
  const { errorToast } = useToast();
  const { useDisableEdit, useInstanceValue } = useInstanceMolecule();
  const { mdf, metadata, errorMap, updateFieldValues } = useInstanceMdf();
  const { fields, pairedMetadata, parameterFields } = useInstanceMetadata();
  const instance = useInstanceValue();
  const [disableEdit] = useDisableEdit();
  const { onMetadataChanged } = useInstanceCore();

  const onUpdateMetadata = useCallback(
    async (newMetadata: MMetaDataField[]) => {
      if (instance) {
        onMetadataChanged(newMetadata, instance).catch(errorToast);
      }
    },
    [instance],
  );

  return (
    <Debounce ms={200}>
      {mdf ? (
        <MdfEditor
          style={{ padding: '10px', overflow: 'auto' }}
          fields={mdf.fields}
          defaultLayoutSettings={mdf.views.default}
          layoutSettings={[]}
          metadata={metadata ?? {}}
          permissions={mdf.permissions}
          view="default"
          errorMap={errorMap}
          updateFieldValue={updateFieldValues}
        />
      ) : (
        <MetadataComponent
          fields={fields}
          metadata={pairedMetadata}
          parameterFields={parameterFields}
          onUpdateMeta={onUpdateMetadata}
          disableEdit={disableEdit}
          showHeader={false}
          onBackClick={undefined}
          usage={undefined}
        />
      )}
    </Debounce>
  );
}

export default Metadata;
