import { css } from '@emotion/react';
import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

interface ViewerStyleProps {
  $noBorderRadius?: boolean;
}

export const Article = styled('article', transientOptions)<ViewerStyleProps>`
  ${({ theme }) => css`
    background: ${theme.palette.dina.whiteHighEmphasis};
    box-shadow: ${theme.palette.mode === 'light'
      ? '0px 8px 8px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.15)'
      : '0px 8px 8px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)'};
  `}
  border-radius: ${({ $noBorderRadius }) => ($noBorderRadius ? '0' : '8px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  img {
    max-width: 100%;
  }
`;

export const Sections = styled('div')`
  padding-bottom: 56px;
  width: 100%;
`;

export const ButtonWrapper = styled('div')`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.dina.dividerLight};
    background: ${theme.palette.dina.storyTimelineBackground};
  `}
  position: sticky;
  bottom: 0;
  min-height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 0 0 8px 8px;
  gap: 5px;
  button {
    margin: 0;
  }
`;
