import { isObject } from 'lodash';

import { SearchParameters } from 'api/search/useSearch';
import { DefaultMdfTypes, SortOption } from 'features/command/command-types';
import { MdfIds } from 'types';
import { FieldValue, Metadata, MiniMember } from 'types/forms/forms';
import {
  MemberType,
  MemberTypeEnum,
  RangeBy,
  SearchItemTypeEnum,
  SortDirection,
} from 'types/graphqlTypes';

export const MAX_RELATIONS = 25;

export type MemberKeys = Pick<MemberType, 'mId' | 'mRefId'>;

/**
 * These mTypes are members that are connected to another mType via mId.
 */
export const connectedMTypes = [
  MemberTypeEnum.Asset,
  MemberTypeEnum.Block,
  MemberTypeEnum.Order,
  MemberTypeEnum.OrderForm,
];

export const getMiniKeys = (member: MemberType, swapKeys: boolean) => {
  const idKey: keyof MemberType = swapKeys ? 'mRefId' : 'mId';
  const connectedKey: keyof MemberType = swapKeys ? 'mId' : 'mRefId';
  return { id: member[idKey]!, connectedId: member[connectedKey]!, type: member.mType! };
};

export const getKeys = (miniMember: MiniMember, swapKeys: boolean) => {
  const idKey: keyof MiniMember = swapKeys ? 'connectedId' : 'id';
  const connectedKey: keyof MiniMember = swapKeys ? 'id' : 'connectedId';
  return { mId: miniMember[idKey]!, mRefId: miniMember[connectedKey]! };
};

export const toMiniMember = (member: MemberType): MiniMember => {
  return getMiniKeys(member, connectedMTypes.includes(member.mType!));
};

export const getKeysFromMiniMember = (miniMember: MiniMember): MemberKeys => {
  return getKeys(miniMember, connectedMTypes.includes(miniMember.type));
};

export const isMiniMember = (value: unknown): value is MiniMember => {
  if (!value) return false;
  if (typeof value !== 'object') return false;
  const maybeMiniMember = value as Partial<MiniMember>;
  return typeof maybeMiniMember.id === 'string' && typeof maybeMiniMember.type === 'string';
};

export const isMiniMemberArray = (value: unknown): value is MiniMember[] => {
  if (!Array.isArray(value)) return false;
  return value.every(isMiniMember);
};

export const getInputFromValue = (value: FieldValue): MemberKeys[] | undefined => {
  if (isMiniMemberArray(value)) {
    return value.map(getKeysFromMiniMember);
  }
};

export const isSearchParameters = (val: unknown): val is SearchParameters => {
  if (!isObject(val)) return false;
  const maybe = val as Partial<SearchParameters>;
  return maybe.toolbarState !== undefined;
};

export const toSearchFilterProps = (val: SearchParameters): SearchFilterProps => {
  return {
    types: val.toolbarState.mTypes ?? undefined,
    mdfId: val.toolbarState.defaultMdfId ?? val.toolbarState.mdfId ?? undefined,
    statusList: val.toolbarState.statusFilter,
    assignedMemberIds: val.toolbarState.assignedIds,
    createdByIds: val.toolbarState.createdByIds,
    searchString: val.searchString,
    metaDataFilter: val.metadataFilter,
    rangeBy: val.toolbarState.rangeBy ?? undefined,
    orderBy: val.toolbarState.sortBy ?? undefined,
    order: val.toolbarState.order,
  };
};

const isDefaultMdfId = (val?: string): val is DefaultMdfTypes => {
  return MdfIds.includes(val as DefaultMdfTypes);
};

export const toSearchParameters = (val: SearchFilterProps): SearchParameters => {
  const defaultMdfId = isDefaultMdfId(val.mdfId) ? val.mdfId : null;

  return {
    searchString: val.searchString ?? '',
    skip: false,
    metadataFilter: val.metaDataFilter,
    toolbarState: {
      defaultMdfId,
      assignedIds: val.assignedMemberIds ?? [],
      createdByIds: val.createdByIds ?? [],
      isFiltering: true,
      mdfId: val.mdfId ?? null,
      mTypes: val.types ?? [],
      order: val.order ?? 'desc',
      rangeBy: val.rangeBy ?? null,
      sortBy: val.orderBy ?? 'best',
      statusFilter: val.statusList ?? [],
    },
  };
};

export interface SearchFilterProps {
  types?: SearchItemTypeEnum[];
  mdfId?: string;
  statusList?: string[];
  assignedMemberIds?: string[];
  createdByIds?: string[];
  searchString?: string;
  metaDataFilter?: Metadata;
  rangeBy?: RangeBy;
  orderBy?: SortOption;
  order?: SortDirection;
}
