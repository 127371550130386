/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import { useDrag } from 'react-dnd';

import { useAddFeedToStory } from 'features/feedViewer/api';
import DndTypes from 'utils/dndTypes';
import { onImageLoadingError } from 'utils/image/imageUtils';
import { ReactComponent as DropdownIcon } from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet.svg';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import PriorityMinimal from 'components/priorityLabel';
import Badge from 'components/badge';

import {
  FeedItem,
  Item,
  Thumbnail,
  TextWrapper,
  Title,
  Updated,
  Content,
  Metadata,
  SectionChip,
  ImageIcon,
  VideoIcon,
  DocIcon,
  AudioIcon,
  Associations,
  AssetIcons,
  AddButton,
  Button,
  Association,
  StoryCount,
  IconWrapper,
} from './styled';

const assetIconSelector = (type) => {
  if (type === 'image')
    return (
      <IconWrapper>
        <ImageIcon />
      </IconWrapper>
    );
  if (type === 'video') return <VideoIcon />;
  if (type === 'document') return <DocIcon />;
  if (type === 'audio') return <AudioIcon />;

  return null;
};
function ListItem({ item, children, priority, onClick, setSelected, selected }) {
  const isBreaking = priority === '1';
  const [addFeedToStory] = useAddFeedToStory();

  const handleDrop = async ({ storyId, storyType }) => {
    await addFeedToStory({ provider: item.provider, feedId: item.mId, storyId, storyType });
  };

  const [, dragRef] = useDrag({
    canDrag: () => selected,
    type: DndTypes.FEED_ITEM,
    item: {
      type: DndTypes.FEED_ITEM,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (_, monitor) => {
      if (!monitor.didDrop()) return;
      if (monitor.getDropResult().storyId) handleDrop(monitor.getDropResult());
    },
  });

  return (
    <FeedItem
      $isBreaking={isBreaking}
      role="button"
      key={item.mId}
      onClick={onClick}
      onMouseDown={() => setSelected(item)}
      ref={selected ? dragRef : null}
    >
      <Item>{children}</Item>
    </FeedItem>
  );
}

ListItem.Thumbnail = function ListItemThumbnail({ src }) {
  return (
    <Thumbnail
      loading="lazy"
      alt="Story thumbnail"
      src={src || fallbackImage}
      onError={onImageLoadingError}
    />
  );
};

ListItem.Content = function ListItemContent({ children }) {
  return <Content>{children}</Content>;
};

ListItem.Metadata = function ListItemMetadata({
  priority,
  provider,
  section,
  revision = 0,
  children,
}) {
  return (
    <Metadata>
      <>
        {priority && priority === '1' && revision > 1 && (
          <Badge>{`${revision} update${revision > 1 ? 's' : ''}`}</Badge>
        )}
        {priority && <PriorityMinimal priorityLevel={priority} type="minimal" />}
        {section && <SectionChip label={section} />}
        {provider}
      </>
      {children}
    </Metadata>
  );
};

ListItem.Associations = function ListItemAssociations({ item, onCreateClick, onStoryCountClick }) {
  const storyCount = uniq(item.storyList ?? []).length;

  return (
    <Associations>
      <AssetIcons>
        {item?.assetTypes?.map((type, count) => (
          <React.Fragment key={`${item.mRefId}-${type}-${count}`}>
            {assetIconSelector(type)}
          </React.Fragment>
        ))}
      </AssetIcons>
      <Association>
        {storyCount > 0 ? (
          <Button role="presentation" onClick={onStoryCountClick}>
            <StoryCount>{`${storyCount}x`}</StoryCount>
            <DropdownIcon />
          </Button>
        ) : (
          <Button role="presentation" onClick={onCreateClick}>
            <AddButton className="addButton skipOverride" />
          </Button>
        )}
      </Association>
    </Associations>
  );
};

ListItem.Text = function ListItemText({ children }) {
  return <TextWrapper>{children}</TextWrapper>;
};

ListItem.Title = function ListItemTitle({ children }) {
  return <Title>{children}</Title>;
};

ListItem.Updated = function ListItemUpdated({ children }) {
  return <Updated>{children}</Updated>;
};

ListItem.propTypes = {
  /** Children */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  /** on click  */
  onClick: PropTypes.func,
  /** priority value */
  priority: PropTypes.string,
};

ListItem.defaultProps = {
  onClick: () => {},
  priority: null,
};

export default ListItem;
