/* eslint-disable sort-imports */
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { GetMemberInput, OptionListRaw } from 'types/graphqlTypes';

import { STANDARD_OPTION_LIST_KEYS } from '../commonKeys';

import { generateIdForAlternatives } from './useGetOptionLists';

const GET_LIST = gql`
  query GetOptionList($input: GetMemberInput) {
    getOptionList(input: $input) {
      ${STANDARD_OPTION_LIST_KEYS}
    }
  }
`;

interface GetList {
  getOptionList: OptionListRaw;
}

interface GetMemberInputType {
  input: GetMemberInput;
}

export const useGetOptionList = (id: string | undefined | null, forceFetch = false) => {
  const { data, error, loading, refetch } = useQuery<GetList, GetMemberInputType>(GET_LIST, {
    variables: {
      input: {
        mId: id ?? '',
      },
    },
    fetchPolicy: forceFetch ? 'network-only' : 'cache-first',
    skip: !id,
  });

  const optionList = useMemo(() => {
    if (data?.getOptionList) {
      return generateIdForAlternatives(data.getOptionList);
    }
    return null;
  }, [data]);

  return { optionList, error, loading, refetch };
};
