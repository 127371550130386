import { useContext, useEffect, useRef } from 'react';
import { ApolloClient } from '@apollo/client';

import UserContext from 'contexts/UserContext';
import useApolloSubscription from 'hooks/useApolloSubscription';
import GET_MEMBER from 'operations/queries/getMember';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'operations/subscriptions/notifyMemberUpdate';
import { DailyNote } from 'types';

import updateDailyNoteCache from '../api/updateDailyNoteCache';
import { useCurrentDailyNote, useHasUpdatedSubscription, useSelectedDailyNoteDate } from '../store';
import getUTCDateString from '../utils/getUTCDateString';

const useDailyNoteSubscription = () => {
  const { mId: currentUserId } = useContext(UserContext);
  const [selectedDate] = useSelectedDailyNoteDate();
  const [currentDailyNote] = useCurrentDailyNote();
  const [, setHasUpdatedSubscription] = useHasUpdatedSubscription();

  const currentDailyNoteRef = useRef(currentDailyNote);
  const selectedMrefIdRef = useRef(getUTCDateString(selectedDate));

  const [subscribe, unSubscribe] = useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: 'dailyNote',
    },
    onSubscriptionData: ({
      client,
      subscriptionData,
    }: {
      client: ApolloClient<object>;
      subscriptionData: { data: { notifyMemberUpdateSubscription: DailyNote } };
    }) => {
      const updatedData = subscriptionData.data.notifyMemberUpdateSubscription;

      // return if current user is editing
      if (updatedData.locked && updatedData.locked === currentUserId) return;

      const cachedData = client.readQuery({
        query: GET_MEMBER,
        variables: {
          input: {
            mId: updatedData.mId,
            mRefId: updatedData.mRefId,
          },
        },
      }) as { getMember: DailyNote | null };

      if (!cachedData?.getMember) {
        // insert new cache item
        updateDailyNoteCache(client, updatedData);
      }

      // don't show badge when locked
      if (!currentDailyNoteRef.current?.locked && updatedData.locked) return;

      // update state to show badge & to refetch content
      if (updatedData.mRefId === selectedMrefIdRef.current) {
        setHasUpdatedSubscription(true);
      }
    },
    source: 'dailyNote',
  });

  useEffect(() => {
    selectedMrefIdRef.current = getUTCDateString(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    currentDailyNoteRef.current = currentDailyNote;
  }, [currentDailyNote]);

  useEffect(() => {
    subscribe();
    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useDailyNoteSubscription;
