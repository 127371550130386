import { memo, useCallback, useMemo, useState } from 'react';

import { Button } from 'components/buttons';
import Popover from 'components/dialogs/DialogBuilder';
import Slider from 'components/slider/Slider';
import Switch from 'components/switch/Switch';
import Text from 'components/text/Text';
import { HStack, VStack } from 'layouts/box/Box';

import { FilterComponentType, FilterProps } from './types';

interface KanbanFilterProps extends FilterProps {
  setWidthPreview: (width?: string) => void;
}

function KanbanFilter({
  filters = {},
  updateFilters,
  setWidthPreview,
}: Readonly<KanbanFilterProps>) {
  const { customWidth } = filters;

  const storedWidth: number | undefined = useMemo(() => {
    return customWidth as number;
  }, [customWidth]);

  const [widthValue, setWidthValue] = useState<number | undefined>(storedWidth);
  const [showCustomWidth, setShowCustomWidth] = useState(Boolean(storedWidth));
  const [open, setOpen] = useState(false);

  const resetWidth = useCallback(() => {
    setWidthValue(undefined);
    setWidthPreview(undefined);
    const resetFilters = { ...filters };
    delete resetFilters.customWidth;

    updateFilters({
      ...resetFilters,
    });
  }, [setWidthPreview, updateFilters]);

  const handleSwitch = useCallback(() => {
    setShowCustomWidth((prev) => {
      // Reset the value when switching back to default
      if (prev) resetWidth();

      return !prev;
    });
  }, []);

  const handleSlider = useCallback(
    (val: number) => {
      setWidthValue(val);
      setWidthPreview(`${val}px`);
    },
    [setWidthPreview],
  );

  const handleConfirmWidth = useCallback(
    (value: number | undefined) => {
      if (!value) return;
      setOpen(false);
      updateFilters({
        ...filters,
        customWidth: value,
      });
    },
    [updateFilters],
  );

  const handleCancelWidth = useCallback(
    (value: number | undefined) => {
      setOpen(false);
      setWidthPreview(value ? `${value}px` : undefined);
      setWidthValue(value);
    },
    [setWidthPreview],
  );

  const hasSetWidth = Boolean(widthValue);

  const getSwitchLabel = () => {
    if (!showCustomWidth) return 'Default';
    if (hasSetWidth) return `${widthValue}px`;

    return 'Not set (default)';
  };

  return (
    <VStack padding="50px" alignItems="flex-start">
      <Text variant="overline" style={{ textTransform: 'uppercase' }}>
        Width settings
      </Text>
      <HStack>
        <Switch disabled={false} selected={showCustomWidth} onClick={handleSwitch} />
        <Text variant="listItemLabel">{getSwitchLabel()}</Text>
      </HStack>
      {showCustomWidth && (
        <Button
          height={32}
          variant="outlined"
          usage="outlined"
          autoWidth
          onClick={() => setOpen(true)}
        >
          Adjust
        </Button>
      )}
      <Popover modal={false} open={open} onClose={() => handleCancelWidth(storedWidth)}>
        <Popover.Header>Widget width</Popover.Header>
        <Popover.Body>
          <Popover.Label>Width</Popover.Label>
          <VStack>
            <Slider
              range={[450, 2000]}
              value={hasSetWidth ? (widthValue as number) : 800}
              setValue={handleSlider}
            />
            <Text>{hasSetWidth ? widthValue : 800}px</Text>
          </VStack>
        </Popover.Body>
        <Popover.Footer>
          <Popover.CancelButton />
          <Popover.ConfirmButton label="Confirm" onClick={() => handleConfirmWidth(widthValue)} />
        </Popover.Footer>
      </Popover>
    </VStack>
  );
}

export const FilterComponent: FilterComponentType = memo(KanbanFilter) as FilterComponentType;
