import { useCallback, useEffect, useMemo } from 'react';
import { ApolloClient, useQuery } from '@apollo/client';

import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import useApolloSubscription from 'hooks/useApolloSubscription';
import GET_FEEDS from 'operations/queries/getFeeds';
import UPDATE_FEED from 'operations/subscriptions/updateFeed';
import {
  FeedsConnection,
  QueryGetFeedsArgs,
  SubscriptionUpdateFeedSubscriptionArgs,
  UpdateFeedInput,
} from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

interface FeedInput extends Omit<UpdateFeedInput, 'provider'> {
  provider: string;
}

type GetFeedReturnType = {
  getFeeds: FeedsConnection;
};

type UpdateCacheProps = {
  client: ApolloClient<object>;
  item: FeedInput;
  queryVariables: QueryGetFeedsArgs;
  logger: ReturnType<typeof useLogger>;
};

type GetFeedProps = {
  filters?: UpdateFeedInput;
  dateRange?: DateRange;
  freeText?: string;
  limit?: number;
  searchString?: string;
  selectedProviders: { mRefId: string }[];
  noSubscribe?: boolean;
  noSearch?: boolean;
};

type SubscriptionTypes = {
  client: ApolloClient<object>;
  subscriptionData: {
    data: {
      updateFeedSubscription: FeedInput;
    };
  };
};

/** Store feed subscription item in cache */
const updateCache = ({ client, item, queryVariables, logger }: UpdateCacheProps) => {
  try {
    const existing = client.readQuery<GetFeedReturnType, QueryGetFeedsArgs>({
      query: GET_FEEDS,
      variables: { ...queryVariables },
    });

    client.writeQuery<GetFeedReturnType, QueryGetFeedsArgs>({
      query: GET_FEEDS,
      variables: { ...queryVariables },
      data: {
        getFeeds: {
          ...existing?.getFeeds,
          items: [item],
        },
      },
    });
  } catch (error: unknown) {
    if (error instanceof Error) {
      logger.error(`Error updating cache: ${error.message}`);
    }
  }
};

/** Query and subscription hook for feeds */
const useGetFeed = ({
  filters = {},
  freeText = '',
  limit = 50,
  searchString = '',
  selectedProviders = [],
  dateRange,
  noSubscribe,
  noSearch,
}: GetFeedProps) => {
  const providerIds = selectedProviders.map((provider) => provider.mRefId);
  const freeTextSearch = useMemo(() => freeText || searchString, [freeText, searchString]);
  const logger = useLogger('useGetFeed hook');

  const queryVariables: QueryGetFeedsArgs = {
    input: {
      searchString: freeTextSearch,
      providers: [...providerIds],
    },
    limit,
  };

  if (dateRange?.startDate && queryVariables.input) {
    queryVariables.input.dateRange = {
      from: dateRange.startDate,
      to: dateRange.endDate,
    };
  }

  if (Object.keys(filters)?.length > 0 && queryVariables.input) {
    queryVariables.input.filters = filters;
  }

  const { data, error, loading, fetchMore, startPolling, stopPolling } = useQuery<
    GetFeedReturnType,
    QueryGetFeedsArgs
  >(GET_FEEDS, {
    variables: { ...queryVariables },
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !providerIds?.length || noSearch === true,
  });

  /** Subscription logic  */
  const subFilters = { ...filters, providers: providerIds };

  if (subFilters?.assets?.length) {
    const { assets } = subFilters;
    subFilters.assetTypes = assets.map((asset) => asset.type as string);
    delete subFilters.assets;
  }

  const subscriptionFilters: SubscriptionUpdateFeedSubscriptionArgs = {
    filters: JSON.stringify(subFilters),
  };

  const [subscribe, unsubscribe] = useApolloSubscription(UPDATE_FEED, {
    variables: subscriptionFilters,
    onSubscriptionData: ({ client, subscriptionData }: SubscriptionTypes) => {
      const item = subscriptionData?.data?.updateFeedSubscription;
      const willUpdateCache = providerIds.includes(item.provider);

      if (willUpdateCache) {
        updateCache({ client, item, queryVariables, logger });
      }
    },
  });

  const canSubscribe = providerIds?.length > 0 && !noSubscribe;

  // Start/stop subscription
  useEffect(() => {
    if (freeTextSearch || dateRange) {
      unsubscribe();
      return;
    }

    if (canSubscribe) subscribe();

    return () => {
      unsubscribe();
    };
  }, [freeTextSearch, canSubscribe, dateRange]);

  // Start polling on initial render
  useEffect(() => {
    startPolling(60000);

    return () => {
      stopPolling();
    };
  }, []);

  // Fetch more callback
  const fetchMoreHandler = useCallback(async () => {
    if (data?.getFeeds?.nextToken && !loading) {
      try {
        await fetchMore({
          variables: {
            input: { ...queryVariables.input, nextToken: data.getFeeds.nextToken },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;

            return {
              getFeeds: {
                nextToken: fetchMoreResult.getFeeds?.nextToken,
                items: [...(fetchMoreResult.getFeeds?.items ?? [])],
                __typename: 'FeedsConnection',
              },
            };
          },
        });
      } catch (err: unknown) {
        if (err instanceof Error) {
          logger.error(`Feeds: Error fetching more: ${err.message}`);
        }
      }
    }
  }, [data?.getFeeds?.nextToken, fetchMore, providerIds, freeTextSearch, queryVariables, loading]);

  const items = data?.getFeeds?.items ?? [];
  return { items, loading, error, fetchMore: fetchMoreHandler };
};

export default useGetFeed;
