import { useMemo } from 'react';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { BlockWithLabel } from 'api/mdfBlocks/types';
import { useGetBlocks } from 'api/mdfBlocks/useGetMdfBlocks';
import Dialog from 'components/dialog';
import { initialValues } from 'components/editor/constants';
import variants from 'components/editor/constants/types/editorVariants';
import LoadingIndicator from 'components/loadingIndicator';
import PdfViewer from 'components/pdfViewer/PdfViewer';
import useTextStorage from 'hooks/useTextStorage';
import { Box } from 'layouts/box/Box';
import { useGetOrdersForEntities } from 'screens/space/api/useGetOrdersAndForms';
import { useEditorCommandsKeyed } from 'store';
import { Story } from 'types';
import { GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

import ContentPrintDoc from './docs/ContentPrintDoc';
import useGetBlocksWithMdf from './hooks/useGetBlocksWithMdf';
import useGetOrdersWithMdf from './hooks/useGetOrdersWithMdf';

import { ErrorWrapper, LoadingWrapper, PrintViewWrapper } from './styled';

interface Props {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  story: Story;
}

const View = ({ story }: { story: Story }) => {
  const { data, loading, error } = useTextStorage(story.mContentKey);
  const [editorCommandsKeyed] = useEditorCommandsKeyed();

  const initialValue = initialValues(variants.GENERAL, undefined, undefined, false);

  const { mdfs, mdfsSeparated, loading: mdfsLoading, error: mdfsError } = useGetMdfs({ all: true });

  const { blocks, loading: blocksLoading, error: blocksError } = useGetBlocks(story.mId);

  const mIds = useMemo(() => blocks.map((block) => block.mRefId), [blocks]);

  const {
    orders,
    loading: ordersLoading,
    error: ordersError,
  } = useGetOrdersForEntities(mIds, GetOrderEnum.Resource, TaskStatusEnum.all);

  const blocksWithType: BlockWithLabel[] = useMemo(() => {
    return blocks.map((block) => {
      const cmd = editorCommandsKeyed[block.mSecId];
      return {
        ...block,
        commandLabel: cmd.mTitle ?? '',
        color: cmd.mColor,
      };
    });
  }, [blocks, editorCommandsKeyed]);

  const { blocksWithMdf } = useGetBlocksWithMdf(blocksWithType, mdfs, mdfsSeparated);
  const { ordersWithMdf } = useGetOrdersWithMdf(orders, mdfs, mdfsSeparated);

  if (loading || blocksLoading || mdfsLoading || ordersLoading)
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );

  if (error || blocksError || mdfsError || ordersError) {
    const errors = {
      error: error ?? null,
      blocksError: blocksError ?? null,
      mdfsError: mdfsError ?? null,
      ordersError: ordersError ?? null,
    };

    return (
      <ErrorWrapper>
        <Box>Encountered following error while printing</Box>
        <Box>
          <pre>{JSON.stringify(errors, null, 2)}</pre>
        </Box>
      </ErrorWrapper>
    );
  }

  return (
    <PrintViewWrapper>
      <PdfViewer>
        <ContentPrintDoc
          content={data ?? initialValue}
          story={story}
          blocks={blocksWithMdf}
          orders={ordersWithMdf}
          mdfsSeparated={mdfsSeparated}
        />
      </PdfViewer>
    </PrintViewWrapper>
  );
};

const ContentPrint = ({ isDialogOpen, onCloseDialog, story }: Props) => {
  return (
    <Dialog container={undefined} open={isDialogOpen} onClose={onCloseDialog}>
      <View story={story} />
    </Dialog>
  );
};

export default ContentPrint;
