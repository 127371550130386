import type { CSSProperties } from 'react';
import { useMemo } from 'react';
import { horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { TableRow } from 'components/dataTable/Table';
import { Cell } from 'features/grids/common/components/TableCell';
import { getMdfByMTypeFromMdfs } from 'features/grids/common/components/utils/mdfUtils';
import { DataTableRow } from 'features/grids/common/types';
import useMetadata from 'hooks/useMetadata';
import { Mdf } from 'types/graphqlTypes';
import type { ParsedMemberType } from 'types/members';

export function DndRow({
  table,
  row,
  onClick,
  rowVirtualizer,
  virtualColumns,
  mdfs,
  virtualRow,
}: Readonly<DataTableRow<ParsedMemberType>>) {
  // ROW DRAG AND DROP
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.mId,
  });

  const styles: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    background: isDragging ? '#0A73EB' : 'revert-layer',
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
    outline: row.getIsSelected() ? '2px solid #0A73EB' : 'none',
    boxShadow: isDragging ? '0 5px 10px 0px rgba(0, 0, 0, 0.3)' : 'none',
  };

  const original = row.original;
  const mdf: Mdf | undefined = useMemo(() => {
    if (original.mdfId) return mdfs?.find((m) => m.id === original.mdfId);
    return getMdfByMTypeFromMdfs(original, mdfs);
  }, [mdfs, row.original]);

  const { metadata, errorMap, updateFieldValues } = useMetadata(
    mdf,
    original?.metadata,
    'default',
    original,
  );

  const visibleCells = row.getVisibleCells();
  const columnOrder = table.getState().columnOrder;

  return (
    <div ref={setNodeRef}>
      <TableRow
        onClick={() => onClick && onClick(row.original)}
        onDoubleClick={() => table.options.meta?.onDoubleClick?.(row.original)}
        key={row.id}
        onContextMenu={(event) => table.options.meta?.onContextMenu?.(event, row.original)}
        className={onClick ? 'cursor-pointer' : ''}
        data-index={virtualRow.index} // needed for dynamic row height measurement
        ref={(node) => rowVirtualizer.measureElement(node)} // measure dynamic row height
        style={{
          display: 'flex',
          position: 'absolute',
          transform: `translateY(${virtualRow.start}px)`,
          width: '100%',
          ...styles,
        }}
        {...attributes}
        {...listeners}
      >
        {virtualColumns.map((vc) => {
          const cell = visibleCells[vc.index];
          return (
            <SortableContext
              key={cell.id}
              items={columnOrder}
              strategy={horizontalListSortingStrategy}
            >
              <Cell
                cell={cell}
                key={cell.id}
                row={row}
                mdf={mdf}
                metadata={metadata}
                errorMap={errorMap}
                updateFieldValues={updateFieldValues}
              />
            </SortableContext>
          );
        })}
      </TableRow>
    </div>
  );
}
