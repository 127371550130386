import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const rundownFocusScopes = {
  ASSETS: 'assets',
  READY_LIST: 'readyInstances',
  PREPARING_LIST: 'preparingInstances',
};

/* Atom for keeping track of current selected instances from rundown */
const selectedInstancesAtom = atom<string[]>([]);
export const useSelectedInstances = () => useAtom(selectedInstancesAtom);

const instanceLockedIdAtom = atom<string | null>(null);
export const useInstanceLockedId = () => useAtom(instanceLockedIdAtom);

const saveDialogOpenAtom = atom(false);
export const useSaveDialogOpen = () => useAtom(saveDialogOpenAtom);

const currentFocusAtom = atom('');
export const useCurrentFocus = () => useAtom(currentFocusAtom);

const refreshCurrentFocusAtom = atom(new Date().toISOString());
export const useRefreshCurrentFocus = () => useAtom(refreshCurrentFocusAtom);

const copiedInstanceIdsAtom = atom<string | null>(null);
export const useCopiedInstanceIds = () => useAtom(copiedInstanceIdsAtom);

const lastFocusedListAtom = atom('');
export const useLastFocusedList = () => useAtom(lastFocusedListAtom);

const showEditorSidepanel = atomWithStorage<boolean>('sidepanel:rundown', false);
export const useShowEditorSidepanel = () => useAtom(showEditorSidepanel);

const defaultEditorWidth = atom<number | undefined>(undefined);
export const useDefaultEditorWidth = () => useAtom(defaultEditorWidth);
