import gql from 'graphql-tag';

export default gql`
  mutation unLockMember($input: UnlockMemberInput) {
    unlockMember(input: $input) {
      mId
      mRefId
      mTitle
      mAvatarKey
      mThumbnailKey
      mThumbnailUrl
      mContentKey
      mCreatedAt
      mUpdatedAt
      mState
      locked
      mPublishingAt
      mCategories {
        label
        score
        categoryId
      }
    }
  }
`;
