import Skeleton from '@material-ui/lab/Skeleton';

import { HStack, VStack } from 'layouts/box/Box';

function MessagesSkeleton() {
  return (
    <VStack height="100%" width="100%" padding="12px 12px" justifyContent="flex-end" gap="12px">
      {[...Array(2).keys()].map((i) => (
        <VStack key={i} width="100%" gap="8px">
          <HStack width="100%" gap="8px" alignItems="flex-end">
            <Skeleton animation="wave" variant="rect" height={32} width={32} />
            <VStack width="100%" alignItems="flex-start">
              <Skeleton animation="wave" width="60%" />
              <Skeleton animation="wave" width="70%" />
              <Skeleton animation="wave" width="70%" />
            </VStack>
          </HStack>
          <HStack width="100%" gap="8px" alignItems="flex-end" justifyContent="end">
            <VStack width="100%" alignItems="flex-end">
              <Skeleton animation="wave" width="60%" />
              <Skeleton animation="wave" width="70%" />
              <Skeleton animation="wave" width="70%" />
            </VStack>
            <Skeleton animation="wave" variant="rect" height={32} width={32} />
          </HStack>
        </VStack>
      ))}
    </VStack>
  );
}

export default MessagesSkeleton;
