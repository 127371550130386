import { useCallback, useEffect, useState } from 'react';
import {
  closestCenter,
  // eslint-disable-next-line sort-imports
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  // eslint-disable-next-line sort-imports
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ReactComponent as DragHandle } from 'assets/icons/systemicons/unionDragHandle.svg';
import { DNDKitUseSortableProps } from 'types/dndkit/sortable';

import { DragHandleWrapper, ItemWrapper } from './styled';

export interface RenderItemProps extends DNDKitUseSortableProps {
  id: UniqueIdentifier;
}

interface ItemProps {
  id: UniqueIdentifier;
  renderItem: (renderProps: RenderItemProps) => JSX.Element | null;
}

export type SortableItem = UniqueIdentifier | { id: UniqueIdentifier };
export type SortableItems = SortableItem[];

interface SortableListProps {
  order: SortableItems;
  updateOrder: (newOrder: SortableItems) => void;
  renderItem?: (renderItemProps: RenderItemProps) => JSX.Element | null;
  direction?: 'vertical' | 'horizontal';
}

const DNDOptions = {
  modifiers: {
    vertical: [restrictToParentElement, restrictToVerticalAxis],
    horizontal: [restrictToParentElement, restrictToHorizontalAxis],
  },
  strategy: {
    vertical: verticalListSortingStrategy,
    horizontal: horizontalListSortingStrategy,
  },
};

const getId = (item: UniqueIdentifier | { id: UniqueIdentifier }) =>
  typeof item === 'object' ? item.id : item;

const Item = ({ id, renderItem }: ItemProps) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({ id });

  return renderItem
    ? renderItem({
        attributes,
        listeners,
        transform,
        transition,
        setNodeRef,
        setActivatorNodeRef,
        id,
      })
    : null;
};

function SortableList({
  order: _order = [],
  updateOrder,
  renderItem,
  direction = 'vertical',
}: Readonly<SortableListProps>) {
  const [order, setOrder] = useState(_order);
  useEffect(() => setOrder(_order), [_order]);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5,
    },
  });

  const sensors = useSensors(
    pointerSensor,
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      const oldIndex = order.findIndex((item) => getId(item) === active.id);
      const newIndex = order.findIndex((item) => getId(item) === over?.id);

      const updatedOrder = arrayMove(order, oldIndex, newIndex);
      setOrder(updatedOrder);
      updateOrder(updatedOrder);
    },
    [order, updateOrder],
  );

  const defaultRenderItem = useCallback((props: RenderItemProps) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, id } =
      props;

    return (
      <ItemWrapper
        $transform={CSS.Translate.toString(transform)}
        $transition={transition}
        tabIndex={0}
        ref={setNodeRef}
        {...attributes}
      >
        <DragHandleWrapper tabIndex={0} ref={setActivatorNodeRef} {...listeners}>
          <DragHandle />
        </DragHandleWrapper>
        {id}
      </ItemWrapper>
    );
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      modifiers={DNDOptions.modifiers[direction]}
    >
      <SortableContext items={order} strategy={DNDOptions.strategy[direction]}>
        {order.map((item) => (
          <Item key={getId(item)} id={getId(item)} renderItem={renderItem ?? defaultRenderItem} />
        ))}
      </SortableContext>
    </DndContext>
  );
}

export default SortableList;
