import { useEffect, useMemo } from 'react';

import useContentResolver from 'hooks/useContentResolver';

import { useInstanceMolecule } from '../store/instance';

const useLoadAndSetContent = () => {
  const {
    useInstanceValue,
    useInstanceContentData,
    useInstanceContentLoading,
    useInstanceContentRefetch,
    useSkipDownload,
    useWriteLock,
  } = useInstanceMolecule();

  const instance = useInstanceValue();
  const [, setInstanceContentData] = useInstanceContentData();
  const [, setInstanceContentLoading] = useInstanceContentLoading();
  const [, setInstanceContentRefetch] = useInstanceContentRefetch();
  const [writeLock] = useWriteLock();
  const [skip, setSkip] = useSkipDownload();

  const contentKey = useMemo(() => instance?.mContentKey, [instance?.mContentKey]);

  const { data, loading, refetch } = useContentResolver(
    contentKey,
    skip || !contentKey || writeLock,
  );

  useEffect(() => {
    if (data) {
      setInstanceContentData(data);
      setSkip(true);
    }
  }, [data, setInstanceContentData, setSkip]);

  useEffect(() => {
    // do not skip if content key updates
    setSkip(false);
  }, [contentKey, setSkip]);

  useEffect(() => {
    setInstanceContentLoading(loading);
  }, [loading, setInstanceContentLoading]);

  useEffect(() => {
    setInstanceContentRefetch(refetch);
  }, [refetch, setInstanceContentRefetch]);
};

export default useLoadAndSetContent;
