/* eslint-disable no-console */
import { memo } from 'react';
import { useDrag } from 'react-dnd';
import { uniqBy } from 'lodash';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { ReactComponent as Metadata } from 'assets/icons/search/metadata.svg';
import { ReactComponent as Planned } from 'assets/icons/search/planned_duration.svg';
import { ReactComponent as ScheduleOn } from 'assets/icons/systemicons/storyCard/scheduled_on.svg';
import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import { Icon } from 'components/memberIcon/MemberIcon';
import Tooltip from 'components/tooltip';
import { isHighlight } from 'features/command/command-types';
import { getAccountTitle, getMetadataValue, safeForHTML } from 'features/command/command-utils';
import { isRestricted } from 'features/storyHub/utils';
import useGetStatuses from 'hooks/useGetStatuses';
import { HStack } from 'layouts/box/Box';
import { AssignedMember } from 'types';
import {
  AssignedMemberType,
  InstanceStateType,
  MemberType,
  MemberTypeEnum,
  SortType,
} from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import { OrderFormMemberType } from 'types/memberTypes/order_form';
import { isoToLocaleShort } from 'utils/datetimeHelpers';
import { formatDuration } from 'utils/formatDuration';
import { getHighlightedTitle } from 'utils/member/member-utils';

import { UniqueId } from '../../Command';
import { hasAvatar, integrationIcons, StatusIcons } from '../../command-constants';

import {
  IconWrapper,
  ItemContentWrapper,
  ItemDate,
  ItemDescription,
  ItemFooter as ItemFooterWrapper,
  ItemInstances,
  ItemTitle,
  ItemTitleWrapper,
  RestrictedIcon,
  RundownStatus,
  SearchItemWrapper,
} from './styled';

interface SearchItemProps {
  openItem: (item: MemberType) => void;
  onContextMenu?: (item: MemberType, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  item: MemberType;
  sortedBy: SortType | 'best';
  commandById: Record<string, EditorCommandConfigType | undefined>;
  index?: number;
  value: string;
  members?: Record<string, AssignedMember>;
}

interface FooterProps {
  item: MemberType;
  sortedBy: SortType | 'best';
  instanceStates: Record<string, InstanceStateType>;
  forms: Record<string, OrderFormMemberType>;
  command: EditorCommandConfigType | undefined;
}

interface AvatarProps {
  item: MemberType;
  members: Record<string, AssignedMember>;
}

const sortToMemberTypeProp: Record<
  SortType | 'best',
  keyof Pick<MemberType, 'mUpdatedAt' | 'mPublishingAt' | 'mCreatedAt' | 'totalInstances'>
> = {
  best: 'mCreatedAt',
  createdAt: 'mCreatedAt',
  updatedAt: 'mUpdatedAt',
  scheduledAt: 'mPublishingAt',
  totalInstances: 'totalInstances',
};

const sortToTooltip: Record<SortType | 'best', string> = {
  best: 'Updated date',
  createdAt: 'Created date',
  updatedAt: 'Last updated date',
  scheduledAt: 'Scheduled date',
  totalInstances: 'Total instances',
};

const rundownStateLabel: Record<string, string> = {
  ready: 'Ready to air',
  active: 'Activated',
};

interface ItemFooterWrapperTooltipProps {
  children: React.ReactNode;
  sortedBy: SortType | 'best';
  item: MemberType;
}
const ItemFooterWrapperTooltip = ({ children, sortedBy, item }: ItemFooterWrapperTooltipProps) => (
  <ItemFooterWrapper>
    {children}
    {
      // Don't show the date for totalInstances
      sortedBy === 'totalInstances' ? null : (
        <Tooltip title={sortToTooltip[sortedBy]}>
          <ItemDate variant="caption">{`${isoToLocaleShort(
            item[sortToMemberTypeProp[sortedBy]],
            true,
          )}`}</ItemDate>
        </Tooltip>
      )
    }
  </ItemFooterWrapper>
);

const ItemFooter = ({ item, sortedBy, instanceStates, forms, command }: FooterProps) => {
  switch (item.mType ?? MemberTypeEnum.Story) {
    case MemberTypeEnum.Block: {
      return <ItemDate variant="caption">{command?.mTitle ?? 'Unknown command'}</ItemDate>;
    }
    case MemberTypeEnum.Asset: {
      const provider = getMetadataValue(item.mMetaData, 'provider');
      const copyright = getMetadataValue(item.mMetaData, 'copyrightnotice');
      return (
        <ItemFooterWrapper>
          <ItemDate variant="caption">{provider}</ItemDate>
          <ItemDate variant="caption">{copyright}</ItemDate>
        </ItemFooterWrapper>
      );
    }
    case MemberTypeEnum.StrIns:
    case MemberTypeEnum.RndIns:
    case MemberTypeEnum.Instance: {
      const accountTitle = getAccountTitle(item);
      const instanceState = item.mState ? instanceStates[item.mState] : undefined;
      const StatusIcon = StatusIcons[instanceState?.icon ?? ''];
      return (
        <ItemFooterWrapperTooltip sortedBy={sortedBy} item={item}>
          <HStack gap="6px">
            <ItemDate variant="caption" style={{ width: '120px' }}>
              {accountTitle}
            </ItemDate>
            <ItemDate variant="caption">
              <ScheduleOn />{' '}
              {item.mPublishingAt ? isoToLocaleShort(item.mPublishingAt) : 'Unscheduled'}
            </ItemDate>
            {StatusIcon && <StatusIcon style={{ width: '20px' }} className="skipOverride" />}
            <ItemDate variant="caption" style={{ height: '16px' }}>
              {instanceState?.name}
            </ItemDate>
          </HStack>
        </ItemFooterWrapperTooltip>
      );
    }
    case MemberTypeEnum.Note: {
      return (
        <ItemFooterWrapperTooltip item={item} sortedBy={sortedBy}>
          <ItemDate variant="caption">Note in a story</ItemDate>
        </ItemFooterWrapperTooltip>
      );
    }
    case MemberTypeEnum.User:
    case MemberTypeEnum.Contact:
    case MemberTypeEnum.Team:
    case MemberTypeEnum.Department: {
      return null;
    }
    case MemberTypeEnum.Rundown: {
      const rundownState = rundownStateLabel[item.mState ?? 'notReady'] ?? '';
      return (
        <ItemFooterWrapperTooltip item={item} sortedBy={sortedBy}>
          <ItemDate variant="caption">
            <ScheduleOn />{' '}
            {item.mPublishingAt ? isoToLocaleShort(item.mPublishingAt) : 'Unscheduled'}
          </ItemDate>

          <ItemDate variant="caption" style={{ position: 'relative', top: '2px' }}>
            <Planned />{' '}
            <span style={{ position: 'relative', top: '-4px' }}>
              {formatDuration(Number(item.mPlannedDuration ?? '0'))}
            </span>
          </ItemDate>

          {rundownState && (
            <RundownStatus
              variant="caption"
              status={(item.mState ?? 'notReady') as 'ready' | 'active' | 'notReady'}
            >
              {rundownState}
            </RundownStatus>
          )}
        </ItemFooterWrapperTooltip>
      );
    }
    case MemberTypeEnum.ResPitch:
    case MemberTypeEnum.Pitch:
    case MemberTypeEnum.ResStory:
    case MemberTypeEnum.Story: {
      const InstanceIcon = integrationIcons.instance;
      return (
        <ItemFooterWrapperTooltip item={item} sortedBy={sortedBy}>
          <ItemDate variant="caption">
            <ScheduleOn />{' '}
            {item.mPublishingAt ? isoToLocaleShort(item.mPublishingAt) : 'Unscheduled'}
          </ItemDate>
          <ItemInstances>
            <InstanceIcon height="1rem" width="1rem" />
            {`${item.totalInstances ?? 0} instances`}
          </ItemInstances>
        </ItemFooterWrapperTooltip>
      );
    }
    case MemberTypeEnum.Order: {
      const form = forms[item.mFormId ?? ''];
      const statuses = form?.configs?.find((c) => c.key === 'statuses')?.alternatives;
      const status = statuses?.find((s) => s.value === item.mState)?.label;
      return (
        <ItemFooterWrapperTooltip item={item} sortedBy={sortedBy}>
          <ItemDate variant="caption">{`${
            status ?? (item.mState ?? 'Create#active').split('#')[1]
          }`}</ItemDate>
        </ItemFooterWrapperTooltip>
      );
    }
    default:
  }
};

const Avatars = ({ item, members }: AvatarProps) => {
  const assignedMembers = item.mAssignedMembers;
  const taskAssignee = item.mTertRefId;
  if (!assignedMembers?.length && !taskAssignee) return null;
  const avatarsToDraw =
    item.mType === MemberTypeEnum.Order
      ? [{ mId: taskAssignee } as AssignedMemberType]
      : assignedMembers!;
  // Normalization fix for bad data. Take away any duplicate members to avoid
  // duplicate avatars in the list.
  const filtered = uniqBy(avatarsToDraw, (a) => a.mId);
  return (
    <HStack overflow="visible" gap="1px">
      {filtered.map((member) => {
        const m = members[member.mId];
        return m ? (
          <Avatar
            variant={m.mType as AvatarVariant}
            imageKey={m.mAvatarKey}
            size={16}
            title={m.mTitle}
          />
        ) : null;
      })}
    </HStack>
  );
};

const AvatarIcon = ({ item, members }: AvatarProps) => {
  const member = item.mType === MemberTypeEnum.Contact ? item : members[item.mId ?? ''];

  if (!member) return null;
  return (
    <Avatar
      variant={member.mType as AvatarVariant}
      imageKey={member.mAvatarKey}
      size={24}
      title={member.mTitle}
    />
  );
};

const getHighlight = (stringifiedHighlight?: string) => {
  if (!stringifiedHighlight) return;
  try {
    const text = JSON.parse(stringifiedHighlight) as unknown;
    if (isHighlight(text)) {
      if (text.mDescription?.length) {
        return {
          __html: `${safeForHTML(text.mDescription.join(' '))}`,
        };
      }
      if (text.textContent?.length) {
        return {
          __html: `${safeForHTML(text.textContent.join(' '))}`,
        };
      }
    }
  } catch (err: unknown) {
    console.error(err);
  }
};

const getDescription = (item: MemberType) => {
  if (item.mType === MemberTypeEnum.Asset) {
    return {
      __html: `${safeForHTML(getMetadataValue(item.mMetaData, 'caption'))}`,
    };
  }

  return (
    getHighlight(item.highlight) ?? {
      __html: `${safeForHTML(item.mDescription ?? '')}`,
    }
  );
};

const getKey = (item: MemberType) => {
  if (
    item.mType && [
      MemberTypeEnum.Note,
      MemberTypeEnum.Asset,
      MemberTypeEnum.Order.includes(item.mType),
    ]
  ) {
    return item.mRefId;
  }
  return item.mId;
};

type ListItemProps = Omit<SearchItemProps, 'openItem' | 'index' | 'onContextMenu'>;

const ListItem = ({ item, members, sortedBy, commandById }: ListItemProps) => {
  const { instanceStatusesById } = useGetStatuses();
  const { keyedOrderForms } = useGetOrderForms();

  const isBlock = item.mType === MemberTypeEnum.Block;
  const command = commandById && isBlock && item.mSecId ? commandById[item.mSecId] : undefined;

  const isRestrictedItem = isRestricted(item.mType ?? '');

  return (
    <HStack width="100%">
      <div style={{ width: '32px', height: '32px' }} className="search-icon">
        {isBlock && (
          <IconWrapper $color={command?.mColor}>
            <Metadata className="skipOverride" />
          </IconWrapper>
        )}
        {!isBlock && !hasAvatar.includes(item.mType ?? MemberTypeEnum.Story) && (
          <Icon member={item} />
        )}
        {members && hasAvatar.includes(item.mType ?? MemberTypeEnum.Story) && (
          <AvatarIcon item={item} members={members} />
        )}
      </div>
      <ItemContentWrapper alignItems="start">
        <ItemTitleWrapper justifyContent="space-between">
          {isRestrictedItem && <RestrictedIcon />}
          <ItemTitle
            dangerouslySetInnerHTML={getHighlightedTitle(item, keyedOrderForms, commandById)}
          />
          {members && (item.mAssignedMembers || typeof item.mTertRefId === 'string') && (
            <Avatars item={item} members={members} />
          )}
        </ItemTitleWrapper>
        <ItemDescription dangerouslySetInnerHTML={getDescription(item)} />
        <ItemFooter
          forms={keyedOrderForms}
          item={item}
          sortedBy={sortedBy}
          instanceStates={instanceStatusesById}
          command={command}
        />
      </ItemContentWrapper>
    </HStack>
  );
};

const areEqual = (prevProps: ListItemProps, nextProps: ListItemProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

export const MemoizedListItem = memo(ListItem, areEqual);

const SearchItem = ({
  openItem,
  item,
  index,
  members,
  sortedBy,
  value,
  onContextMenu,
  commandById,
}: SearchItemProps) => {
  const key = getKey(item);

  const [, drag] = useDrag({
    type: 'STORY_DRAG',
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      key={key}
      ref={(r) => {
        if (r) drag(r.firstElementChild);
      }}
    >
      <SearchItemWrapper
        className="search-item"
        onSelect={() => openItem(item)}
        forceMount={true}
        value={`${key} ${value}`}
        onContextMenu={(ev) => onContextMenu?.(item, ev)}
      >
        <UniqueId id={key ?? `${index}`} />
        <MemoizedListItem
          value={`${key} ${value}`}
          members={members}
          sortedBy={sortedBy}
          item={item}
          commandById={commandById}
        />
      </SearchItemWrapper>
    </div>
  );
};

export default SearchItem;
