import { useContext, useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { CellMap } from 'components/mdfEditor/fields/fields';
import UserContext from 'contexts/UserContext';
import { FieldMap, getFieldMap } from 'features/gridDeck/utils';
import { getMdfByMTypeFromKeyedMdfs } from 'features/grids/common/components/utils/mdfUtils';
import { hasPermission } from 'features/mdf/mdf-utils';
import { getFieldKey } from 'features/orderForm/utils';
import { PreviewType } from 'store/preview';
import { SupportedMetadataTypes } from 'types';
import { FieldValue, Metadata, NewFieldValue } from 'types/forms/forms';
import { FieldTypeEnum, Mdf, MdfField } from 'types/graphqlTypes';
import { ParsedMemberType } from 'types/members';

export type MetadataCell = CellContext<ParsedMemberType, unknown> & {
  errorValue: string;
  updateFieldValues: (val: NewFieldValue[]) => void;
  metadata: Metadata;
  mdf?: Mdf;
  setPreview: (val: PreviewType) => void;
};

interface GridWidgetMdfColumnProps {
  items: ParsedMemberType[];
  fieldMap: FieldMap;
  mdfMap: Record<SupportedMetadataTypes | string, Mdf | undefined>;
  groups: string[];
}

const getMaxWidth = (fieldModel: MdfField) => {
  switch (fieldModel.type) {
    case FieldTypeEnum.checkbox:
      return 100;
    case FieldTypeEnum.choice:
      return 160;
    case FieldTypeEnum.text:
      return 300;
    default:
      return undefined;
  }
};

export const getMdfColumnDefinitions = ({
  items,
  mdfMap,
  fieldMap,
  groups,
}: GridWidgetMdfColumnProps): ColumnDef<ParsedMemberType>[] => {
  const columns: ColumnDef<ParsedMemberType>[] = [];
  const uniqueKeys = new Set<string>();

  const usedMdfIds = new Set<string>();

  for (const member of items) {
    if (member.mdfId) {
      usedMdfIds.add(member.mdfId);
    } else {
      const id = getMdfByMTypeFromKeyedMdfs(member, mdfMap)?.id;
      if (id) usedMdfIds.add(id);
    }
  }

  for (const mdfId of usedMdfIds.values()) {
    const mdf = mdfMap[mdfId];
    if (mdf) {
      for (const field of mdf.fields) {
        uniqueKeys.add(getFieldKey(field.fieldId, mdf.id));
      }
    }
  }

  const it = uniqueKeys.entries();
  for (const entry of it) {
    const key = entry[0];
    const fieldModel = fieldMap[key];
    if (!fieldModel) continue;

    columns.push({
      accessorKey: `metadata.${fieldModel.fieldId}`,
      header: fieldModel.settings.label,
      maxSize: getMaxWidth(fieldModel),
      sortingFn: (a, b) => {
        const aValue = a.original.metadata[fieldModel.fieldId];
        const bValue = b.original.metadata[fieldModel.fieldId];

        const stringifyValue = (value: FieldValue) => {
          if (value === null || value === undefined) return '';
          if (typeof value === 'object') return JSON.stringify(value);
          return value.toString();
        };

        const aString = stringifyValue(aValue);
        const bString = stringifyValue(bValue);

        if (aString === bString) return 0;
        if (aString === '') return -1;
        if (bString === '') return 1;
        return aString.localeCompare(bString);
      },
      cell: (context) => {
        const { updateFieldValues, row, errorValue, metadata, mdf, setPreview } =
          context as MetadataCell;
        const Cell = CellMap[fieldModel.type];
        if (Cell && mdf) {
          // -- Do not render cell if the field does not belong to the form
          if (row.original.mdfId && fieldModel.formId !== row.original.mdfId) return null;

          return (
            <Cell
              onClick={() => {
                if (fieldModel.type === FieldTypeEnum.subtype) {
                  setPreview(row.original);
                }
              }}
              key={fieldModel.fieldId}
              mdf={mdf}
              disableEdit={!hasPermission(mdf.permissions.write[fieldModel.fieldId], groups)}
              fieldModel={fieldModel}
              value={metadata[fieldModel.fieldId]}
              errorValue={errorValue}
              fieldSettings={fieldModel.settings}
              setValue={(newValue) => {
                if (newValue !== row.original.metadata[fieldModel.fieldId]) {
                  updateFieldValues([{ fieldId: fieldModel.fieldId, value: newValue }]);
                }
              }}
            />
          );
        }
        return <span>{mdf ? 'Missing field' : 'Missing schema'}</span>;
      },
    });
  }

  return columns;
};

export const useGetMdfColumns = (items: ParsedMemberType[]) => {
  const { groups } = useContext(UserContext);
  const { mdfs } = useGetMdfs({ all: true });
  const keyedMdfs = useMemo(() => keyBy(mdfs, (m) => m.id), [mdfs]);
  const fieldMap = useMemo(() => {
    return getFieldMap(keyedMdfs);
  }, [keyedMdfs]);

  const mdfDefinitions = useMemo(
    () => getMdfColumnDefinitions({ items, fieldMap, mdfMap: keyedMdfs, groups }),
    [items?.length, fieldMap, keyedMdfs],
  );

  return mdfDefinitions;
};
