import styled from '@emotion/styled';

export const HeaderWrapper = styled('div')`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;
export const Header = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AvatarWrapper = styled('div')`
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  button {
    transition: height 100ms;
    position: absolute;
    bottom: 0;
    height: 0;
    padding: 0;
    border: none;
    border-radius: 0px;
  }
  :hover {
    button {
      height: 32px;
      padding-bottom: 4px;
    }
  }
`;

export const TitleWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const IdWrapper = styled('div')`
  margin-top: 6px;
  display: flex;
  gap: 8px;
`;

export const ErrorWrapper = styled('div')`
  height: 100px;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
