import { useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import configCtx from 'contexts/configContext';
import UPDATE_INSTANCE from 'operations/mutations/updateInstance';
import getMetadataWithMosTag from 'utils/metadata/addMostag';
import useLogger from 'utils/useLogger';
import GET_INSTANCE_METADATA_FROM_CACHE from 'operations/queries/getInstanceMetadataFromCache';
import useUpdateFloatedItems from './useUpdateFloatedItems';

const useUpdateInstance = () => {
  const logger = useLogger('use update instance metadata');
  const [updateInstanceMutation] = useMutation(UPDATE_INSTANCE);
  const { metadataForms } = useContext(configCtx);
  const client = useApolloClient();
  const [updateFloatedItems] = useUpdateFloatedItems();

  /**
   * Returns true if the given array is a valid array and not empty
   * @param {*} array
   * @returns true if the given array is not empty
   */
  const IsNotEmptyArray = (array) => Array.isArray(array) && array.length;

  const { fields: formFields } = IsNotEmptyArray(metadataForms) ? metadataForms[0] : {};

  /**
   * Returns true if the given params has no changes.
   * @param {*} params
   */
  const noChanges = (params) => {
    const { metadata, items, unlock, version } = params;
    return !unlock && !items && !IsNotEmptyArray(metadata) && !version;
  };

  const readInstanceFromCache = (mId) => {
    const result = client.readFragment({
      fragment: GET_INSTANCE_METADATA_FROM_CACHE,
      id: mId,
      fragmentName: 'currentInstance',
    });

    return result || { mMetaData: [] };
  };
  const findField = (metadata = [], key) => metadata.find((m) => m.key.includes(key));

  const getBooleanFieldValue = (field, defaultValue = 'false') =>
    field ? field.value : defaultValue;

  const getFloatDataChange = (oldMetadata, newMetadata) => {
    const floatFieldKey = 'isFloat';
    const oldFloatField = findField(oldMetadata, floatFieldKey);
    const floatField = findField(newMetadata, floatFieldKey);

    const oldValue = getBooleanFieldValue(oldFloatField);

    // if no float information in updated metadata then consider it is same as old value
    return { oldValue, newValue: getBooleanFieldValue(floatField, oldValue) };
  };

  const getRundownId = (instance = {}) => {
    const { mProperties } = instance;
    const { account, platform } = mProperties;
    if (platform !== 'linear') return null;
    return account?.accountId;
  };

  /**
   * Automation Item representation
   * @typedef {object} AutomationItem
   * @property {string} itemId - Unique item id within the instance
   * @property {string} templateType - Main automation template type (main category)
   * @property {string} templateVariant - Automation variant (subcategory)
   * @property {string} title - String representation of the template
   */

  /**
   * Callback for updating metadata after storing instance to the database
   * @callback onMetadataUpdated
   * @param {string} instanceId - The instance.mId
   * @param {} metadata - Array of [key,value] to update the instance metadata
   */

  /**
   * Updates the instance representation in the database.
   * Invokes the graphql updateInstance mutation
   * @param {object} params - Instance properties for update
   * @param {string} params.instanceId - The instance.mId
   * @param {*} params.metadata - Array of [key,value] to update the instance metadata
   * @param {AutomationItem[]} params.items - Array of first 3 automation items within the instance
   * @param {boolean} params.unlock - True if the instance shall be unlocked
   * @param {boolean} params.autosave - True if the instance update is due an autosave
   * @param {string | undefined} params.version - version
   * @param {*} params.content - Editor content object in slate format
   * @param {{source:string} | undefined} params.audit - audit information
   * @param {onMetadataUpdated} params.onMetadataUpdated - Callback for updating metadata after save
   */
  const updateInstance = async (params) => {
    if (noChanges(params)) return;

    const { metadata, items, instanceId } = params;
    const hasMetadata = IsNotEmptyArray(metadata);

    const input = { mId: instanceId };
    if (items) input.items = items;

    const getMetaDataForFormFields = (existingFormfields, existingMetadata) => {
      return getMetadataWithMosTag(existingFormfields, existingMetadata);
    };

    if (hasMetadata) {
      input.mMetaData = formFields ? getMetaDataForFormFields(formFields, metadata) : metadata;
    }

    const { unlock, content, version, autosave, audit } = params;
    if (unlock) input.locked = null;
    if (content) input.content = JSON.stringify(content);
    if (version) input.version = version;
    if (autosave) input.autosave = true;
    if (audit) input.audit = { ...audit, timestamp: new Date().toISOString() };

    try {
      const instanceState = readInstanceFromCache(input.mId);
      const rundownId = getRundownId(instanceState);

      await updateInstanceMutation({ variables: { input } });

      if (!hasMetadata) return;

      const { onMetadataUpdated } = params;
      if (onMetadataUpdated) {
        onMetadataUpdated(instanceId, input.mMetaData);
      }

      if (!rundownId) return;

      const { newValue: newFloatValue, oldValue: oldFloatValue } = getFloatDataChange(
        instanceState.mMetaData,
        input.mMetaData,
      );

      if (oldFloatValue !== newFloatValue) {
        await updateFloatedItems(rundownId, [input.mId], newFloatValue);
      }
    } catch (error) {
      logger.error(error);
    }
  };

  return [updateInstance];
};

export default useUpdateInstance;
