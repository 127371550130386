import useInstanceCardHeader from 'features/instance/hooks/useInstanceCardHeader';
import useInstanceCore from 'features/instance/hooks/useInstanceCore';
import useInstancePermissions from 'features/instance/hooks/useInstancePermissions';
import useInstanceTemplate from 'features/instance/hooks/useInstanceTemplate';
import useInstanceVersion from 'features/instance/hooks/useInstanceVersion';
import { useInstanceMolecule } from 'features/instance/store/instance';

import { publishingPoints } from '../../utils';
import GeneralEllipsisMenu from '../generalEllipsisMenu';
import LinearEllipsisMenu from '../linearEllipsisMenu';

const Menu = () => {
  const {
    useInstanceValue,
    useIsSavingInstance,
    useHideTemplateOptions,
    usePublishingPointValue,
    useDisableEdit,
    useReadLock,
    usePrintDialog,
  } = useInstanceMolecule();
  const {
    canUpdateInstance,
    canCreateNewTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    canPinTemplateFolder,
    canReorderTemplates,
    canCreateInstance,
    canScheduleInstance,
    canSetDefaultTemplate,
    canDeleteInstance,
  } = useInstancePermissions();
  const { onDeleteInstance, lockedByUser, editorValue, onForceUnlock, onCreateDuplicate } =
    useInstanceCore();
  const { hideAutomationTemplates, isDeleteEnabled, handleDownload, canOpenStory } =
    useInstanceCardHeader();
  const {
    folders,
    onSelectTemplate,
    onSaveTemplate,
    onDeleteFolder,
    onCreateFolder,
    onSetDefaultTemplate,
    defaultTemplateRefId,
    onDeleteTemplate,
  } = useInstanceTemplate();

  const { onRestoreVersion, checkVersionRestorability } = useInstanceVersion();

  const instance = useInstanceValue();
  const publishingPoint = usePublishingPointValue();
  const [isSavingContent] = useIsSavingInstance();
  const [hideTemplateOptions] = useHideTemplateOptions();
  const [disableEdit] = useDisableEdit();
  const [readLock] = useReadLock();
  const [, setShowPrintDialog] = usePrintDialog();

  if (!canUpdateInstance || disableEdit) return null;

  return publishingPoints.LINEAR === publishingPoint ? (
    <LinearEllipsisMenu
      disableEdit={!canUpdateInstance}
      folders={folders}
      onSelectTemplate={onSelectTemplate}
      onSaveTemplate={onSaveTemplate}
      onDeleteTemplate={onDeleteTemplate}
      onCreateFolder={onCreateFolder}
      onDeleteFolder={onDeleteFolder}
      onDownload={handleDownload}
      onCreateDuplicate={onCreateDuplicate}
      onForceUnlock={onForceUnlock}
      onClientPrint={() => {
        setShowPrintDialog(true);
      }}
      lockedByUser={lockedByUser}
      canCreateNewTemplate={canCreateNewTemplate}
      canDeleteTemplate={canDeleteTemplate}
      canDeleteTemplateFolder={canDeleteTemplateFolder}
      canPinTemplateFolder={canPinTemplateFolder}
      canReorderTemplates={canReorderTemplates}
      onDeleteInstance={onDeleteInstance}
      isDeleteEnabled={isDeleteEnabled}
      title={instance?.mTitle}
      instanceId={instance?.mId}
      onRestoreVersion={onRestoreVersion}
      checkVersionRestorability={checkVersionRestorability}
      isSavingContent={isSavingContent}
      canCreateInstance={canCreateInstance}
      canScheduleInstance={canScheduleInstance}
      canDeleteInstance={canDeleteInstance}
      canUpdateInstance={canUpdateInstance}
      hideTemplateOptions={hideTemplateOptions}
      schedule={instance?.mPublishingAt}
      hideAutomationTemplates={hideAutomationTemplates}
      platformKind={instance?.mProperties?.platformKind}
      canSetDefaultTemplate={canSetDefaultTemplate}
      defaultTemplateRefId={defaultTemplateRefId}
      onSetDefaultTemplate={onSetDefaultTemplate}
      isContentLoaded={!!editorValue}
      isLockedByAnotherUser={readLock}
      canOpenStory={canOpenStory}
    />
  ) : (
    <GeneralEllipsisMenu
      disableEdit={!canUpdateInstance}
      isDeleteEnabled={isDeleteEnabled}
      onDeleteInstance={onDeleteInstance}
      onForceUnlock={onForceUnlock}
      isLockedByAnotherUser={readLock}
      lockedByUser={lockedByUser}
      title={instance?.mTitle}
      instanceId={instance?.mId}
      onRestoreVersion={onRestoreVersion}
      checkVersionRestorability={checkVersionRestorability}
      isSavingContent={isSavingContent}
      canDeleteInstance={canDeleteInstance}
      canUpdateInstance={canUpdateInstance}
      folders={folders}
      canCreateNewTemplate={canCreateNewTemplate}
      canDeleteTemplateFolder={canDeleteTemplateFolder}
      canPinTemplateFolder={canPinTemplateFolder}
      canReorderTemplates={canReorderTemplates}
      canDeleteTemplate={canDeleteTemplate}
      onDeleteFolder={onDeleteFolder}
      onSaveTemplate={onSaveTemplate}
      onSelectTemplate={onSelectTemplate}
      onDeleteTemplate={onDeleteTemplate}
      onCreateFolder={onCreateFolder}
      hideTemplateOptions={hideTemplateOptions}
      canSetDefaultTemplate={canSetDefaultTemplate}
      defaultTemplateRefId={defaultTemplateRefId}
      onSetDefaultTemplate={onSetDefaultTemplate}
      isContentLoaded={!!editorValue}
    />
  );
};

export default Menu;
