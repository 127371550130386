import { StyledComponent } from '@emotion/styled';

import { isHighlight } from 'features/command/command-types';
import type { ResourceType } from 'types/forms/forms';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import type { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import type { OrderFormMemberType } from 'types/memberTypes/order_form';

import { InstanceIcon, PitchIcon, RundownIcon, SpacesIcon, StoryIcon, TaskIcon } from './styled';

const safeForHTML = (html: string) => {
  return html?.replace(/['"]+/g, '');
};

export const IconMap: Record<ResourceType, StyledComponent<React.SVGProps<SVGSVGElement>>> = {
  story: StoryIcon,
  pitch: PitchIcon,
  instance: InstanceIcon,
  space: SpacesIcon,
  rundown: RundownIcon,
  order: TaskIcon,
  block: TaskIcon,
};

export const getMemberTitle = (
  item: MemberType,
  forms: Record<string, OrderFormMemberType | undefined>,
  commands: Record<string, EditorCommandConfigType | undefined>,
) => {
  if (item.mFormId && item.mType === MemberTypeEnum.Order) {
    return forms[item.mFormId]?.mDescription ?? item.mTitle ?? '';
  } else if (item.mType === MemberTypeEnum.Block) {
    const command = commands && item.mSecId ? commands[item.mSecId] : undefined;
    return item.mTitle
      ? `${command?.mTitle ?? 'Unknown'}: ${item.mTitle}`
      : command?.mTitle ?? 'Unknown';
  }
  return item.mTitle ?? '';
};

export const getHighlightedTitle = (
  item: MemberType,
  forms: Record<string, OrderFormMemberType | undefined>,
  commands: Record<string, EditorCommandConfigType | undefined>,
) => {
  if (item.highlight) {
    try {
      const text = JSON.parse(item.highlight) as unknown;
      if (isHighlight(text)) {
        if (text.mTitle?.length) {
          return {
            __html: `${safeForHTML(text.mTitle[0])}`,
          };
        }
      }
    } catch {
      // Silently fail, let it fall back.
    }
  }

  return {
    __html: `${getMemberTitle(item, forms, commands)}`,
  };
};
