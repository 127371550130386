import { Component, ErrorInfo, ReactNode } from 'react';
import { ApolloClient } from '@apollo/client';
import styled from '@emotion/styled';

import { ReactComponent as ArrowDownSmall } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRightSmall } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { Button } from 'components/buttons';
import Collapsible from 'components/collapsible/Collapsible';
import Divider from 'components/divider';
import ResizableBox from 'components/resizableBox';
import Scrollbar from 'components/scrollbar';
import Text from 'components/text/Text';
import { Box, Flex, HStack, VStack } from 'layouts/box/Box';
import { BOUNDS, ENABLE_RESIZING } from 'utils/constants/resizableBox';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
`;

const HDivider = styled(Divider)`
  width: 100%;
  margin-block: 12px;
`;

const Caption = styled(Text)``;
Caption.defaultProps = {
  variant: 'caption',
  color: 'inactive',
};

const Wrapper = styled('pre')`
  white-space: pre-wrap;
  font-size: 13;
  color: ${({ theme }) => theme.palette.dina.errorText};
`;

const RefreshButton = ({
  label,
  onClick,
  caption1,
  caption2,
}: {
  label: string;
  onClick: () => void;
  caption1: string;
  caption2: string;
}) => {
  return (
    <VStack flex="1" alignItems="flex-start" gap="8px">
      <Button variant="contained" usage="significant" onClick={onClick}>
        {label}
      </Button>
      <Caption>
        {caption1} <br /> {caption2}
      </Caption>
    </VStack>
  );
};

type Props = {
  children: ReactNode;
  apolloClient: ApolloClient<object>;
};

type States = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  timeStamp: Date | null;
  isCollapsed: boolean;
  copy: string;
};

class ErrorBoundary extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      timeStamp: null,
      isCollapsed: true,
      copy: 'Copy',
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
      timeStamp: new Date(),
    });
  }

  render() {
    const { error, errorInfo, timeStamp, isCollapsed } = this.state;
    const { children, apolloClient } = this.props;

    const isDevEnv = import.meta.env.MODE === 'development';
    const dinaVersion = (import.meta.env.REACT_APP_VERSION ?? 'v0.0.0') as string;
    const dinaVersionDate = (import.meta.env.REACT_APP_VERSION_DATE ??
      new Date().toISOString()) as string;

    if (errorInfo) {
      return (
        <Container width="100vw" height="100vh">
          <VStack width="600px" alignItems="flex-start" gap="8px">
            <Text variant="h4">Something went wrong on our end, we are sorry about that.</Text>
            <Text variant="body2">
              We have already logged the error, and will fix it as soon as possible.
            </Text>
            <HDivider />
            <Text variant="h6">In the meantime, you can try two things:</Text>
            <HStack width="100%" gap="16px" alignItems="flex-start">
              <RefreshButton
                label="Refresh page, and try again"
                onClick={() => window.location.reload()}
                caption1="Will reload the page."
                caption2="You can pick up where you left off."
              />
              <Text variant="listItemLabelItalic" style={{ marginTop: 12 }}>
                ...or
              </Text>
              <RefreshButton
                label="Restart Dina"
                onClick={() => {
                  // eslint-disable-next-line no-console
                  apolloClient.clearStore().catch((e) => console.log(e));
                  window.location.reload();
                }}
                caption1="Will start a new session of Dina."
                caption2="You need to find and open your work again."
              />
            </HStack>
            <HDivider />
            <Text variant="listItemLabelMedium">Technical information (already logged):</Text>
            <VStack width="100%" alignItems="flex-start">
              <Caption>Dina Version: {dinaVersion}</Caption>
              <Caption>Dina Version Date: {dinaVersionDate}</Caption>
              <Caption>Time of incident: {timeStamp?.toString()}</Caption>
              <Caption>Time of incident (ISO): {timeStamp?.toISOString()}</Caption>
              <Caption>Error message: {error?.toString()}</Caption>
            </VStack>
            {isDevEnv && error && (
              <Box width="600px" margin="12px 0 0">
                <Collapsible
                  open={!isCollapsed}
                  setOpen={() => this.setState({ isCollapsed: !isCollapsed })}
                  trigger={
                    <HStack style={{ cursor: 'pointer', marginBottom: 8 }}>
                      {isCollapsed ? <ArrowRightSmall /> : <ArrowDownSmall />}
                      <Text variant="listItemLabelBold" style={{ flex: 1 }}>
                        Stack Trace
                      </Text>
                      <Button
                        height={24}
                        width={80}
                        usage="outlined"
                        variant="outlined"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.setState({ copy: 'Copied!' });
                          setTimeout(() => this.setState({ copy: 'Copy' }), 2000);
                        }}
                      >
                        {this.state.copy}
                      </Button>
                    </HStack>
                  }
                  content={
                    <ResizableBox
                      initialSize={{ width: '600px', height: 300 }}
                      enableResizing={ENABLE_RESIZING}
                      minHeight={200}
                      bounds={BOUNDS}
                      onResize={() => {}}
                    >
                      <Scrollbar>
                        <Wrapper>
                          {error.toString()}
                          {errorInfo.componentStack}
                        </Wrapper>
                      </Scrollbar>
                    </ResizableBox>
                  }
                />
              </Box>
            )}
          </VStack>
        </Container>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
