import { useMemo } from 'react';

import { useUpdateBoardColumns } from 'api/gridAndKanban/useUpdateBoardColumns';
import { MemberContextMenu } from 'components/contextMenu/MemberContextMenu';
import Scrollbar from 'components/scrollbar';
import MemberDropzone from 'features/dnd/MemberDropzone';
import { arrangeColumns } from 'features/grids/common/components/utils/columnUtils';
import { useDataTable } from 'features/grids/common/useDataTable';
import { useGetMdfColumns } from 'features/mdf/useGetMdfColumns';

import { GridWidgetDataTable } from './components/GridWidgetDataTable';
import { useGetTableMeta } from './hooks/useGetTableMeta';
import { getGridWidgetColumns } from './columns';
import { GridWidgetType } from './types';

/**
 * A grid that displays a list of members.
 * Members are added by dragging them from a list into the grid.
 * Their position in the grid is saved to the database.
 */
export function GridWidget({ items, boardId, groupId, itemIds = [] }: Readonly<GridWidgetType>) {
  const { addMember } = useUpdateBoardColumns(boardId);

  // ─── Columns ─────────────────────────────────────────────────────────
  const defaultColumns = useMemo(() => getGridWidgetColumns(), []);
  const mdfColumns = useGetMdfColumns(items);
  const columns = arrangeColumns(defaultColumns, mdfColumns);

  // ─── Table Meta ──────────────────────────────────────────────────────
  const { meta } = useGetTableMeta({ boardId, groupId });

  // ─── Initiate Table Instance w/ Data, Columns And Meta ────────────────
  const { table } = useDataTable({
    data: items,
    columns,
    meta,
    storageKey: `gridWidget-${boardId}`,
    getRowId: (originalRow) => `${originalRow.mId}`,
  });

  return (
    <Scrollbar showHorizontal showVertical={false}>
      <MemberDropzone itemGroupId={groupId} itemIds={itemIds} onDrop={addMember}>
        <GridWidgetDataTable table={table} itemIds={itemIds} boardId={boardId} groupId={groupId} />
      </MemberDropzone>
      <MemberContextMenu menuId={`gridItemMenu-${boardId}`} />
    </Scrollbar>
  );
}
