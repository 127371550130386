import { useState } from 'react';

import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { useSearchMembers } from 'hooks/useSearchMembers';
import { Box } from 'layouts/box/Box';
import { SearchItemTypeEnum } from 'types/graphqlTypes';
import { User } from 'types/members';

import MemberList from './memberList/MemberList';
import Search from './Search';

function Contacts() {
  const [search, setSearch] = useState('');

  const { items, loading, total, loadMore } = useSearchMembers({
    inputs: { mTypes: [SearchItemTypeEnum.contact] },
    searchString: search,
    doPoll: false,
    skip: false,
  });

  return (
    <Box width="100%" height="100%" style={{ position: 'relative' }}>
      <MemberList
        members={items as User[]}
        title="Contacts"
        total={total}
        loadMore={loadMore}
        search={
          <Search searchString={search} setSearchString={setSearch} loading={loading && !!search} />
        }
      />
      {loading && (
        <div style={{ position: 'absolute', bottom: 36, left: '50%' }}>
          <LoadingIndicator width={36} height={36} />
        </div>
      )}
    </Box>
  );
}

export default Contacts;
