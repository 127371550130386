import { useCallback, useMemo, useState } from 'react';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { IconButton } from 'components/buttons';
import Text from 'components/text/Text';
import { useTreeChoiceDialog } from 'components/treeChoiceDialog/TreeChoiceDialog';
import { HStack, VStack } from 'layouts/box/Box';

import { FieldHeader } from '../../styled';
import { FieldProps } from '../fields';
import { TextWrapper } from '../text/styled';
import { isSingleArray } from '../utils';

import TreeChoiceDefault from './TreeChoiceDefault';

export function TreeChoiceField({
  fieldModel,
  value,
  setValue,
  fieldSettings,
  defaultFieldSettings,
  style,
  errorMessage,
  disableEdit,
}: Readonly<FieldProps>) {
  const [, openTreeChoiceDialog] = useTreeChoiceDialog();
  const { fieldId } = fieldModel;
  const { label, hint } = fieldSettings ?? defaultFieldSettings;
  const [hasMadeChange, setHasMadeChange] = useState(false);

  const selectedValue = useMemo(() => {
    if (isSingleArray(value)) {
      return value;
    }
    return [];
  }, [value, hasMadeChange]);

  const setSelected = useCallback(
    (val: string[]) => {
      setValue(val);
      setHasMadeChange(true);
    },
    [setValue, setHasMadeChange],
  );

  const openTreeChoice = useCallback(
    () =>
      openTreeChoiceDialog({
        open: true,
        fieldId,
        selected: selectedValue,
        setSelected,
        treeAlternatives: fieldModel.treeAlternatives,
      }),
    [fieldId, selectedValue, setSelected, fieldModel.treeAlternatives],
  );

  return (
    <TextWrapper key={fieldId} style={style}>
      <FieldHeader>{label}</FieldHeader>
      <VStack gap="2px" alignItems="start">
        <HStack justifyContent="space-between" width="100%">
          <TreeChoiceDefault onClick={openTreeChoice} choice={selectedValue} hint={hint} />
          {!disableEdit && selectedValue?.length > 0 && (
            <IconButton title="Clear value" usage="text" size={24} onClick={() => setValue([])}>
              <Close />
            </IconButton>
          )}
        </HStack>

        {errorMessage && (
          <Text variant="caption" color="statusWarning">
            {errorMessage}
          </Text>
        )}
      </VStack>
    </TextWrapper>
  );
}
