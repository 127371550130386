import { useRef } from 'react';
import { type Table } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';

import { DataTableHeader } from 'features/grids/common/components/TableHeader';
import { TableRoot } from 'features/grids/common/components/TableRoot';
import { ReorderRows } from 'features/grids/widget/components/dnd/ReorderRows';
import { DndBody } from 'features/grids/widget/components/dnd/TableDndBody';
import { ParsedMemberType } from 'types';
interface DataTableProps {
  table: Table<ParsedMemberType>;
  boardId: string;
  groupId?: string;
  itemIds?: string[];
}

/** Sets up a data table with drag and drop support */
export function GridWidgetDataTable({
  table,
  boardId,
  groupId,
  itemIds,
}: Readonly<DataTableProps>) {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const visibleColumns = table.getVisibleLeafColumns();
  const columnVirtualizer = useVirtualizer({
    count: visibleColumns.length,
    estimateSize: (index) => visibleColumns[index].getSize(),
    getScrollElement: () => tableContainerRef.current,
    horizontal: true,
    overscan: 50,
  });
  const rowVirtualizer = useVirtualizer({
    count: table.getRowModel().rows.length,
    estimateSize: () => 33,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 100,
  });
  const virtualColumns = columnVirtualizer.getVirtualItems();
  let virtualPaddingLeft: number | undefined;
  let virtualPaddingRight: number | undefined;

  if (columnVirtualizer && virtualColumns?.length) {
    virtualPaddingLeft = virtualColumns[0]?.start ?? 0;
    virtualPaddingRight =
      columnVirtualizer.getTotalSize() - (virtualColumns[virtualColumns.length - 1]?.end ?? 0);
  }

  return (
    <ReorderRows itemIds={itemIds} tableId={boardId} itemGroupId={groupId}>
      <div
        ref={tableContainerRef}
        style={{
          overflow: 'auto',
          position: 'relative',
          height: '100%', // calculate height?
        }}
      >
        <TableRoot table={table}>
          <DataTableHeader table={table} />
          <DndBody
            table={table}
            itemIds={itemIds}
            virtualColumns={virtualColumns}
            virtualPaddingLeft={virtualPaddingLeft}
            virtualPaddingRight={virtualPaddingRight}
            rowVirtualizer={rowVirtualizer}
            onClick={() => {}}
            boardId={boardId}
            groupId={groupId}
          />
        </TableRoot>
      </div>
    </ReorderRows>
  );
}
