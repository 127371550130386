import { memo } from 'react';

import { elementTypes, markTypes } from 'components/editor/constants';

import CheckListButton from '../checkListButton/CheckListButton';
import ColorButton from '../colorButton';
import ElementButton from '../elementButton';
import HorizontalRuleButton from '../horizontalRuleButton/HorizontalRule';
import ImageButton from '../imageButton';
import LinkButton from '../linkButton';
import ListButton from '../listButton';
import MarkButton from '../markButton';
import PlaceholderButton from '../placeholderButton';
import QuoteButton from '../quoteButton/QuoteButton';
import UppercaseButton from '../uppercaseButton/UppercaseButton';
import VideoButton from '../videoButton';

import { Container, Empty, InnerWrapper, OuterWrapper } from './styled';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const { UNORDERED_LIST, HEADING_FOUR, HEADING_ONE, HEADING_THREE, HEADING_TWO, ORDERED_LIST } =
  elementTypes;

const placeHolders = [...Array(20).keys()];

interface WrapperProps {
  children: React.ReactNode;
}
function Wrapper({ children }: Readonly<WrapperProps>) {
  return (
    <OuterWrapper>
      <InnerWrapper>{children}</InnerWrapper>
    </OuterWrapper>
  );
}

interface GeneralToolbarProps {
  hideRightButtons?: boolean;
  isAllowed?: boolean;
}

function GeneralToolbar({ hideRightButtons, isAllowed }: Readonly<GeneralToolbarProps>) {
  return (
    <Container>
      <Wrapper>
        <ElementButton type={HEADING_ONE} />
        <ElementButton type={HEADING_TWO} />
        <ElementButton type={HEADING_THREE} />
        <ElementButton type={HEADING_FOUR} />
      </Wrapper>
      <Wrapper>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <MarkButton type={STRIKE_THROUGH} />
        <UppercaseButton />
      </Wrapper>
      <Wrapper>
        <ListButton type={UNORDERED_LIST} />
        <ListButton type={ORDERED_LIST} />
      </Wrapper>
      <Wrapper>
        <CheckListButton />
        <QuoteButton />
        <LinkButton />
        <HorizontalRuleButton />
      </Wrapper>
      <Wrapper>
        <ColorButton />
      </Wrapper>
      {!hideRightButtons && (
        <Wrapper>
          {!isAllowed && <PlaceholderButton />}
          <VideoButton />
          <ImageButton />
          <ImageButton isGif />
        </Wrapper>
      )}

      {placeHolders.map((key) => (
        <Empty key={key} />
      ))}
    </Container>
  );
}

export default memo(GeneralToolbar);
