import { v4 } from 'uuid';

import { Resource } from 'hooks/useResourceDetails';
import { Metadata } from 'types/forms/forms';
import {
  CreateMemberInput,
  CrudActionEnum,
  Mdf,
  MemberTypeEnum,
  UpdateMemberInput,
} from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

/**
 * Warning: If new field is added here, please make sure to add it on subscription
 * and backend subscription code to reflect on the view
 */
export interface Block {
  mId: string;
  mRefId: string;
  mTitle: string;
  metadata: Metadata;
  mType: MemberTypeEnum.Block;
  mdfId: string;
  mSecId: string;
  mResourceId?: string; // Resource id for `mId`
  mSecRefId?: string; // Rundown id for linear instance
  mCreatedAt: string;
  mUpdatedAt: string;
  mUpdatedById: string;
  crudAction: CrudActionEnum;
}

export type BlockWithLabel = Block & { commandLabel: string; color?: string };
export type BlockWithMdf = Block & { mdf?: Mdf };
export type BlockWithLabelAndMdf = BlockWithLabel & { mdf?: Mdf };

export type CreateBlock = Pick<
  CreateMemberInput,
  'mId' | 'mRefId' | 'mdfId' | 'mSecId' | 'mResourceId' | 'mSecRefId' | 'mResourceType'
>;
export type UpdateBlockTitle = Pick<UpdateMemberInput, 'mId' | 'mRefId' | 'mTitle'>;

export const createBlockInput = (resource: Resource, cmd: EditorCommandConfigType) => {
  const input: CreateBlock = {
    mId: resource.mId,
    mRefId: v4(),
    mdfId: cmd.mSecId,
    mSecId: cmd.mRefId,
    mResourceType: resource.mType,
  };

  if (resource.mType === MemberTypeEnum.Instance) {
    if (resource.mStoryId) input.mResourceId = resource.mStoryId;
    const { mProperties } = resource;

    if (mProperties?.platform === 'linear') {
      if (mProperties.account?.accountId) input.mSecRefId = mProperties.account.accountId;
    }
  }

  return input;
};
