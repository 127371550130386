import { useCallback } from 'react';
import { ApolloCache, useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

import useToast from 'components/toast/useToast';
import { IntegrationEnum, ListActionType } from 'types/graphqlTypes';
import { MIdRefId } from 'types/members';

import {
  GET_ADMIN_ACTIONS,
  GET_USER_ACTIONS,
  GetActions,
  GetAdminActions,
} from './useGetIntegrations';

const DELETE_MEMBER = gql`
  mutation DeleteIntegration($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
      mRefId
    }
  }
`;

interface DeleteAction {
  deleteSingleMember: MIdRefId;
}

const removeIntegrationFromCache = (
  client: ApolloCache<unknown>,
  id: string,
  integrationType: IntegrationEnum,
) => {
  const cachedUserIntegrations = client.readQuery<GetActions>({
    query: GET_USER_ACTIONS,
    variables: {
      input: { type: ListActionType.User, integrationType },
    },
  });
  const cachedIntegrations = client.readQuery<GetAdminActions>({
    query: GET_ADMIN_ACTIONS,
    variables: {
      input: { type: ListActionType.Admin, integrationType },
    },
  });
  if (cachedIntegrations?.getIntegrations) {
    const updated = [...cachedIntegrations.getIntegrations.filter((f) => f.mRefId !== id)];
    client.writeQuery<GetAdminActions>({
      query: GET_ADMIN_ACTIONS,
      variables: {
        input: { type: ListActionType.Admin, integrationType },
      },
      data: {
        getIntegrations: updated,
      },
    });
  }
  if (cachedUserIntegrations?.getIntegrations) {
    const updated = [...cachedUserIntegrations.getIntegrations.filter((f) => f.mRefId !== id)];
    client.writeQuery<GetActions>({
      query: GET_ADMIN_ACTIONS,
      variables: {
        input: { type: ListActionType.User, integrationType },
      },
      data: {
        getIntegrations: updated,
      },
    });
  }
};

const returnType = (type: IntegrationEnum): string => {
  let typeString: string = '';
  if (type === IntegrationEnum.CustomAction) {
    typeString = 'action';
  } else if (type === IntegrationEnum.SearchPlugin) {
    typeString = 'plugin';
  } else {
    typeString = 'webhook';
  }
  return typeString;
};

export const useDeleteIntegration = () => {
  const [deleteMember] = useMutation<DeleteAction>(DELETE_MEMBER);
  const { toast } = useToast();

  const deleteIntegration = useCallback(
    async (mRefId: string, type: IntegrationEnum) => {
      await deleteMember({
        variables: { input: { mId: type, mRefId } },
        update(proxy) {
          removeIntegrationFromCache(proxy, mRefId, type);
          toast({
            title: 'Deleted',
            description: `Successfully deleted ${returnType(type)}`,
            type: 'success',
          });
        },
      });
    },
    [deleteMember],
  );

  return { deleteIntegration };
};
