import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';

import { ReactComponent as CommentAdd } from 'assets/icons/systemicons/comment/comment_add.svg';
import { ReactComponent as CommentDone } from 'assets/icons/systemicons/comment/comment_completed.svg';
import { ReactComponent as CommentNumber } from 'assets/icons/systemicons/comment/comment_number.svg';
import { ReactComponent as CommentOff } from 'assets/icons/systemicons/comment/comment_off.svg';
import { IconButton } from 'components/buttons';
import { ConfirmDialog } from 'components/dialogs/CommonDialogs';
import Popover from 'components/dialogs/PopoverBuilder';
import Text from 'components/text/Text';
import UserContext from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { Box, VStack } from 'layouts/box/Box';
import GET_COMMENTS from 'operations/queries/getComments';
import { rightSelectionFromMType, useRightHidden, useRightSelection } from 'store/sidebar';
import { EditorValue } from 'types/editor';
import { User } from 'types/members';
import { Conversation } from 'types/messageHub';

import useCreateComments from './hooks/useCreateComments';
import CommentDetails from './CommentDetails';
import CommentInput from './CommentInput';
import ThreadDetails from './ThreadDetails';

const DetailsWrapper = styled('div')`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  overflow-y: auto;
`;

const CountWrapper = styled('div')`
  position: absolute;
  width: 16px;
  height: 12px;
  right: 1px;
  bottom: 4px;
  text-align: center;
`;

interface CommentProps {
  blockId: string;
  resourceId?: string;
  resourceType?: string;
  rundownId?: string;
  spaceId?: string;
  formId?: string;
  ownerId?: string;
  assigneeId?: string;
  showComment?: boolean;
  updatePane?: () => void;
}

function CommentsComponent({
  blockId,
  resourceId,
  resourceType,
  rundownId,
  spaceId,
  formId,
  ownerId,
  assigneeId,
  showComment,
  updatePane,
}: CommentProps) {
  const { mId: userId } = useContext(UserContext);
  const [createComment] = useCreateComments();
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();

  const [open, setOpen] = useState(showComment);
  const [hovering, setHovering] = useState(false);
  const [tempComment, setTempComment] = useState<EditorValue | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showComment) {
      setOpen(showComment);
    }
  }, [showComment]);

  const { data } = useQuery<{ getMessagesOfConversation: { items: Conversation[] } }>(
    GET_COMMENTS,
    {
      variables: {
        input: {
          mId: blockId,
        },
      },
      skip: !blockId,
      fetchPolicy: 'cache-and-network',
    },
  );

  const [comments, parentComment] = useMemo(
    () => [
      (data?.getMessagesOfConversation?.items.filter((item) => !item?.mState) ?? []).sort(
        (a, b) => new Date(b.mCreatedAt ?? '').getTime() - new Date(a.mCreatedAt ?? '').getTime(),
      ),
      data?.getMessagesOfConversation?.items.find((item) => item?.mState) ?? undefined,
    ],
    [data?.getMessagesOfConversation?.items],
  );

  const updateCommentState = useCallback(async () => {
    if (parentComment) {
      await createComment({
        ...parentComment,
        mState: 'created',
        crudAction: 'UPDATE',
      });
    }
  }, [createComment, parentComment]);

  const addComment = useCallback(
    async (newComment?: EditorValue) => {
      await createComment({
        mId: blockId,
        mContent: JSON.stringify(newComment),
        mResourceId: resourceId,
        mResourceType: resourceType,
        mStoryId: rundownId,
        mSecId: spaceId,
        mSecRefId: formId,
        mTertId: ownerId,
        mTertRefId: assigneeId,
        mState: parentComment ? undefined : 'created',
      });
    },
    [
      assigneeId,
      blockId,
      createComment,
      formId,
      ownerId,
      parentComment,
      resourceId,
      resourceType,
      rundownId,
      spaceId,
    ],
  );

  const handleCommentAction = useCallback(
    async (newComment?: EditorValue) => {
      if (newComment) {
        if (parentComment?.mState === 'done') {
          setTempComment(newComment);
        } else {
          await addComment(newComment);
        }
      }
    },
    [parentComment, addComment],
  );

  const handleConfirmDialog = useCallback(async () => {
    setLoading(true);
    await updateCommentState();
    if (tempComment) await addComment(tempComment);
    setLoading(false);
    setTempComment(null);
  }, [updateCommentState, addComment, tempComment]);

  const openUserDetails = useCallback(
    (user?: User) => {
      if (user) {
        setRightHidden(false);
        setRightSelection(rightSelectionFromMType(user.mType));
      }
    },
    [setRightHidden, setRightSelection],
  );

  const onOpenChange = useCallback(() => {
    setOpen(!open);
    if (updatePane) updatePane();
  }, [open, updatePane]);

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild onClick={(e) => e.stopPropagation()}>
        <Box style={{ position: 'relative', top: '4px' }} padding="4px 4px 4px 4px">
          <IconButton
            title="Comments"
            size={24}
            iconSize={24}
            usage="text"
            onMouseOut={() => setHovering(false)}
            onMouseOver={() => setHovering(true)}
          >
            {parentComment ? (
              parentComment?.mState === 'created' ? (
                <>
                  <CommentNumber />
                  <CountWrapper>
                    <Text
                      variant="captionMedium"
                      color="blackHighEmphasis"
                      style={{ fontSize: '10px' }}
                    >
                      {comments.length > 98 ? '1..' : comments.length + 1}
                    </Text>
                  </CountWrapper>
                </>
              ) : (
                <CommentDone />
              )
            ) : hovering ? (
              <CommentAdd />
            ) : (
              <CommentOff />
            )}
          </IconButton>
        </Box>
      </Popover.Trigger>
      <Popover.Content onClick={(e) => e.stopPropagation()} style={{ padding: '0' }}>
        <VStack
          width="400px"
          gap="4px"
          style={{ paddingInline: '0px', maxHeight: '50vh', minHeight: '0px' }}
        >
          {parentComment && (
            <ThreadDetails
              comment={parentComment}
              currentUserId={userId}
              showDivider={comments.length > 0}
              openUserDetails={openUserDetails}
              setOpen={setOpen}
            />
          )}
          {comments.length > 0 && (
            <DetailsWrapper>
              {comments.map((comment) => (
                <CommentDetails
                  key={comment.mRefId}
                  comment={comment}
                  currentUserId={userId}
                  openUserDetails={openUserDetails}
                />
              ))}
            </DetailsWrapper>
          )}
          <CommentInput onSave={handleCommentAction} />
          <ConfirmDialog
            open={!!tempComment}
            onClose={() => setTempComment(null)}
            onClick={handleConfirmDialog}
            title="This thread is marked as resolved"
            message={`
              To add a comment, you need to reopen the thread.
              Would you like to proceed?`}
            confirmLabel="Reopen thread"
            loading={loading}
            overlayStyle={{ zIndex: 1400 }}
          />
        </VStack>
      </Popover.Content>
    </Popover>
  );
}

function Comments(props: CommentProps) {
  const [checkUserRight] = useCheckUserRight();
  const commentEnabled = checkUserRight('feature', 'comments');

  if (!commentEnabled) return null;
  return <CommentsComponent {...props} />;
}

export default Comments;
