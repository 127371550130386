import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Command as CommandPrimitive } from 'carloslfu-cmdk-internal';

export const StyledCommand = styled(CommandPrimitive)<{
  innerHeight?: number;
  mounted?: boolean;
}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  height: 100%;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  max-height: 70vh;
  ${({ mounted }) =>
    mounted &&
    css`
      border-radius: 0px;
      max-height: 100%;
    `}

  & > [cmdk-group]:not([hidden]) ~ [cmdk-group] {
    padding-top: 0;
  }

  & [cmdk-group-heading] {
    padding: 4px 0px 0px 8px;
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    ${({ theme }) => theme.typography.dina.captionMedium};
  }

  & [cmdk-group-items] {
    position: relative;
  }

  & [cmdk-group] {
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  }

  & [cmdk-item] {
    padding-left: 8px;
    ${({ theme }) => theme.typography.dina.listItemLabelMedium};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: inherit;
    :hover {
      background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    }
    :first-of-type {
      margin-top: 8px;
    }
    &[aria-selected='true'] {
      background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
      kbd {
        opacity: 1;
      }
    }
    &.search-item {
      border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
    }
  }

  & [cmdk-input-wrapper] {
    height: 48px;
    min-height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
    padding-inline: 8px;

    svg {
      height: 20px;
      width: 20px;
      margin-right: 4px;
    }
  }

  & [cmdk-input] {
    height: 48px;
    min-height: 48px;
    width: 100%;
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
    ${({ theme }) => theme.typography.dina.listItemLabelMedium};
    background-color: transparent;
    border: none;

    ::placeholder {
      color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    }

    :focus {
      outline: none;
      border: none;
    }
  }

  & [cmdk-empty] {
    ${({ theme }) => theme.typography.dina.listItemLabelMedium};
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    padding: 8px 12px;
  }

  & [cmdk-list] {
    overflow: auto;
    height: 100%;
  }

  & [data-value='search results'] {
    display: block;
  }
`;
