import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import isEqual from 'lodash/isEqual';
import Editor from 'components/editor';
import getInitialValue from 'components/editor/constants/initialValues';
import editorVariants from 'components/editor/constants/types/editorVariants';
import { Button } from 'components/buttons';
import removeTrailingEmptyParagraphs from 'components/editor/utils/removeTrailingEmptyParagraphs';
import { EditorWrapper, ButtonsWrapper } from './messageInput-styled';

const BUTTON_WIDTH = 112;
const BUTTON_HEIGHT = 32;
const BUTTON_PADDING = 12;

const initialValue = getInitialValue();

const MessageInput = ({
  messageValue,
  onSend,
  users,
  isEditingMessage,
  setIsEditingMessage,
  handleSaveMessage,
  handleCancelEdit,
  showDoneButton,
  keepFocus,
}) => {
  const theme = useTheme();

  const [value, setValue] = useState(null);
  const [updatedMessageText, setUpdatedMessageText] = useState(null);

  const handleSend = async (newMessage) => {
    const transformedMessage = removeTrailingEmptyParagraphs(newMessage);
    if (!isEqual(transformedMessage, initialValue)) {
      await onSend(transformedMessage);
    }
  };

  const suggestedUsers = useMemo(() => users, []);

  const handleUpdate = (updatedValue) => {
    setUpdatedMessageText(updatedValue);
  };

  const handleSaveEditedMessage = async () => {
    const { payload } = updatedMessageText || {};
    const transformedMessage = removeTrailingEmptyParagraphs(payload);
    if (isEqual(transformedMessage, initialValue)) return;
    if (!payload || isEqual(transformedMessage, messageValue)) setIsEditingMessage(false);
    else await handleSaveMessage(transformedMessage);
  };

  useEffect(() => {
    setValue(messageValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EditorWrapper>
      <Editor
        isAllowed
        enableEmoji
        users={suggestedUsers}
        height={150}
        readOnly={false}
        variant={editorVariants.MESSAGE}
        value={value}
        onDone={isEditingMessage ? handleSaveEditedMessage : handleSend}
        showHoveringTooltip={false}
        padding={0}
        background={theme.palette.dina.inputBackground}
        showDoneButton={showDoneButton}
        update={handleUpdate}
        keepFocus={keepFocus}
      />

      {isEditingMessage && (
        <ButtonsWrapper>
          <Button
            width={BUTTON_WIDTH}
            height={BUTTON_HEIGHT}
            padding={BUTTON_PADDING}
            variant="outlined"
            usage="outlined"
            onClick={handleCancelEdit}
          >
            Cancel
          </Button>
          <Button
            width={BUTTON_WIDTH}
            height={BUTTON_HEIGHT}
            padding={BUTTON_PADDING}
            variant="contained"
            usage="significant"
            onClick={handleSaveEditedMessage}
          >
            Ok
          </Button>
        </ButtonsWrapper>
      )}
    </EditorWrapper>
  );
};

MessageInput.propTypes = {
  /** slate json object to be shown in editor */
  messageValue: PropTypes.shape({
    document: PropTypes.arrayOf(PropTypes.shape({})),
    version: PropTypes.string,
  }),
  /** callback for sending message */
  onSend: PropTypes.func,
  /** users to mention on the message */
  users: PropTypes.arrayOf(PropTypes.object),
  /** keep focus after on done, be default set to true */
  keepFocus: PropTypes.bool,
};

MessageInput.defaultProps = {
  messageValue: initialValue,
  onSend: () => {},
  users: [],
  keepFocus: true,
};

export default MessageInput;
