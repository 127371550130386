import { SearchResultItem, SupportedSources } from 'features/searchPlugin/types';
import { ExternalUrl, useExternalUrls } from 'store/externalUrls';
import { RightSelection, useRightHidden, useRightSelection } from 'store/sidebar';

const getPanelId = (url: ExternalUrl): RightSelection => {
  switch (url.type) {
    case 'pilotedge':
      return 'graphicsbox';

    case 'mimir':
      return 'storybox';

    case 'mos':
      return 'mos';

    default:
      return `generic-${url.id}`;
  }
};

const getDestination = (source: SupportedSources) => {
  switch (source.toLowerCase()) {
    case 'framelightx':
      return 'FLX';
    default:
      return source;
  }
};

const postMessage = (message: unknown, frameId: string) => {
  const ifrm = document.getElementById(frameId) as HTMLIFrameElement | null;
  ifrm?.contentWindow?.postMessage(message, '*');
};

const postMessageToSource = (id: string, source: SupportedSources, frameId: string) => {
  const message = {
    action: 'open_item',
    payload: id,
    destination: getDestination(source),
  };

  postMessage(message, frameId);
};

const useOpenSearchResult = () => {
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();
  const [externalUrls] = useExternalUrls();

  const getExternalUrl = (id: string) => {
    return externalUrls.find((url) => url.label?.toLowerCase() === id?.toLowerCase());
  };

  const openResult = (item: SearchResultItem) => {
    if (item.externalUrl) {
      window.open(item.externalUrl, '_blank');
      return;
    }

    const { id: itemId, source } = item;
    if (!source) return;
    const url = getExternalUrl(source);
    if (!url) return;

    const rightSelection = getPanelId(url);

    setRightHidden(false);
    setRightSelection(rightSelection);
    postMessageToSource(itemId, source, url.type === 'generic' ? url.mTitle : url.type);
  };

  return openResult;
};

export default useOpenSearchResult;
