/* eslint-disable sort-imports */
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { endOfDay } from 'date-fns';
import { capitalize, uniq, uniqBy } from 'lodash';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { SearchParameters } from 'api/search/useSearch';
import { ReactComponent as MetadataInactive } from 'assets/icons/search/metadata.svg';
import { ReactComponent as MetadataActive } from 'assets/icons/search/metadata_on.svg';
import AddMemberDropdown from 'components/addMember';
import { IconButton } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import Popover from 'components/dialogs/PopoverBuilder';
import Divider from 'components/divider';
import { DateRange, useDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Switch from 'components/switch';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { CommandToolbarProps } from 'features/command/command-types';
import { getDateRange, isFiltering } from 'features/command/command-utils';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useGetPlatforms from 'hooks/useGetPlatforms';
import useGetStatuses from 'hooks/useGetStatuses';
import { HStack, VStack } from 'layouts/box/Box';
import { CheckboxWithLabel } from 'lib/checkbox';
import { AssignedMember } from 'types';
import { Metadata } from 'types/forms/forms';
import {
  Alternative,
  Mdf,
  MemberType,
  MemberTypeEnum,
  RangeBy,
  SearchItemTypeEnum,
} from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

import SearchChips from '../chips/SearchChips';
import { rundownStatusLabels, ToolbarIcons } from '../command-constants';

import SearchExport from './SearchExport';

import {
  ClearAll,
  ColumnHeader,
  FirstRow,
  Info,
  ListItem,
  MoreToolbarItem,
  StyledCaret,
  StyledText,
  ToolbarItem,
  Wrapper,
} from './styled';

const defaultFilterableMTypes: SearchItemTypeEnum[] = [
  SearchItemTypeEnum.story,
  SearchItemTypeEnum.rundown,
  SearchItemTypeEnum.instance,
  SearchItemTypeEnum.space,
  SearchItemTypeEnum.pitch,
  SearchItemTypeEnum.asset,
  SearchItemTypeEnum.order,
  SearchItemTypeEnum.contact,
  SearchItemTypeEnum.note,
  SearchItemTypeEnum.user,
  SearchItemTypeEnum.block,
];

const rundownStatuses = ['ready', 'active', 'notReady'];

type FilterKeys = Pick<CommandToolbarProps, 'assignedIds' | 'createdByIds'>;

const getAllStatusOptions = (
  forms: OrderFormMemberType[],
  selectedMdfId: string | undefined,
): Alternative[] => {
  let opt: Alternative[] = [];

  if (selectedMdfId) {
    for (const orderForm of forms) {
      if (orderForm.mSecId === selectedMdfId) {
        const config = orderForm.configs.find((c) => c.key === 'statuses');
        return [...(config?.alternatives ?? [])];
      }
    }
  } else {
    for (const orderForm of forms) {
      const config = orderForm.configs.find((c) => c.key === 'statuses');
      opt = [...opt, ...(config?.alternatives ?? [])];
    }
  }
  return uniqBy(opt, (o) => o.value);
};

const labelMap: Record<string, string> = {
  [MemberTypeEnum.Story]: 'Story',
  [MemberTypeEnum.Rundown]: 'Rundown',
  [MemberTypeEnum.Instance]: 'Instance',
  [MemberTypeEnum.Space]: 'Space',
  [MemberTypeEnum.Pitch]: 'Pitch',
  [MemberTypeEnum.Asset]: 'Asset',
  [MemberTypeEnum.Order]: 'Order',
  [MemberTypeEnum.Contact]: 'Contact',
  [MemberTypeEnum.Note]: 'Note',
  [MemberTypeEnum.User]: 'User',
  [MemberTypeEnum.Block]: 'Planning item',
  [MemberTypeEnum.Draft]: 'Draft',
};

export function CommandToolbar({
  toolbarState,
  setToolbarState,
  toolbarHeight,
  setToolbarHeight,
  allMembersKeyed,
  toggleSidePanel,
  resetAll,
  clearFieldValue,
  showSidePanel,
  metadataFilter,
  searchParams,
  totalSearchHits = 0,
  mdfs,
  mdfId,
  hideDateFilter,
  filterableMTypes = defaultFilterableMTypes,
  disableSidePanel = false,
}: Readonly<{
  toolbarState: CommandToolbarProps;
  setToolbarState: React.Dispatch<React.SetStateAction<CommandToolbarProps>>;
  setToolbarHeight: React.Dispatch<React.SetStateAction<number>>;
  metadataFilter: Metadata;
  toolbarHeight: number;
  showSidePanel: boolean;
  searchParams?: SearchParameters;
  totalSearchHits?: number;
  toggleSidePanel: () => void;
  clearFieldValue: (fieldId: string) => void;
  allMembersKeyed: Record<string, AssignedMember>;
  items: MemberType[];
  mdfs: Mdf[];
  mdfId: string | undefined;
  resetAll: () => void;
  hideDateFilter?: boolean;
  filterableMTypes?: SearchItemTypeEnum[];
  disableSidePanel?: boolean;
}>) {
  const { platforms } = useGetPlatforms(new Date());
  const [checkUserRight] = useCheckUserRight();
  const showSemanticSearch = checkUserRight('feature', 'semanticSearch');
  const { orderForms } = useGetOrderForms();
  const { instanceStatuses } = useGetStatuses();
  const [, openPicker] = useDatePicker();
  const [isFinding, setIsFinding] = useState(false);
  const [filterKey, setFilterKey] = useState<keyof FilterKeys>('assignedIds');

  const showTaskStatuses = toolbarState.mTypes.includes(SearchItemTypeEnum.order);
  const [showConfirmExport, setShowConfirmExport] = useState(false);

  const allTaskStatuses = useMemo(() => {
    return getAllStatusOptions(orderForms, mdfId);
  }, [orderForms, mdfId]);

  const filterableTypes = useMemo(() => {
    const fTypes = [];
    if (Array.isArray(platforms)) {
      for (const platform of platforms) {
        if (platform.mProperties.platformKind === 'audio') {
          fTypes.push('audio');
        } else {
          fTypes.push(platform.mProperties.platform);
        }
      }
    }
    return fTypes;
  }, [platforms]);

  const updateState = useCallback(
    (val: Partial<CommandToolbarProps>) => {
      setToolbarState((prevState) => {
        const newValue = {
          ...prevState,
          ...val,
        };
        return {
          ...newValue,
          isFiltering: isFiltering(newValue),
        };
      });
    },
    [setToolbarState],
  );

  const toggleMState = useCallback(
    (instanceMState: string, type: SearchItemTypeEnum) => {
      const copy = [...toolbarState.statusFilter];
      if (copy.includes(instanceMState)) {
        updateState({ statusFilter: copy.filter((s) => s !== instanceMState) });
      } else {
        copy.push(instanceMState);
        updateState({ statusFilter: copy, mTypes: uniq([...toolbarState.mTypes, type]) });
      }
    },
    [updateState, toolbarState.statusFilter, toolbarState.mTypes],
  );

  const toggleMType = useCallback(
    (mType: SearchItemTypeEnum) => {
      let copy = [...toolbarState.mTypes];
      if (copy.includes(mType)) {
        copy = [...toolbarState.mTypes.filter((s) => s !== mType)];
      } else {
        copy.push(mType);
      }

      const shouldEmptyPlatform =
        !copy.includes(SearchItemTypeEnum.instance) &&
        (toolbarState.platformTypes ?? []).length > 0;

      updateState({ mTypes: copy, ...(shouldEmptyPlatform && { platformTypes: [] }) });
    },
    [updateState, toolbarState.mTypes, toolbarState.platformTypes],
  );

  const toggleSemanticSearch = useCallback(() => {
    updateState({ semanticSearch: !toolbarState.semanticSearch });
  }, [updateState, toolbarState.semanticSearch]);

  const toggleMatchAllAssignees = useCallback(() => {
    updateState({ matchAllAssignees: !toolbarState.matchAllAssignees });
  }, [updateState, toolbarState.matchAllAssignees]);

  const toggleShowRestrictedItems = useCallback(() => {
    updateState({
      showRestricted: !toolbarState.showRestricted,
      ...(!toolbarState.showRestricted && {
        mTypes: uniq([...toolbarState.mTypes, SearchItemTypeEnum.story, SearchItemTypeEnum.pitch]),
      }),
    });
  }, [updateState, toolbarState.showRestricted, toolbarState.mTypes]);

  const toggleRestrictedItemsOnly = useCallback(() => {
    updateState({
      restrictedItemsOnly: !toolbarState.restrictedItemsOnly,
      ...(!toolbarState.restrictedItemsOnly && {
        mTypes: uniq([...toolbarState.mTypes, SearchItemTypeEnum.story, SearchItemTypeEnum.pitch]),
      }),
    });
  }, [updateState, toolbarState.restrictedItemsOnly, toolbarState.mTypes]);

  const selectDate = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const dateRange = getDateRange(toolbarState.rangeBy);
      openPicker({
        anchorEl: ev.currentTarget,
        startValue: dateRange
          ? { startDate: dateRange.dateRange.from, endDate: dateRange.dateRange.to }
          : undefined,
        selectRange: true,
        dateType: dateRange?.key ?? 'createdAt',
        showTypeSelect: true,
        selectDate: (val: DateRange, key?: keyof RangeBy) => {
          const prop = key ? key : 'createdAt';
          updateState({
            rangeBy: {
              [prop]: {
                from: val.startDate ?? new Date().toISOString(),
                to: val.endDate ?? endOfDay(new Date(val.startDate)).toISOString(),
              },
            },
            sortBy: key,
          });
        },
      });
    },
    [updateState, openPicker, toolbarState.rangeBy],
  );

  const onSelectAssignee = useCallback(
    (newAssignee: AssignedMember[]) => {
      setIsFinding(false);
      const newId: string | undefined = newAssignee?.[0]?.mId;
      if (newId) {
        let newIds: string[] = [];
        if (toolbarState[filterKey].includes(newId)) {
          newIds = toolbarState[filterKey].filter((s) => s !== newId);
        } else {
          newIds = [...toolbarState[filterKey], newId];
        }
        updateState({ [filterKey]: newIds });
      }
    },
    [updateState, setIsFinding, toolbarState, filterKey],
  );

  const findMember = useCallback(
    (key: keyof FilterKeys) => {
      setFilterKey(key);
      setIsFinding(true);
    },
    [setFilterKey, setIsFinding],
  );

  const toggleInstanceType = useCallback(
    (type: string) => {
      let copy = [...(toolbarState.platformTypes ?? [])];
      if (copy.includes(type)) {
        copy = [...copy.filter((s) => s !== type)];
      } else {
        copy.push(type);
      }

      const shouldSetInstance =
        copy.length > 0 && !toolbarState.mTypes.includes(SearchItemTypeEnum.instance);

      updateState({
        platformTypes: copy,
        ...(shouldSetInstance && {
          mTypes: uniq([...toolbarState.mTypes, SearchItemTypeEnum.instance]),
        }),
      });
    },
    [updateState, toolbarState.platformTypes, toolbarState.mTypes],
  );

  /**
   * Anything that might affect the height of the toolbar should go into this dependency array.
   */
  useLayoutEffect(() => {
    const ele = document.getElementById('toolbar-wrapper');
    const currentHeight = ele?.getBoundingClientRect()?.height ?? 30;
    if (toolbarHeight !== currentHeight) {
      setToolbarHeight(currentHeight);
    }
  }, [
    toolbarHeight,
    toolbarState.assignedIds,
    toolbarState.createdByIds,
    toolbarState.rangeBy,
    toolbarState.statusFilter,
    metadataFilter,
    setToolbarHeight,
  ]);

  const instanceIndeterminate =
    toolbarState.mTypes.includes(SearchItemTypeEnum.instance) &&
    (toolbarState.platformTypes ?? []).length > 0;

  return (
    <Wrapper id="toolbar-wrapper" width="100%">
      <FirstRow gap="8px">
        {isFinding ? (
          <AddMemberDropdown
            autoFocus
            variant={filterKey === 'createdByIds' ? 'user' : 'all'}
            showChips={false}
            selectedMembers={[]}
            setSelectedMembers={onSelectAssignee}
            onClose={() => setIsFinding(false)}
          />
        ) : (
          <>
            <HStack justifyContent="start" gap="6px">
              <HStack flex="0 0 auto">
                <IconButton
                  width={32}
                  height={30}
                  iconHeight={20}
                  onFocus={(ev: React.FocusEvent<HTMLDivElement>) => {
                    if (ev.target) ev.target.blur();
                  }} // Hack for stuck tooltip
                  onClick={() => toggleSidePanel()}
                  title="Toggle metadata sidebar"
                  usage={showSidePanel ? 'story' : 'text'}
                  disabled={disableSidePanel}
                  borderRadius="0"
                >
                  {showSidePanel ? <MetadataActive /> : <MetadataInactive />}
                </IconButton>
                <Divider orientation="vertical" flexItem />
              </HStack>
              <Popover>
                <Popover.Trigger style={{ all: 'unset' }}>
                  <Tooltip title="Filter by type">
                    <StyledText variant="button">
                      <ToolbarIcons.Types />
                      Types
                      <StyledCaret />
                    </StyledText>
                  </Tooltip>
                </Popover.Trigger>
                <Popover.Content style={{ minWidth: '100px' }}>
                  <ToolbarItem>
                    <VStack alignItems="start">
                      <ColumnHeader variant="overline">Types</ColumnHeader>
                      <HStack alignItems="start" gap="8px">
                        <VStack alignItems="start" gap="4px">
                          {filterableMTypes.map((type) => (
                            <CheckboxWithLabel
                              key={type}
                              indeterminate={
                                type === SearchItemTypeEnum.instance && instanceIndeterminate
                              }
                              checked={toolbarState.mTypes.includes(type)}
                              onCheckedChange={() => toggleMType(type)}
                              label={labelMap[type]}
                            />
                          ))}
                        </VStack>
                        <VStack alignItems="start" gap="4px">
                          {filterableTypes.map((type: string) => (
                            <CheckboxWithLabel
                              key={type}
                              checked={(toolbarState.platformTypes ?? []).includes(type)}
                              onCheckedChange={() => toggleInstanceType(type)}
                              label={capitalize(type)}
                            />
                          ))}
                        </VStack>
                      </HStack>
                    </VStack>
                    <Popover.Close asChild>
                      <CloseIcon />
                    </Popover.Close>
                  </ToolbarItem>
                </Popover.Content>
              </Popover>
              {!hideDateFilter && (
                <Tooltip title={!toolbarState.rangeBy ? 'Search by date' : ''}>
                  <StyledText variant="button" onClick={selectDate}>
                    <ToolbarIcons.Date />
                    Date
                    <StyledCaret />
                  </StyledText>
                </Tooltip>
              )}
              <Popover>
                <Popover.Trigger style={{ all: 'unset' }}>
                  <Tooltip title={toolbarState.statusFilter.length > 0 ? '' : 'Filter by status'}>
                    <StyledText variant="button">
                      <ToolbarIcons.Status />
                      Status
                      <StyledCaret />
                    </StyledText>
                  </Tooltip>
                </Popover.Trigger>
                <Popover.Content style={{ minWidth: '100px' }}>
                  {showTaskStatuses && (
                    <ToolbarItem>
                      <VStack alignItems="start" gap="4px">
                        <ColumnHeader variant="overline">Available order statuses</ColumnHeader>
                        {allTaskStatuses.map((alt) => (
                          <CheckboxWithLabel
                            key={alt.id}
                            label={capitalize(alt.label)}
                            checked={toolbarState.statusFilter.includes(alt.value)}
                            onCheckedChange={() =>
                              toggleMState(alt.value, SearchItemTypeEnum.order)
                            }
                          />
                        ))}
                      </VStack>
                      <Popover.Close asChild>
                        <CloseIcon />
                      </Popover.Close>
                    </ToolbarItem>
                  )}
                  {!showTaskStatuses && (
                    <ToolbarItem>
                      <VStack alignItems="start" gap="4px">
                        <ColumnHeader variant="overline">Instance status</ColumnHeader>
                        {instanceStatuses.map((instance) => (
                          <CheckboxWithLabel
                            key={instance.id}
                            label={capitalize(instance.name ?? '')}
                            checked={toolbarState.statusFilter.includes(instance.id)}
                            onCheckedChange={() =>
                              toggleMState(instance.id, SearchItemTypeEnum.instance)
                            }
                          />
                        ))}
                      </VStack>
                      <VStack alignItems="start" gap="4px">
                        <ColumnHeader variant="overline">Rundown status</ColumnHeader>
                        {rundownStatuses.map((rundown) => (
                          <CheckboxWithLabel
                            key={rundown}
                            label={capitalize(rundownStatusLabels[rundown] ?? '')}
                            checked={toolbarState.statusFilter.includes(rundown)}
                            onCheckedChange={() =>
                              toggleMState(rundown, SearchItemTypeEnum.rundown)
                            }
                          />
                        ))}
                      </VStack>
                      <VStack alignItems="start" gap="4px">
                        <ColumnHeader variant="overline">Restricted</ColumnHeader>
                        <CheckboxWithLabel
                          label="Include restricted"
                          checked={toolbarState.showRestricted}
                          onCheckedChange={toggleShowRestrictedItems}
                        />
                        <CheckboxWithLabel
                          label="Restricted only"
                          checked={toolbarState.restrictedItemsOnly}
                          onCheckedChange={toggleRestrictedItemsOnly}
                        />
                      </VStack>
                      <Popover.Close asChild>
                        <CloseIcon />
                      </Popover.Close>
                    </ToolbarItem>
                  )}
                </Popover.Content>
              </Popover>
              <Tooltip title="Filter by assignees">
                <StyledText
                  variant="button"
                  onClick={() => {
                    findMember('assignedIds');
                  }}
                >
                  <ToolbarIcons.Assignees />
                  Assignee
                  <StyledCaret />
                </StyledText>
              </Tooltip>
              <Tooltip title="Filter by created">
                <StyledText
                  variant="button"
                  onClick={() => {
                    findMember('createdByIds');
                  }}
                >
                  <ToolbarIcons.Assignees />
                  Created by
                  <StyledCaret />
                </StyledText>
              </Tooltip>
              <Tooltip title="Reset search filters">
                <ClearAll onClick={resetAll} style={{ flexShrink: 0 }} />
              </Tooltip>
            </HStack>
            {(searchParams || showSemanticSearch) && (
              <>
                {searchParams && (
                  <Dialog
                    style={{ pointerEvents: 'auto', maxWidth: '396px' }}
                    open={showConfirmExport}
                    onClose={() => setShowConfirmExport(false)}
                  >
                    <SearchExport
                      searchParams={searchParams}
                      total={totalSearchHits}
                      onClose={() => setShowConfirmExport(false)}
                    />
                  </Dialog>
                )}
                <Popover>
                  <Popover.Trigger style={{ all: 'unset' }}>
                    <IconButton
                      variant="discreet"
                      usage="text"
                      noBorder
                      iconHeight={16}
                      iconWidth={4}
                      size={24}
                      round
                      title="More options"
                    >
                      <ToolbarIcons.MoreOptions />
                    </IconButton>
                  </Popover.Trigger>
                  <Popover.Content style={{ minWidth: '100px' }}>
                    <MoreToolbarItem>
                      <HStack
                        width="100%"
                        justifyContent="space-between"
                        padding="4px 0px 4px 16px"
                      >
                        <Text variant="caption">More options</Text>
                        <Popover.Close asChild>
                          <CloseIcon />
                        </Popover.Close>
                      </HStack>
                      {showSemanticSearch && (
                        <ListItem
                          firstChild={<ToolbarIcons.SemanticSearch />}
                          iconChild={
                            <Info>
                              <>
                                Use AI to enhance <br /> search capabilities{' '}
                              </>
                              <Switch
                                selected={toolbarState.semanticSearch}
                                onClick={() => toggleSemanticSearch()}
                                disabled={false}
                              />
                            </Info>
                          }
                          text="Use Semantic Search"
                        />
                      )}
                      {searchParams && (
                        <Popover.Close asChild>
                          <ListItem
                            firstChild={<ToolbarIcons.Csv />}
                            text="Export to CSV"
                            onClick={() => setShowConfirmExport(true)}
                            excludeDivider
                          />
                        </Popover.Close>
                      )}
                    </MoreToolbarItem>
                  </Popover.Content>
                </Popover>
              </>
            )}
          </>
        )}
      </FirstRow>
      <SearchChips
        toolbarState={toolbarState}
        setToolbarState={setToolbarState}
        clearFieldValue={clearFieldValue}
        allMembersKeyed={allMembersKeyed}
        metadataFilter={metadataFilter}
        mdfs={mdfs}
        mdfId={mdfId}
        toggleMatchAllAssignees={toggleMatchAllAssignees}
      />
    </Wrapper>
  );
}
