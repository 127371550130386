import { memo } from 'react';

import { elementTypes, markTypes } from 'components/editor/constants';
import useCheckUserRight from 'hooks/useCheckUserRight';

import AutomationButton from '../automationButton';
import ColorButton from '../colorButton';
import MarkButton from '../markButton';
import UppercaseButton from '../uppercaseButton/UppercaseButton';

import { Container, Empty, InnerWrapper, OuterWrapper } from './styled';

const { BOLD, ITALIC, UNDERLINE } = markTypes;

const {
  CAMERA,
  PACKAGE,
  VOICE_OVER,
  FULLSCREEN_GRAPHICS,
  LIVE,
  DVE,
  JINGLE,
  BREAK,
  TELEPHONE,
  ADLIB,
  AUDIO,
  ACCESSORY,
  OVERLAY_GRAPHICS,
} = elementTypes;

interface WrapperProps {
  children: React.ReactNode;
}
function Wrapper({ children }: Readonly<WrapperProps>) {
  return (
    <OuterWrapper>
      <InnerWrapper>{children}</InnerWrapper>
    </OuterWrapper>
  );
}

interface LinearToolbarProps {
  platformKind?: string;
}
function LinearToolbar({ platformKind }: Readonly<LinearToolbarProps>) {
  const [checkUserRight] = useCheckUserRight();
  const canShowStarCG = checkUserRight('feature', 'starcg');

  if (platformKind === 'audio')
    return (
      <Container>
        <Wrapper>
          <MarkButton type={BOLD} />
          <MarkButton type={ITALIC} />
          <MarkButton type={UNDERLINE} />
          <UppercaseButton />
          <ColorButton />
        </Wrapper>
      </Container>
    );

  return (
    <Container>
      <Wrapper>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <UppercaseButton />
        <ColorButton />
      </Wrapper>
      <Empty />
      <Wrapper>
        <AutomationButton type={CAMERA} />
        <AutomationButton type={PACKAGE} />
        <AutomationButton type={VOICE_OVER} />
        <AutomationButton type={FULLSCREEN_GRAPHICS} />
        <AutomationButton type={LIVE} />
        <AutomationButton type={DVE} />
      </Wrapper>
      <Wrapper>
        <AutomationButton type={JINGLE} />
        <AutomationButton type={BREAK} />
        <AutomationButton type={TELEPHONE} />
        <AutomationButton type={ADLIB} />
      </Wrapper>
      <Wrapper>
        {canShowStarCG ? <AutomationButton type={OVERLAY_GRAPHICS} /> : null}
        <AutomationButton type={AUDIO} />
        <AutomationButton type={ACCESSORY} />
      </Wrapper>
    </Container>
  );
}

export default memo(LinearToolbar);
