import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InlineTagWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const IconWrapper = styled('div')`
  display: none;
  position: absolute;
  right: 0;
  margin-right: 4px;
  cursor: pointer;
`;

export const Item = styled('div')`
  display: flex;
  padding: 4px;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
    .unassign-icn {
      display: flex;
    }
  }
`;

export const ItemTitle = styled('div')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  margin: 3px;
`;

export const ItemHeader = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.overline};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  `}
  padding: 10px 16px 6px 4px;
  margin-bottom: 6px;
  font-weight: 600;
`;

export const Tag = styled('div')`
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.palette.dina.watermark};
  padding-left: 4px;
  margin-right: 2px;
  border-radius: 4px;
`;

export const TagText = styled('div')`
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  :only-child {
    padding-right: 4px;
  }
`;
