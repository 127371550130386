/* eslint-disable no-console */
/* eslint-disable sort-imports */
import { createContext, useCallback, useMemo, useState } from 'react';
import type { Column, RenderRowProps, RowsChangeData, SortColumn } from 'react-data-grid';
import DataGrid, { Row } from 'react-data-grid';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import useDeleteBlock from 'api/mdfBlocks/useDeleteMdfBlock';
import { useGetBlocks } from 'api/mdfBlocks/useGetMdfBlocks';
import useUpdateMetadata from 'api/useUpdateMetadata';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import LoadingIndicator from 'components/loadingIndicator';
import { useDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import useToast from 'components/toast/useToast';
import { useTreeChoiceDialog } from 'components/treeChoiceDialog/TreeChoiceDialog';
import { useDataGridErrorSuppressor } from 'features/gridDeck/hooks/useDataGridErrorSuppressor';
import { getFieldIdFromKey, getFieldMap } from 'features/gridDeck/utils';
import { getComparator } from 'features/gridDeck/utils/compare';
import { useEditFieldValueDialog } from 'features/mdf/mdf-utils';
import { BlockColumn, BlockRow } from 'features/orderForm/types';
import { Box } from 'layouts/box/Box';
import { useAllMembersKeyed, useEditorCommands } from 'store';
import { useSetPreview } from 'store/preview';
import { Metadata } from 'types/forms/forms';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';

import 'react-data-grid/lib/styles.css';

import { extractColumnsFromBlock, extractRowsFromBlock } from './utils';

import { BlockGridWrapper } from './styled';

interface Props {
  resourceId: string;
}

export interface MapValue {
  value: boolean;
  column: Column<BlockRow, unknown>;
  immutable: boolean;
}
export type BooleanMap = Record<string, MapValue>;

export interface Filter extends Omit<BlockRow, 'id'> {
  enabled: boolean;
  status: string;
  ownerId: string;
  assigneeId: string;
}

// Context is needed to read filter values otherwise columns are
// re-created when filters are changed and filter loses focus
export const FilterContext = createContext<Filter | undefined>(undefined);

const rowKeyGetter = (row: BlockRow) => row.id;

export function BlockGrid({ resourceId }: Readonly<Props>) {
  useDataGridErrorSuppressor();
  const setPreview = useSetPreview();
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [blockToDelete, setBlockToDelete] = useState<{ mId: string; mRefId: string } | null>(null);

  const { errorToast } = useToast();
  const [, setPicker] = useDatePicker();
  const [, showEditFieldDialog] = useEditFieldValueDialog();
  const [, openTreeChoiceDialog] = useTreeChoiceDialog();

  // Store
  const [allMembersKeyed] = useAllMembersKeyed();
  const [editorCommands] = useEditorCommands();
  const commandsById = useMemo(() => {
    return keyBy(editorCommands, (c) => c.mRefId);
  }, [editorCommands]);

  // API
  const { deleteBlock } = useDeleteBlock();
  const {
    mdfsSeparated: { custom: mdfs },
  } = useGetMdfs({ all: true });
  const mdfsById = useMemo(() => {
    return keyBy(mdfs, (mdf) => mdf.id);
  }, [mdfs]);
  const updateMetadata = useUpdateMetadata();
  const { blocks, loading, refetch } = useGetBlocks(resourceId);

  const showPreview = useCallback(
    (id: string) => {
      const block = blocks.find((bl) => bl.mRefId === id);
      if (block) {
        setPreview(block as unknown as MemberType);
      }
    },
    [setPreview, blocks],
  );

  // ─── Remove Item From Grid ───────────────────────────────────────────
  const onRemoveItem = useCallback(
    (block: { mId: string; mRefId: string }) => {
      setBlockToDelete(block);
    },
    [setBlockToDelete],
  );

  const closeDeleteDialog = useCallback(() => {
    setBlockToDelete(null);
  }, [setBlockToDelete]);

  const handleDeleteBlock = useCallback(
    (keys: { mId: string; mRefId: string } | null) => {
      if (keys) {
        deleteBlock(keys)
          .catch(errorToast)
          .finally(() => {
            refetch().catch(errorToast);
            closeDeleteDialog();
          });
      }
    },
    [deleteBlock, refetch, closeDeleteDialog],
  );

  const fieldMap = useMemo(() => {
    return getFieldMap(mdfsById);
  }, [mdfsById]);

  const columns: BlockColumn[] = useMemo(() => {
    if (loading) return [];

    return extractColumnsFromBlock(
      blocks,
      fieldMap,
      mdfsById,
      // fix when useAllMembersKeyed is typed
      allMembersKeyed as unknown as Record<string, AssignedMember>,
      setPicker,
      showPreview,
      showEditFieldDialog,
      openTreeChoiceDialog,
      onRemoveItem,
    );
  }, [blocks, fieldMap, mdfsById, loading]);

  const rows = useMemo(() => {
    if (loading) return [];

    return extractRowsFromBlock(blocks, mdfsById, commandsById);
  }, [blocks, mdfsById, loading]);

  const filteredRows = useMemo(() => {
    if (sortColumns.length === 0) return rows;
    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  // ─── Handles New Rows ─────────────────────────────────────────────────
  const onNewRows = (rws: BlockRow[], data: RowsChangeData<BlockRow, unknown>) => {
    const key = data.column.key;
    for (const index of data.indexes) {
      const item = rws[index];
      if (item) {
        const value = item[key];
        if (!value) return;

        const partialUpdate: Metadata = { [getFieldIdFromKey(key)]: value };
        updateMetadata(
          item.__resourceId,
          item.id,
          partialUpdate,
          blocks.find((b) => b.mRefId === item.id)?.metadata ?? {},
          MemberTypeEnum.Block,
          item.__mdfId,
        ).catch(errorToast);
      }
    }
  };

  // ─── Renders Each Row ────────────────────────────────────────────────
  const renderRow = useCallback((key: React.Key, props: RenderRowProps<BlockRow>) => {
    return <Row {...props} />;
  }, []);

  if (loading)
    return (
      <Box>
        <LoadingIndicator />
      </Box>
    );

  return (
    <BlockGridWrapper maxHeight="100%">
      <DataGrid
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        defaultColumnOptions={{ sortable: true, resizable: true }}
        columns={columns}
        rows={filteredRows}
        renderers={{
          noRowsFallback: <div>No blocks found</div>,
          renderRow,
        }}
        rowKeyGetter={rowKeyGetter}
        onRowsChange={onNewRows}
      />
      <DeleteDialog
        open={Boolean(blockToDelete)}
        onClose={closeDeleteDialog}
        onClick={() => handleDeleteBlock(blockToDelete)}
        title="Delete block?"
        message="Are you sure you want to delete this block? This cannot be undone!"
      />
    </BlockGridWrapper>
  );
}
