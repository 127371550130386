import { useCallback } from 'react';
import { ConnectableElement, DropTargetMonitor, useDrop } from 'react-dnd';
import styled from '@emotion/styled';

import useGetMember from 'api/useGetMember';
import { MemberMdfEditor } from 'components/mdfEditor/MemberMdfEditor';
import Scrollbar from 'components/scrollbar';
import EditableText from 'components/text/EditableText';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import { PreviewProps } from 'features/preview/utils/childMap';
import useShareStory from 'hooks/useShareStory';
import { Box, VStack } from 'layouts/box/Box';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import { User } from 'types/members';
import dndTypes from 'utils/dndTypes';

import Header from './Header';
import LinkedMembers from './LinkedMembers';
import Skeleton from './Skeleton';
import useMemberDetails from './useMemberDetails';

const Container = styled(VStack)<{ hovered?: boolean }>`
  width: 450px;
  height: 100%;
  justify-content: flex-start;
  border: 1px solid
    ${({ hovered, theme }) => (hovered ? theme.palette.dina.statusWarning : 'transparent')};
`;

const Wrapper = styled(VStack)`
  flex: 1;
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
`;

type MemberDetailsPreviewProps = Omit<PreviewProps, 'member'> & {
  memberId?: string;
  member: MemberType | null;
};

function MemberDetails({ member, memberId, onClose }: Readonly<MemberDetailsPreviewProps>) {
  const { errorToast } = useToast();
  const [assignMemberToStory] = useShareStory();

  const { data, loading } = useGetMember<User>({
    mId: memberId ?? member?.mId ?? '',
    mRefId: memberId ?? member?.mId ?? '',
    fetchPolicy: 'cache-and-network',
    skip: !memberId && !member?.mId,
  });

  const { users, teams, departments, updateDescription, updateUser, updateTeam, updateDepartment } =
    useMemberDetails(data);

  const [{ hovered }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.PITCH],
    drop: (_, monitor: DropTargetMonitor<{ id: string }, User>) => {
      if (monitor) {
        assignMemberToStory(monitor?.getItem()?.id, data?.mId, data?.mType).catch((err) =>
          errorToast(err),
        );
      }
    },
    collect: (monitor) => ({ hovered: monitor.isOver() }),
  });

  const attachRef = useCallback(
    (el: ConnectableElement) => {
      dropRef(el);
    },
    [dropRef],
  );

  if (data) {
    return (
      <Container ref={attachRef} hovered={hovered}>
        <Header member={data} userCount={users?.length} onClose={onClose} />
        <Wrapper>
          <Scrollbar top={5} bottom={10}>
            <Box width="100%" padding="12px">
              {data.mType === MemberTypeEnum.User || data.mType === MemberTypeEnum.Contact ? (
                <MemberMdfEditor view="default" member={data} />
              ) : (
                <VStack alignItems="flex-start" gap="8px">
                  <Text variant="overline">Description</Text>
                  <EditableText
                    variant="rightColumnHeaderText"
                    value={data?.mDescription ?? ''}
                    onUpdate={(value) => void updateDescription(value)}
                    multiline={8}
                    placeholder="Add description"
                  />
                </VStack>
              )}
            </Box>
            {users && (
              <LinkedMembers
                type="user"
                listTitle="Users"
                members={users ?? []}
                updateMember={updateUser}
                onItemClick={undefined}
              />
            )}
            {teams && (
              <LinkedMembers
                type="team"
                listTitle="Teams"
                members={teams ?? []}
                updateMember={updateTeam}
                onItemClick={undefined}
              />
            )}
            {departments && (
              <LinkedMembers
                type="department"
                listTitle="Departments"
                members={departments ?? []}
                updateMember={updateDepartment}
                onItemClick={undefined}
              />
            )}
          </Scrollbar>
        </Wrapper>
      </Container>
    );
  }

  if (loading)
    return (
      <Box width="450px">
        <Skeleton />
      </Box>
    );

  return <span></span>;
}

export default MemberDetails;
