import { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import tooltipEnum from 'components/editor/constants/tooltipEnum';
import Tooltip from 'components/tooltip';
import transientOptions from 'theme/helpers';

interface ContainerProps {
  $isActive: boolean;
}
const Container = styled('div', transientOptions)<ContainerProps>`
  background-color: ${({ $isActive, theme }) => $isActive && theme.palette.dina.iconDisabled};
  border-radius: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  svg {
    * {
      fill: ${({ theme }) => theme.palette.dina.highEmphasis};
      fill-opacity: 0.7;
      ${({ $isActive }) =>
        $isActive &&
        css`
          fill-opacity: 0.87;
        `};
    }
  }
  :hover {
    svg {
      * {
        fill-opacity: 1;
      }
    }
  }
`;

interface ButtonBaseProps {
  IconComponent: React.FC<React.SVGProps<SVGSVGElement>>;
  type: string;
  isActive?: boolean;
  onMouseDown?: React.MouseEventHandler<SVGSVGElement>;
}

function ButtonBase({
  IconComponent = () => null,
  type,
  isActive = false,
  onMouseDown = () => {},
}: ButtonBaseProps) {
  const renderIcon = useMemo(
    () => (
      <Tooltip title={tooltipEnum[type]}>
        <Container $isActive={isActive}>
          <IconComponent className="skipOverride" onMouseDown={onMouseDown} />
        </Container>
      </Tooltip>
    ),
    [onMouseDown, type, isActive],
  );

  return renderIcon;
}

export default memo(ButtonBase);
