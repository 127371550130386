import Dialog from 'components/dialog';
import LoadingIndicator from 'components/loadingIndicator';
import PdfViewer from 'components/pdfViewer/PdfViewer';
import useTextStorage from 'hooks/useTextStorage';
import { Box } from 'layouts/box/Box';
import { DailyNote } from 'types';

import DailyNotePrintDoc from './docs/DailyNotePrintDoc';

import { ErrorWrapper, LoadingWrapper, PrintViewWrapper } from './styled';

interface Props {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  dailyNote: DailyNote;
  date: Date;
}

const View = ({ dailyNote, date }: { dailyNote: DailyNote; date: Date }) => {
  const { data, loading, error } = useTextStorage(dailyNote?.mContentKey);
  if (loading)
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );

  if (data)
    return (
      <PrintViewWrapper>
        <PdfViewer>
          <DailyNotePrintDoc content={data} date={date} />
        </PdfViewer>
      </PrintViewWrapper>
    );

  if (error)
    return (
      <ErrorWrapper>
        <Box>Encountered following error while printing</Box>
        <Box>
          <pre>{JSON.stringify(error)}</pre>
        </Box>
      </ErrorWrapper>
    );

  return null;
};

const DailyNotePrint = ({ isDialogOpen, onCloseDialog, dailyNote, date }: Props) => {
  return (
    <Dialog container={undefined} open={isDialogOpen} onClose={onCloseDialog}>
      <View dailyNote={dailyNote} date={date} />
    </Dialog>
  );
};

export default DailyNotePrint;
