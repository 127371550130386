import { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import { StyledCreateButton } from 'components/createStoryItems/styled';
import { isPlatformArray } from 'components/editMdfDialog/components/EditOrderFormProperties';
import LoadingIndicator from 'components/loadingIndicator';
import InstanceSelector from 'components/menu/createInstanceMenu/MenuContent/SingleInstance';
import useFilterPlatforms from 'hooks/useFilterPlatforms';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { HStack } from 'layouts/box/Box';
import { useSelectedDate } from 'screens/story/components/header/atoms';
import { PlatformData } from 'utils/instance/platform';

import { Popover } from '../../../addInstanceDialog/styled';

export const StyledHStack = styled(HStack)`
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  border-radius: 4px;
  padding: 2px 4px;
  min-height: 30px;
  cursor: pointer;
  position: relative;
  &:hover {
    background: ${({ theme }) => theme.palette.dina.backgroundHover};
    border: 1px solid ${({ theme }) => theme.palette.dina.mediumEmphasis};
    svg path {
      fill-opacity: 1;
    }
  }
`;

export interface AddInstance {
  text: string | null;
}

interface ButtonProps {
  onPlatformSelect: (platformData: PlatformData) => void;
  popupRef: React.MutableRefObject<HTMLDivElement | null>;
  loading: boolean;
}

export function CreateInstanceButton({ onPlatformSelect, popupRef, loading }: ButtonProps) {
  const [selectedDate] = useSelectedDate();
  const { platforms: data } = useGetPlatforms(selectedDate ? new Date(selectedDate) : new Date());
  const { filterPlatforms } = useFilterPlatforms();

  const [openPopup, setOpenPopup] = useState(false);

  const platforms = useMemo(() => {
    if (isPlatformArray(data)) {
      return data;
    }
    return [];
  }, [data]);

  const handlePopupClose = (platformData: PlatformData) => {
    if (platformData?.platform) {
      onPlatformSelect(platformData);
    }
    setOpenPopup(false);
  };

  return (
    <>
      <StyledHStack
        gap="8px"
        alignItems="center"
        onClick={() => {
          if (!loading) {
            setOpenPopup(true);
          }
        }}
        width="144px"
      >
        {loading ? (
          <LoadingIndicator height={24} width={24} />
        ) : (
          <>
            <StyledCreateButton
              aria-haspopup="true"
              aria-owns="create-menu"
              $notAllowed={false}
              $size={20}
              $margin="0px"
            >
              <AddIcon />
            </StyledCreateButton>
            <span>Create instance</span>
          </>
        )}
      </StyledHStack>
      <Popover open={openPopup} onClose={handlePopupClose} anchorEl={popupRef.current}>
        <div>
          <InstanceSelector
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            instanceItems={filterPlatforms(platforms)}
            canScheduleInstance
            onClose={handlePopupClose}
            publishingAt={undefined}
          />
        </div>
      </Popover>
    </>
  );
}
