import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import MemberSearchField from 'components/addMember/MemberSearchField';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { useGetMembersFromFieldValue } from 'features/gridDeck/api/useBatchGetItems';
import { FakeStyledTextField } from 'features/reusableStyled';
import { useSetPreview } from 'store/preview';
import transientOptions from 'theme/helpers';
import { MiniMember } from 'types/forms/forms';
import { MemberType } from 'types/graphqlTypes';

import { FieldHeader } from '../../styled';
import { FieldProps } from '../fields';

import {
  isMiniMemberArray,
  MAX_RELATIONS,
  SearchFilterProps,
  toMiniMember,
  toSearchParameters,
} from './relation-utils';
import { RelationItem } from './RelationItem';

import { RelationFieldWrapper } from './styled';

const isMaxConstraint = (obj: Record<string, unknown>): obj is { max: number } => {
  return 'max' in obj && typeof obj.max === 'number';
};

const sanitizeConstraint = (constraint?: Record<string, unknown>): { max?: number } => {
  if (isMaxConstraint(constraint ?? {})) return constraint as { max: number };
  return {};
};

const DefaultChoiceText = styled(Text, transientOptions)<{ $disabled?: boolean }>`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ $disabled, theme }) =>
    !$disabled
      ? css`
          :hover {
            cursor: pointer;
            color: ${theme.palette.dina.highEmphasis} !important;
          }
        `
      : ''}
`;

export function RelationField({
  fieldModel,
  fieldSettings,
  defaultFieldSettings,
  value,
  setValue,
  errorMessage,
  style,
  view,
  disableEdit,
  forms,
  commands,
}: Readonly<FieldProps>) {
  const setPreview = useSetPreview();
  const [isEditing, setIsEditing] = useState(false);
  const { hint, label } = fieldSettings ?? defaultFieldSettings;
  const { filters, constraint } = fieldModel;
  const { members } = useGetMembersFromFieldValue(value);

  const searchParams = useMemo(() => {
    return toSearchParameters((filters ?? {}) as SearchFilterProps);
  }, [filters]);

  const onSelect = useCallback(
    (newMember: MemberType) => {
      const copy = [...((value ?? []) as MiniMember[])];
      const mini = toMiniMember(newMember);
      if (!copy.find((v) => v.id === mini.id)) {
        copy.push(mini);
      }
      setValue(copy);
    },
    [setValue, value],
  );

  const onSingleSelect = useCallback(
    (newMember: MemberType) => {
      const mini = toMiniMember(newMember);
      setValue([mini]);
    },
    [setValue],
  );

  const removeRelation = useCallback(
    (ev: React.MouseEvent<Element, MouseEvent>, member: MemberType) => {
      ev.stopPropagation();
      if (isMiniMemberArray(value)) {
        const keys = toMiniMember(member);
        const idx = value.findIndex((l) => l.id === keys.id);
        if (idx >= 0) {
          const copy = [...value];
          copy.splice(idx, 1);
          setValue(copy);
        }
      }
    },
    [setValue, value],
  );

  const sanitizedConstraint = useMemo(() => sanitizeConstraint(constraint), [constraint]);
  const maxReached = useMemo(() => {
    return members.length >= (sanitizedConstraint.max ?? MAX_RELATIONS);
  }, [sanitizedConstraint, members.length]);

  /**
   * Provides specialized usability that is for 1 item specifically.
   */
  const SingleItemWorkflow = useMemo(() => {
    if (sanitizedConstraint.max !== 1 && view !== 'search_view') return;
    const singleMember = members[0];

    if (isEditing) {
      return (
        <MemberSearchField
          autoFocus
          placeholderText={hint || undefined}
          storedSearchParams={searchParams}
          onSelect={onSingleSelect}
          onBlur={() => setIsEditing(false)}
        />
      );
    }

    return singleMember ? (
      <RelationItem
        key={`${singleMember.mId}:${singleMember.mRefId}`}
        member={singleMember}
        forms={forms}
        commands={commands}
        showEditIcon
        showRemoveIcon
        onEditClick={() => setIsEditing(true)}
        onRemoveClick={(ev) => removeRelation(ev, singleMember)}
        onOpenClick={() => setPreview(singleMember)}
      />
    ) : (
      <DefaultChoiceText
        variant="listItemLabel"
        $disabled={disableEdit}
        color={disableEdit ? 'disabled' : undefined}
        onClick={() => {
          if (!disableEdit) {
            setIsEditing(true);
          }
        }}
      >
        {view === 'search_view' ? 'Search by relation' : 'Add relation'}
      </DefaultChoiceText>
    );
  }, [
    sanitizedConstraint.max,
    view,
    members,
    isEditing,
    forms,
    commands,
    disableEdit,
    hint,
    searchParams,
    onSingleSelect,
    removeRelation,
    setPreview,
  ]);

  const MultipleItemWorkflow = useMemo(() => {
    if (sanitizedConstraint.max === 1) return undefined;

    if (disableEdit && !members.length) {
      return (
        <DefaultChoiceText color="disabled" variant="listItemLabel">
          Add relation
        </DefaultChoiceText>
      );
    }
    return (
      <>
        {!disableEdit &&
          (maxReached ? (
            <Tooltip title={hint}>
              <FakeStyledTextField>
                Max number of relations ({sanitizedConstraint?.max}) have been added
              </FakeStyledTextField>
            </Tooltip>
          ) : (
            <Tooltip title={hint}>
              <span>
                <MemberSearchField
                  placeholderText={hint || undefined}
                  storedSearchParams={searchParams}
                  onSelect={onSelect}
                />
              </span>
            </Tooltip>
          ))}
        {members
          .filter((as) => Boolean(as))
          .map((m) => (
            <RelationItem
              doHover
              forms={forms}
              commands={commands}
              key={`${m.mId}:${m.mRefId}`}
              member={m}
              onRemoveClick={(ev) => removeRelation(ev, m)}
              onOpenClick={() => setPreview(m)}
              showRemoveIcon={!disableEdit}
            />
          ))}
      </>
    );
  }, [
    hint,
    members,
    maxReached,
    sanitizedConstraint,
    searchParams,
    disableEdit,
    forms,
    commands,
    setPreview,
    onSelect,
    removeRelation,
  ]);

  return (
    <RelationFieldWrapper style={{ gap: label ? '5px' : '0px', ...(style || {}) }}>
      <FieldHeader>{label}</FieldHeader>
      {sanitizedConstraint.max === 1 || view === 'search_view'
        ? SingleItemWorkflow
        : MultipleItemWorkflow}
      {errorMessage && (
        <Text variant="caption" color="statusWarning">
          {errorMessage}
        </Text>
      )}
    </RelationFieldWrapper>
  );
}
