import { useMutation } from '@apollo/client';
import graphql from 'graphql-tag';

import { Metadata } from 'types/forms/forms';
import { MemberType, MemberTypeEnum, UpdateMetadataInput } from 'types/graphqlTypes';

const UPDATE_METADATA = graphql`
  mutation UpdateMetadata($input: UpdateMetadataInput) {
    updateMetadata(input: $input) {
      mId
      mRefId
      mTitle
      metadata
    }
  }
`;

interface UpdateMetadata {
  updateMetadata: MemberType;
}

interface Input {
  input: UpdateMetadataInput;
}

const useUpdateMetadata = () => {
  const [updateMetadata] = useMutation<UpdateMetadata, Input>(UPDATE_METADATA);

  const update = async (
    mId: string,
    mRefId: string | undefined,
    updatedPartialMetadata: Metadata,
    fullMetadata: Metadata,
    mType: MemberTypeEnum,
    mdfId?: string,
  ) => {
    const input: UpdateMetadataInput = {
      mId,
      mRefId,
      metadata: JSON.stringify(updatedPartialMetadata),
      mType,
      mdfId,
    };

    return updateMetadata({
      variables: {
        input,
      },
      optimisticResponse: {
        updateMetadata: {
          mId,
          mRefId,
          metadata: JSON.stringify({ ...fullMetadata, ...updatedPartialMetadata }),
          mType,
          mdfId,
        },
      },
    });
  };

  return update;
};

export default useUpdateMetadata;
