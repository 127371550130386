import { useCallback } from 'react';
import { useContextMenu } from 'react-contexify';

import { useUpdateBoardColumns } from 'api/gridAndKanban/useUpdateBoardColumns';
import { useGetMdfs } from 'api/mdf/useGetMdfs';
import useOpenMember from 'components/contextMenu/useOpenMember';
import { ParsedMemberType } from 'types/members';

interface UseGetTableMetaProps {
  boardId: string;
  groupId: string;
}

const emptyStateText = 'Drag stories, pitches, rundowns or instances here to get started';

/** Enriches the grid widget table with additional functionality. */
export function useGetTableMeta({ boardId, groupId }: UseGetTableMetaProps) {
  const { show } = useContextMenu({ id: `gridItemMenu-${boardId}` });
  const { openItem } = useOpenMember();
  const { removeMember } = useUpdateBoardColumns(boardId);
  const { mdfs } = useGetMdfs({ all: true });

  // Show the context menu on right click.
  const onContextMenu = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, member: ParsedMemberType) => {
      show({
        event,
        props: {
          member,
        },
      });
    },
    [show],
  );

  // Open the member in a new tab on double click
  const onDoubleClick = useCallback(
    (member: ParsedMemberType) => {
      openItem(member);
    },
    [openItem],
  );

  // Open the member in a new tab.
  const onOpen = useCallback(
    (member: ParsedMemberType) => {
      if (!member.mId) return;
      openItem(member);
    },
    [openItem],
  );

  // Remove the member from the grid.
  const onRemove = useCallback(
    (member: ParsedMemberType) => {
      if (!member.mId || !groupId) return;
      void removeMember(member.mId, groupId);
    },
    [groupId],
  );

  return { meta: { onOpen, onRemove, onContextMenu, onDoubleClick, mdfs, emptyStateText } };
}
