import { isObject } from 'types';
import type {
  AccountType,
  RangeBy,
  SearchItemTypeEnum,
  SortDirection,
  SortType,
} from 'types/graphqlTypes';

export type DefaultMdfTypes = 'story-mdf' | 'contact-mdf' | 'user-mdf' | 'rundown-mdf';

export type Section =
  | 'home'
  | 'stories'
  | 'instances'
  | 'contacts'
  | 'feeds'
  | 'rundowns'
  | 'tasks'
  | 'assets'
  | 'notes';
export const Destinations = ['home', 'feeds', 'maps', 'storyhub', 'settings'] as const;
export type Destination = (typeof Destinations)[number];
export type ItemType = 'paging' | 'feed' | 'asset' | 'search' | 'search-item' | 'metadata';
export type Instruction = 'create_story' | 'create_instance' | 'open_tasks' | 'create_space';
export type ToolbarIcon =
  | 'Sort'
  | 'Caret'
  | 'Date'
  | 'Clear'
  | 'Assignees'
  | 'Status'
  | 'Csv'
  | 'MoreOptions'
  | 'ClearAll'
  | 'Types'
  | 'SemanticSearch'
  | 'Restricted';

export const treatAsSection = (val: Section | SearchItemTypeEnum): val is Section => {
  return val === 'assets' || val === 'home' || val === 'feeds';
};

interface Highlight {
  mDescription: string[] | undefined;
  mTitle: string[] | undefined;
  textContent: string[] | undefined;
}

/**
 * Toolbar
 */

export type SortOption = SortType | 'best';

export interface Option {
  key: SortOption;
  label: string;
}

export interface Order {
  key: SortDirection;
  label: string;
}

export type PopOverContents = 'sort' | 'statusFilter' | 'moreOptions' | 'types';

export type CommandToolbarProps = {
  sortBy: SortOption;
  order: SortDirection;
  mTypes: SearchItemTypeEnum[];
  rangeBy: RangeBy | null;
  statusFilter: string[];
  assignedIds: string[];
  createdByIds: string[];
  isFiltering: boolean;
  mdfId: string | null;
  defaultMdfId: DefaultMdfTypes | null;
  platformTypes?: string[];
  semanticSearch?: boolean;
  isScheduled?: boolean;
  matchAllAssignees?: boolean;
  showRestricted?: boolean;
  restrictedItemsOnly?: boolean;
};

export type CsvKeyValue = {
  [key: string]: string | null | undefined;
};

export const isHighlight = (obj: unknown): obj is Highlight => {
  if (!isObject(obj)) return false;
  const maybe = obj as Partial<Highlight>;
  return (
    Array.isArray(maybe.mDescription) ||
    Array.isArray(maybe.mTitle) ||
    Array.isArray(maybe.textContent)
  );
};

export const isAccountType = (obj: unknown): obj is AccountType => {
  if (!isObject(obj)) return false;
  const maybe = obj as Partial<AccountType>;
  return typeof maybe.accountId === 'string' || typeof maybe.accountTitle === 'string';
};
