import { useEffect } from 'react';
import gql from 'graphql-tag';

import useApolloSubscription from 'hooks/useApolloSubscription';

import useUpdateCommentsCache from './useUpdateCommentsCache';

const NOTIFY_COMMENT = gql`
  subscription comments($ids: String) {
    updateContentChat(contentIds: $ids) {
      mId
      mRefId
      mType
      mTitle
      mContent
      mCreatedAt
      mUpdatedAt
      mCreatedById
      mUpdatedById
      convoType
      crudAction
      contentIds
      mResourceId
      mResourceType
      mStoryId
      mSecId
      mSecRefId
      mTertId
      mTertRefId
      mState
    }
  }
`;

const useCommentSubscription = (contentIds: string[]) => {
  const [updateCommentCache] = useUpdateCommentsCache();

  const [subscribeComment, unsubscribeComment] = useApolloSubscription(NOTIFY_COMMENT, {
    variables: { ids: JSON.stringify(contentIds) },
    onSubscriptionData: updateCommentCache,
    skip: !contentIds?.length,
  });

  useEffect(() => {
    unsubscribeComment();
    subscribeComment();

    return () => {
      unsubscribeComment();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentIds]);
};

export default useCommentSubscription;
