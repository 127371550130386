import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { v4 } from 'uuid';

import { STANDARD_OPTION_LIST_KEYS } from 'api/commonKeys';
import { OptionList, OptionListRaw } from 'types/graphqlTypes';

export const GET_LISTS = gql`
  query GetLists {
    getOptionLists {
      ${STANDARD_OPTION_LIST_KEYS}
    }
  }
`;

export const generateIdForAlternatives = (rawList: OptionListRaw): OptionList => {
  return {
    ...rawList,
    alternatives: rawList.alternatives.map((l) => ({ ...l, id: v4() })),
  };
};

export const generateForList = (rawLists: OptionListRaw[]): OptionList[] => {
  const lists: OptionList[] = [];
  rawLists.forEach((l) => lists.push(generateIdForAlternatives(l)));
  return lists;
};

export interface GetOptionLists {
  getOptionLists: OptionListRaw[];
}

export const useGetOptionLists = () => {
  const { data, error, loading, refetch } = useQuery<GetOptionLists>(GET_LISTS, {
    fetchPolicy: 'cache-first',
  });

  const optionLists = useMemo(() => {
    return generateForList(data?.getOptionLists ?? []);
  }, [data]);

  return { optionLists, error, loading, refetch };
};
