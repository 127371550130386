import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import useCheckUserRight from 'hooks/useCheckUserRight';

import Divider from 'components/divider/divider-view';
import TabsComponent from 'components/tabs/oldTabs';
import Header from './components/header';
import Footer from './components/footer';
import UsersAndGroups from './components/settingsTabs/usersAndGroups';
import SystemSettings from './components/settingsTabs/systemSettings';
import UserSettings from './components/settingsTabs/userSettings';

import { useSelectedGroupName } from './atoms';
import { useSelectedLeftMenu } from 'screens/main/components/header/navbar/settings/atomsTs';

import { useSelectedOrganization } from './atomsTs';
import SETTING_TABS_INDEXS from './utils/settingTabsIndexes';
import { Integrations } from './components/integrations/Integrations';
import { MdfSchemas } from './components/mdf/MdfSchemas';

import { RootWrapper } from './settings-styled';

const tabs = [
  { label: 'User settings' },
  { label: 'System Settings' },
  { label: 'Organization' },
  { label: 'Integrations' },
  { label: 'Schemas' },
];
const tabsDefault = [{ label: 'User settings' }];

const Tabs = memo(TabsComponent);

const Settings = ({
  groupPolicies,
  systemSettings,
  form,
  handleCloseSettings,
  disabled,
  isSaving,
  onSaveAndClose,
  onDiscardAndClose,
  onSave,
  onCloseConfirmDialog,
  instanceTypes,
  links,
  webhooks,
  kanbanStates,
  panels,
  rundowns,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [checkUserRight] = useCheckUserRight();
  const settings = checkUserRight('feature', 'settings');
  const [selectedLeftMenu, setSelectedLeftMenu] = useSelectedLeftMenu();
  const [selectedOrganizationMenu, setSelectedOrganizationMenu] = useSelectedOrganization();
  const [selectedGroupName, setSelectedGroupName] = useSelectedGroupName();

  const handleTabChange = (_, index) => {
    if (index === SETTING_TABS_INDEXS.GROUPS) {
      selectedOrganizationMenu || setSelectedOrganizationMenu('group');
      selectedLeftMenu || setSelectedLeftMenu(groupPolicies[0]?.mRefId);
      selectedGroupName || setSelectedGroupName(groupPolicies[0]?.mTitle);
    }
    setTabIndex(index);
  };

  const viewHelper = () => {
    switch (tabIndex) {
      case SETTING_TABS_INDEXS.USER_SETTINGS:
        return (
          <UserSettings
            handleCloseSettings={handleCloseSettings}
            groupPolicies={groupPolicies}
            systemSettings={systemSettings}
          />
        );
      case SETTING_TABS_INDEXS.SYSTEM_SETTINGS:
        return (
          <SystemSettings
            systemSettings={systemSettings}
            form={form}
            instanceTypes={instanceTypes}
            links={links}
            webhooks={webhooks}
            panels={panels}
            rundowns={rundowns}
          />
        );
      case SETTING_TABS_INDEXS.GROUPS:
        return (
          <UsersAndGroups
            groupPolicies={groupPolicies}
            instanceTypes={instanceTypes}
            kanbanStates={kanbanStates}
          />
        );
      case SETTING_TABS_INDEXS.INTEGRATIONS:
        return <Integrations />;
      case SETTING_TABS_INDEXS.SCHEMAS:
        return <MdfSchemas />;
      default:
        return <div />;
    }
  };

  return (
    <RootWrapper>
      <Header onClose={handleCloseSettings} title="Settings" />
      <Tabs
        tabs={settings ? tabs : tabsDefault}
        onTabChange={handleTabChange}
        tabIndex={tabIndex}
      />
      <Divider />
      {viewHelper()}
      <Footer
        onSaveAndClose={onSaveAndClose}
        onDiscardAndClose={onDiscardAndClose}
        onCloseSettings={handleCloseSettings}
        onSave={onSave}
        disabled={disabled}
        isSaving={isSaving}
        onCloseConfirmDialog={onCloseConfirmDialog}
      />
    </RootWrapper>
  );
};

Settings.propTypes = {
  handleCloseSettings: PropTypes.func,
  groupPolicies: PropTypes.array,
  systemSettings: PropTypes.object,
  form: PropTypes.object,
  onSaveAndClose: PropTypes.func,
  onDiscardAndClose: PropTypes.func,
  onSave: PropTypes.func,
  onCloseConfirmDialog: PropTypes.func,
  disabled: PropTypes.bool,
  instanceTypes: PropTypes.array,
  links: PropTypes.object,
  webhooks: PropTypes.array,
  kanbanStates: PropTypes.array,
  panels: PropTypes.array,
};

Settings.defaultProps = {
  handleCloseSettings: () => {},
  groupPolicies: [],
  systemSettings: {},
  form: {},
  onSaveAndClose: () => {},
  onDiscardAndClose: () => {},
  onSave: () => {},
  onCloseConfirmDialog: () => {},
  disabled: false,
  instanceTypes: [],
  links: {},
  webhooks: [],
  kanbanStates: [],
  panels: [],
};

export default Settings;
