import gql from 'graphql-tag';

export default gql`
  mutation updateNote($input: UpdateNoteInput) {
    updateNote(input: $input) {
      mId
      mRefId
      mType
      mTitle
      mDescription
      locked
      mContentKey
      mDefaultContentKey
      mUpdatedAt
      mCreatedAt
      mProperties {
        __typename
        ... on NoteProperties {
          pinned
          labelId
        }
      }
    }
  }
`;
