import MUISkeleton from '@material-ui/lab/Skeleton';

import { HStack, VStack } from 'layouts/box/Box';

function Skeleton() {
  return (
    <VStack width="100%" height="100%" justifyContent="flex-start">
      <HStack width="100%" gap="8px" padding="8px" height="120px" style={{ borderBottom: 'red' }}>
        <MUISkeleton
          variant="rect"
          width={64}
          height={64}
          animation="wave"
          style={{ borderRadius: '50%' }}
        />
        <VStack flex="1" alignItems="flex-start">
          <MUISkeleton animation="wave" width="60%" height={32} />
          <MUISkeleton animation="wave" width="80%" />
        </VStack>
      </HStack>
      <VStack width="100%" padding="12px" gap="16px">
        {Array(5)
          .fill(null)
          .map((_u, i) => (
            <VStack key={i} width="100%" alignItems="flex-start">
              <MUISkeleton animation="wave" width="70%" height={32} />
              <MUISkeleton animation="wave" width="85%" />
            </VStack>
          ))}
      </VStack>
    </VStack>
  );
}

export default Skeleton;
