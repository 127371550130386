import { Document, Page, Text, View } from '@react-pdf/renderer';
import format from 'date-fns/format';

import { BlockWithLabelAndMdf } from 'api/mdfBlocks/types';
import { EditorValue, Story } from 'types';
import { OrderWithMdf } from 'types/forms/forms';
import { Mdf } from 'types/graphqlTypes';

import AutoDirText from '../components/AutoDirText';
import CommonDocFooter from '../components/CommonDocFooter';
import StoryIcon from '../components/StoryIcon';

import { getDocumentComponent } from './utils';

import { styles } from './styles';

const ContentDocHeader = ({ story }: { story: Story }) => {
  return (
    <View style={styles.header} fixed>
      {story?.mPublishingAt && (
        <Text style={styles.timingInfoTitle}>
          {format(story.mPublishingAt, 'ddd. MMMM DD, YYYY')}
        </Text>
      )}
      <View style={[styles.headerContent, { alignItems: 'center' }]}>
        <StoryIcon />
        <AutoDirText value={story.mTitle} style={styles.title} />
      </View>
      <View style={[styles.horizontalLine, { paddingTop: '8' }]} />
    </View>
  );
};

const Contents = ({ content, blocks, orders, mdfsSeparated }: Props) => {
  const { document = [] } = content;

  if (document && document.length > 0) {
    return (
      <View style={[styles.column, styles.content]}>
        {document.map((doc) => {
          return getDocumentComponent({ doc, blocks, orders, mdfsSeparated });
        })}
      </View>
    );
  }

  return null;
};

interface Props {
  content: EditorValue;
  story: Story;
  blocks: BlockWithLabelAndMdf[];
  orders: OrderWithMdf[];
  mdfsSeparated: {
    defaults: Mdf[];
    custom: Mdf[];
    subTypes: Mdf[];
    instances: Mdf[];
  };
}

const ContentPrintDoc = ({ content, story, blocks, orders, mdfsSeparated }: Props) => (
  <Document>
    <Page style={styles.body}>
      <ContentDocHeader story={story} />
      <Contents
        content={content}
        story={story}
        blocks={blocks}
        orders={orders}
        mdfsSeparated={mdfsSeparated}
      />
      <CommonDocFooter />
    </Page>
  </Document>
);

export default ContentPrintDoc;
