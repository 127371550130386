import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  type Content,
  type ContentTab,
  type Icon,
  type StoryTab,
  useContentTabs,
} from 'store/tabs';

type Page = Icon | Content;
type SearchParams = { tab: StoryTab; entityId?: string };

const getPathname = (page: Page, id?: string) => {
  let pathname = `/${page}`;
  if (id) pathname += `/${id}`;
  return pathname;
};

const getStorySearchParams = (searchParams?: SearchParams, existingTab?: ContentTab) => {
  if (existingTab?.search && searchParams?.tab) {
    const urlParams = new URLSearchParams(
      existingTab.search.substring(existingTab.search.indexOf('?') + 1),
    );
    const storyPanes = urlParams.get('storyPanes')?.split(',') ?? [];
    storyPanes[0] = `${searchParams.tab}.${searchParams.entityId ?? ''}`;
    urlParams.set('storyPanes', storyPanes.join(','));
    return `?${urlParams.toString()}`;
  }

  if (existingTab?.search) return existingTab.search;

  if (searchParams?.tab)
    return `?storyPanes=${searchParams.tab ?? ''}.${searchParams.entityId ?? ''}`;

  return '';
};

const getSearchParams = (page: Page, searchParams?: SearchParams, existingTab?: ContentTab) => {
  if (page === 'rundown' && searchParams) {
    return `?search=${searchParams.entityId}`;
  }
  return existingTab?.search ?? '';
};

type Args<T extends Icon | Content> = T extends Icon
  ? [page: T]
  : T extends 'story' | 'pitch' | 'rundown'
  ? [page: T, id: string, searchParams?: SearchParams]
  : [page: T, id: string];

const useDinaNavigate = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [contentTabs] = useContentTabs();

  const pathRef = useRef(pathname);
  const searchRef = useRef(search);
  const navigateRef = useRef(navigate);
  const contentTabsRef = useRef(contentTabs);

  const navigateTo = useCallback((...args: Args<Icon | Content>): void => {
    const [page, id, searchParams] = args;
    const existingTab = contentTabsRef.current.find((tab) => tab.id === id);

    const path = getPathname(page, id);
    const searchParam =
      page === 'story' || page === 'pitch'
        ? getStorySearchParams(searchParams, existingTab)
        : getSearchParams(page, searchParams, existingTab);

    if (path + searchParam !== pathRef.current + searchRef.current) {
      navigateRef.current(path + searchParam);
    }
  }, []);

  useEffect(() => {
    pathRef.current = pathname;
  }, [pathname]);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  useEffect(() => {
    contentTabsRef.current = contentTabs;
  }, [contentTabs]);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  return {
    navigateTo,
  };
};

export default useDinaNavigate;
