import { useEffect, useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { differenceBy } from 'lodash';
import { useSlate, useReadOnly, useSelected } from 'slate-react';

import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import { useEditorContext } from 'components/editor/EditorContext';
import { onImageLoadingError } from 'utils/image/imageUtils';
import defaultImage from 'assets/images/default/defaultThumbnail.png';
import { allowsGraphics, mediaTypes } from 'utils/rundownItemTypes';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useOpenAssetInMimir from 'hooks/useOpenAssetInMimir';
import useOpenAssetInPilotEdge from 'hooks/useOpenAssetInPilotEdge';
import useGetAsset from 'hooks/useGetAsset';
import { getThumbnailKey } from 'utils/mediaKey';
import { isMimirAssetItem, openPreviewInProvider } from 'utils/openAssetInMimir';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';
import { elementTypes } from 'components/editor/constants/types';

import getInitialData from './utils/getInitialData';
import syncAssetWithSlate from './utils/syncAssetWithSlate';

import PlaySrc from 'assets/icons/systemicons/Play_WithCircleBackground_small.svg';
import PhotoSrc from 'assets/icons/systemicons/photo.svg';
import QuestionMark from 'assets/icons/systemicons/question_mark_off.svg';
import { ReactComponent as AddPlaceholder } from 'assets/icons/systemicons/placeholder_add.svg';
import HourglassSrc from 'assets/icons/systemicons/hourglass.svg';
import { ReactComponent as DeletePlaceholder } from 'assets/icons/systemicons/placeholder_delete.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/systemicons/appMenu/info_off.svg';
import { ReactComponent as EditGraphics } from 'assets/icons/systemicons/edit_graphics.svg';

import { ConfirmDialog } from 'components/dialogs/CommonDialogs';
import SelectedElement from 'components/editor/components/selectedElement';
import PlaceholderDialog from 'components/editor/components/placeholderDialog';
import removePlaceholder from 'components/editor/components/placeholderDialog/utils/removePlaceholder';
import { refreshSelection } from 'components/editor/utils';
import { IconButton } from 'components/buttons';

import DragAndDrop from '../dragAndDrop';
import PrimaryDropZone from './components/primaryDropZone';
import MediaDropZone from './components/mediaDropZone';
import AutomationIcon from './components/automationIcon';
import Select from './components/select';
import Title from './components/title';
import MediaDialog from './components/mediaDialog';
import DetailsDialog from './components/detailsDialog';

import {
  BoxWrapper,
  ContentWrapper,
  Icon,
  PlaceholdersWrapper,
  RootWrapper,
  Thumbnail,
  ThumbnailWrapper,
  TitleWrapper,
  OpenIcon,
} from './styled';

const { PACKAGE, ADLIB, VOICE_OVER } = elementTypes;
const videoAutomationTypes = [PACKAGE, ADLIB, VOICE_OVER];

const PrimaryAutomation = ({ attributes, children, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const readOnly = useReadOnly();
  const isSelected = useSelected(element);

  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [placeholderDialogOpen, setPlaceholderDialogOpen] = useState(false);
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [showDeletePlaceholder, setShowDeletePlaceholder] = useState(false);

  const { data, type } = element;
  const initialData = useMemo(() => getInitialData(data), [data]);
  const { assets = [], templateVariant } = initialData;

  const isVideoAutomation = videoAutomationTypes.some((automation) => automation === type);

  const { templates } = useGetAutomationTemplates();
  const template = useMemo(
    () =>
      templates && templates.find((item) => item.type === type && item.variant === templateVariant),
    [templateVariant, templates, type],
  );
  const { video = false, graphics = false, image = false } = template?.requires || {};

  const graphicsAsset = assets.find((asset) => asset.mediaType === mediaTypes.GRAPHICS);
  const hasGraphicsAsset = Boolean(graphicsAsset);

  const [clipAsset] = differenceBy(assets, graphicsAsset ? [graphicsAsset] : [], 'mAssetId');
  const hasClipAsset = Boolean(clipAsset);

  const { mId, mRefId, mAssetId } = clipAsset ?? {};
  const hasAssetId = Boolean(mAssetId);
  const [newAssetData] = useGetAsset(mId, mRefId, false);
  const openAssetInMimir = useOpenAssetInMimir();
  const openAssetInPilotEdge = useOpenAssetInPilotEdge();

  useEffect(() => {
    if (newAssetData) syncAssetWithSlate(editor, element, initialData, newAssetData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAssetData]);

  const placeholder = useMemo(
    () => assets.find(({ mediaType }) => mediaType === 'video/placeholder'),
    [assets],
  );

  const hasPlaceholder = Boolean(placeholder);

  const memoizedKey = useMemo(() => mId && mRefId && getThumbnailKey(mId, mRefId), [mId, mRefId]);
  const { url: thumbSrc } = useGetSignedUrl(memoizedKey, false);

  const showCreatePlaceholderOption = Boolean(video) || isVideoAutomation;
  const showEditGraphicsOption = Boolean(graphics) || allowsGraphics(type);
  const showOpenInMimir = isMimirAssetItem(element);

  const openDetailsDialog = useCallback(() => setDetailsDialogOpen(true), []);

  const closeDetailsDialog = useCallback(() => setDetailsDialogOpen(false), []);

  const openPlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(true), []);

  const closePlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(false), []);

  const openMediaDialog = useCallback(
    (e) => {
      if (hasGraphicsAsset) return;
      preventDefaultAndPropagation(e);
      if (openPreviewInProvider(clipAsset?.mProvider)) {
        return handleOpenInMimir(clipAsset);
      }
      setMediaDialogOpen(true);
    },
    [clipAsset, hasGraphicsAsset],
  );

  const closeMediaDialog = useCallback(() => setMediaDialogOpen(false), []);

  const getValidProvider = (provider) => {
    switch (provider) {
      case 'PILOT':
      case 'XPRESSION':
      case 'xpression.mos':
        return true;
      default:
        return false;
    }
  };

  const handleEditGraphics = (e) => {
    if (!hasGraphicsAsset) return;
    if (!getValidProvider(graphicsAsset.provider)) return;

    preventDefaultAndPropagation(e);

    if (graphicsAsset?.mosobj) {
      openAssetInPilotEdge(graphicsAsset?.mosobj, element);
    }
  };

  const handleOpenInMimir = (asset) => {
    openAssetInMimir({ data: { assets: [{ ...asset }] } });
  };

  const onBlur = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  const openInMimirAction = useCallback(
    (e = undefined) => {
      if (e && readOnly) preventDefaultAndPropagation(e);

      if (!hasAssetId) {
        syncAssetWithSlate(editor, element, initialData, newAssetData);
        handleOpenInMimir({
          ...newAssetData,
          mProvider: newAssetData.mMetaData.reduce(
            (accumulator, currentValue) =>
              currentValue.key === 'provider' ? currentValue.value : accumulator,
            '',
          ),
          mediaType: 'video/placeholder',
        });
      } else handleOpenInMimir(clipAsset);
    },
    [editor, element, initialData, newAssetData, handleOpenInMimir],
  );

  const onDeletePlaceholderConfirm = useCallback(() => {
    removePlaceholder(editor, initialData, placeholder, update);
    setShowDeletePlaceholder(false);
  }, [editor, initialData, placeholder, update]);

  const onDeletePlaceholder = useCallback(() => {
    setShowDeletePlaceholder(true);
  }, []);

  const getAssetIcon = (itemType) => {
    if (!itemType) return QuestionMark;
    if (hasPlaceholder) return HourglassSrc;

    return itemType === mediaTypes.IMAGE ? PhotoSrc : PlaySrc;
  };

  return (
    <div {...attributes} onBlur={onBlur}>
      <DragAndDrop element={element} hideHighlight>
        <SelectedElement element={element}>
          <PrimaryDropZone element={element}>
            <MediaDropZone
              element={element}
              canDropVideo={Boolean(video)}
              canDropImage={Boolean(image)}
              canDropGraphics={Boolean(graphics)}
            >
              {children}
              <RootWrapper contentEditable={false} $readOnly={readOnly}>
                <BoxWrapper $isSelected={isSelected} $readOnly={readOnly}>
                  <AutomationIcon type={type} />
                  <ContentWrapper>
                    {hasClipAsset && (
                      <ThumbnailWrapper
                        onClick={thumbSrc && !hasPlaceholder ? openMediaDialog : null}
                        aria-disabled={hasGraphicsAsset}
                        $showCursor={!!thumbSrc && !hasPlaceholder && !hasGraphicsAsset}
                      >
                        <Thumbnail
                          src={clipAsset?.thumbnailUrl ?? defaultImage}
                          alt="asset"
                          onError={onImageLoadingError}
                        />

                        <Icon alt="play" src={getAssetIcon(clipAsset?.itemType)} />
                      </ThumbnailWrapper>
                    )}
                    <Select initialData={initialData} element={element} />
                    <TitleWrapper>
                      <Title {...{ initialData, type, templates, template }} />
                    </TitleWrapper>
                    <PlaceholdersWrapper>
                      {showCreatePlaceholderOption && (
                        <>
                          {hasPlaceholder && (
                            <IconButton
                              size={24}
                              iconSize={22}
                              usage="text"
                              onClick={onDeletePlaceholder}
                              title="Delete placeholder"
                              disableEnhancedIconOpacity
                            >
                              <DeletePlaceholder />
                            </IconButton>
                          )}
                          {!hasPlaceholder && !clipAsset && !hasGraphicsAsset && (
                            <IconButton
                              size={24}
                              iconSize={22}
                              usage="text"
                              onClick={openPlaceholderDialog}
                              onMouseEnter={(e) => {
                                if (readOnly) e.stopPropagation();
                              }}
                              title="Create placeholder"
                              disableEnhancedIconOpacity
                            >
                              <AddPlaceholder />
                            </IconButton>
                          )}
                        </>
                      )}
                      {showEditGraphicsOption && hasGraphicsAsset && (
                        <IconButton
                          size={24}
                          iconSize={22}
                          usage="text"
                          onClick={handleEditGraphics}
                          title="Edit graphics"
                          disableEnhancedIconOpacity
                          style={{ pointerEvents: 'all' }}
                        >
                          <EditGraphics />
                        </IconButton>
                      )}
                      <IconButton
                        title="Show details"
                        size={24}
                        iconSize={22}
                        usage="text"
                        onClick={openDetailsDialog}
                        disableEnhancedIconOpacity
                      >
                        <InfoIcon />
                      </IconButton>
                      {showOpenInMimir && (
                        <IconButton
                          title="Open Asset"
                          size={24}
                          iconSize={22}
                          usage="text"
                          onClick={openInMimirAction}
                          disableEnhancedIconOpacity
                          style={{ pointerEvents: 'all' }}
                        >
                          <OpenIcon />
                        </IconButton>
                      )}
                    </PlaceholdersWrapper>
                  </ContentWrapper>
                </BoxWrapper>
              </RootWrapper>
            </MediaDropZone>
          </PrimaryDropZone>
        </SelectedElement>
      </DragAndDrop>

      {detailsDialogOpen && (
        <DetailsDialog
          open={detailsDialogOpen}
          onClose={closeDetailsDialog}
          {...{ initialData, type, element }}
        />
      )}

      <ConfirmDialog
        open={showDeletePlaceholder}
        confirmLabel="Delete"
        onClick={onDeletePlaceholderConfirm}
        onClose={() => {
          setShowDeletePlaceholder(false);
        }}
        usage="danger"
        title="Remove placeholder"
        message="Are you sure you want to remove the placeholder?"
      />

      {placeholderDialogOpen && (
        <PlaceholderDialog
          open={placeholderDialogOpen}
          onClose={closePlaceholderDialog}
          {...{ element }}
        />
      )}

      {mediaDialogOpen && (
        <MediaDialog asset={clipAsset} open={mediaDialogOpen} onClose={closeMediaDialog} />
      )}
    </div>
  );
};

PrimaryAutomation.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

PrimaryAutomation.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.CAMERA,
    children: [],
  },
};

export default memo(PrimaryAutomation);
