import styled from '@emotion/styled';

import { Button } from 'components/buttons';
import Text from 'components/text/Text';
import { VStack } from 'layouts/box/Box';
import { useShowError } from 'store';

const Wrapper = styled(VStack)`
  position: absolute;
  inset: 0;
  backdrop-filter: blur(40px);
  z-index: 1500;
`;

const StyledText = styled(Text)`
  text-align: center;
  width: 100%;
`;
export default function ReloadOnAuthError() {
  const [show] = useShowError();

  return (
    show && (
      <Wrapper gap="16px">
        <VStack width="100%">
          <StyledText variant="h4">You have been</StyledText>
          <StyledText variant="h4">logged out of Dina</StyledText>
        </VStack>

        <StyledText variant="body2">
          You have been logged out due to inactivity.
          <br /> Please log in again to continue using Dina.
        </StyledText>

        <Button usage="significant" width={350} onClick={() => window.location.reload()}>
          Log in
        </Button>
      </Wrapper>
    )
  );
}
