import React, { useState, useCallback, useMemo, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import { useSlate, useReadOnly, useSelected } from 'slate-react';
import { elementTypes, outTimingTypes } from 'components/editor/constants/types';
import SelectedElement from 'components/editor/components/selectedElement';
import PlaceholderDialog from 'components/editor/components/placeholderDialog';
import removePlaceholder from 'components/editor/components/placeholderDialog/utils/removePlaceholder';
import useSettingsValue from 'hooks/useSettingsValue';
import { useEditorContext } from 'components/editor/EditorContext';
import useGetAsset from 'hooks/useGetAsset';
import { onImageLoadingError } from 'utils/image/imageUtils';
import { refreshSelection } from 'components/editor/utils';
import iconComponents from './constants/iconComponents';
import Box from './components/box';
import InTimingSelect from './components/inTimingSelect';
import OutTimingSelect from './components/outTimingSelect';
import Dialog from './components/dialog';
import SecondaryDropZone from './components/secondaryDropZone';
import GraphicsDropZone from './components/graphicsDropZone';
import ClipDropZone from './components/clipDropZone';
import CharacterGraphics from './components/characterGrapgics';
import TitleBox from './components/title';
import MediaDialog from '../primaryAutomation/components/mediaDialog';
import defaultImage from 'assets/images/default/defaultThumbnail.png';
import getInitialData from './utils/getInitialData';
import {
  BoxWrapper,
  ContentWrapper,
  SelectWrapper,
  VerticalBorder,
  TitleBoxWrapper,
  PlaceholdersWrapper,
  ThumbnailWrapper,
  PlayIcon,
  PlaceholderIcon,
  Thumbnail,
  OpenIcon,
} from './styled';
import DragAndDrop from '../dragAndDrop';
import { mediaTypes } from 'utils/rundownItemTypes';
import HourglassSrc from 'assets/icons/systemicons/hourglass.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/systemicons/appMenu/info_off.svg';
import { ReactComponent as AddPlaceholder } from 'assets/icons/systemicons/placeholder_add.svg';
import { ReactComponent as DeletePlaceholder } from 'assets/icons/systemicons/placeholder_delete.svg';

import PlaySrc from 'assets/icons/systemicons/Play_WithCircleBackground_small.svg';
import PhotoSrc from 'assets/icons/systemicons/photo.svg';
import { isMimirAssetItem, openPreviewInProvider } from 'utils/openAssetInMimir';
import syncAssetWithSlate from '../primaryAutomation/utils/syncAssetWithSlate';
import { IconButton } from 'components/buttons';
import { ConfirmDialog } from 'components/dialogs/CommonDialogs';
import useOpenAssetInMimir from 'hooks/useOpenAssetInMimir';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';
import useOpenAssetInPilotEdge from 'hooks/useOpenAssetInPilotEdge';

const { MANUAL_OUT } = outTimingTypes;
const { OVERLAY_GRAPHICS } = elementTypes;

const getAssetIcon = (itemType) => (itemType === mediaTypes.IMAGE ? PhotoSrc : PlaySrc);

const SecondaryAutomation = ({ attributes, children, element }) => {
  const editor = useSlate();
  const readOnly = useReadOnly();
  const { update } = useEditorContext();
  const openAssetInPilotEdge = useOpenAssetInPilotEdge();
  const { data, type } = element;
  const isSelected = useSelected(element);
  const { mosobj, protocol } = data;
  const [openDetails, setOpenDetails] = useState(false);
  const [showDeletePlaceholder, setShowDeletePlaceholder] = useState(false);
  const [placeholderDialogOpen, setPlaceholderDialogOpen] = useState(false);
  const initialData = useMemo(() => getInitialData(data), [data]);
  const { inTiming, outTiming, templateVariant, assets } = initialData;
  const Icon = iconComponents[type];
  const isGraphic = type === OVERLAY_GRAPHICS;
  const [getSettingsValue] = useSettingsValue();
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

  const openMediaAsset = useOpenAssetInMimir();

  const hideInOutTimingInGraphics =
    getSettingsValue('rundown.hideInOutTimingInGraphics', 'app.hideInOutTimingInGraphics') ===
    'true';
  const showInOutTiming = isGraphic ? !hideInOutTimingInGraphics : true;

  const { templates, resources } = useGetAutomationTemplates();
  const clipAsset = useMemo(
    () =>
      assets.find(({ itemType }) =>
        new Set([mediaTypes.IMAGE, mediaTypes.VIDEO, mediaTypes.AUDIO]).has(itemType),
      ),
    [assets],
  );

  const { mId, mRefId } = clipAsset ?? {};

  const [assetData] = useGetAsset(mId, mRefId, !mId || !mRefId);

  const thumbSrc = assetData?.mThumbnailUrl;
  const showOpenAsset = isMimirAssetItem(element);
  const hasClipAsset = Boolean(clipAsset);
  const hasPlaceholder = useMemo(
    () => clipAsset?.mediaType?.includes('placeholder') || false,
    [clipAsset],
  );

  useEffect(() => {
    if (assetData) syncAssetWithSlate(editor, element, initialData, assetData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetData]);

  const template = useMemo(
    () =>
      templates && templates.find((item) => item.type === type && item.variant === templateVariant),
    [templateVariant, templates, type],
  );

  const { graphics = false, video = false, image = false } = template?.requires || {};

  const onBlur = useCallback(() => refreshSelection(editor, element), [editor, element]);

  const handleOpenDetails = useCallback(() => setOpenDetails(true), []);

  const handleCloseDetails = useCallback(() => setOpenDetails(false), []);

  const openPlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(true), []);

  const closePlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(false), []);

  const handleRemovePlaceholder = useCallback(
    () => {
      if (hasPlaceholder && clipAsset) removePlaceholder(editor, initialData, clipAsset, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialData, clipAsset, hasPlaceholder],
  );

  const onDeletePlaceholderConfirm = useCallback(() => {
    handleRemovePlaceholder();
    setShowDeletePlaceholder(false);
  }, []);

  const onDeletePlaceholder = useCallback(() => {
    setShowDeletePlaceholder(true);
  }, []);

  const onOpenMimirPanel = useCallback(
    (e) => {
      if (readOnly) preventDefaultAndPropagation(e);
      openMediaAsset(element);
    },
    [element, readOnly],
  );

  const onOpenPilotEdgePanel = useCallback(
    (e) => {
      if (readOnly) preventDefaultAndPropagation(e);
      openAssetInPilotEdge(mosobj, element);
    },
    [readOnly, mosobj],
  );

  const closeMediaDialog = useCallback(() => setMediaDialogOpen(false), []);

  const openMediaDialog = useCallback(
    (e) => {
      if (openPreviewInProvider(clipAsset.mProvider)) {
        return handleOpenInMimir(clipAsset);
      }
      e.stopPropagation();
      setMediaDialogOpen(true);
    },
    [clipAsset],
  );

  return (
    <div {...attributes} onBlur={onBlur}>
      <DragAndDrop element={element} hideHighlight>
        <SelectedElement element={element}>
          <SecondaryDropZone element={element}>
            <GraphicsDropZone element={element} canDropGraphics={graphics}>
              <ClipDropZone element={element} canDropVideo={video} canDropImage={image}>
                {children}
                <BoxWrapper
                  contentEditable={false}
                  readOnly={readOnly}
                  hasPlaceholder={hasClipAsset}
                >
                  <Box
                    inTimingType={inTiming}
                    outTimingType={isGraphic ? outTiming : MANUAL_OUT}
                    isGraphic={isGraphic}
                    isSelected={isSelected}
                  >
                    <ContentWrapper>
                      <Icon className="automationIcon skipOverride" />
                      {hasClipAsset && (
                        <>
                          {hasPlaceholder && (
                            <ThumbnailWrapper
                              onClick={thumbSrc ? openMediaDialog : null}
                              onMouseEnter={(e) => {
                                if (readOnly) e.stopPropagation();
                              }}
                            >
                              {thumbSrc ? (
                                <>
                                  <Thumbnail
                                    alt="placeholder"
                                    src={thumbSrc}
                                    onError={onImageLoadingError}
                                  />
                                  <PlayIcon alt="play" src={getAssetIcon(clipAsset.itemType)} />
                                </>
                              ) : (
                                <>
                                  <Thumbnail
                                    alt="placeholder"
                                    src={defaultImage}
                                    onError={onImageLoadingError}
                                  />
                                  <PlaceholderIcon alt="placeholder" src={HourglassSrc} />
                                </>
                              )}
                            </ThumbnailWrapper>
                          )}
                          {!hasPlaceholder && (
                            <ThumbnailWrapper onClick={openMediaDialog}>
                              <Thumbnail
                                src={assetData?.mThumbnailUrl || defaultImage}
                                alt="asset"
                                onError={onImageLoadingError}
                              />
                              <PlayIcon alt="play" src={getAssetIcon(clipAsset.itemType)} />
                            </ThumbnailWrapper>
                          )}
                        </>
                      )}
                      {showInOutTiming && (
                        <>
                          <SelectWrapper>
                            <InTimingSelect element={element} />
                            {isGraphic && <OutTimingSelect element={element} />}
                          </SelectWrapper>
                          <VerticalBorder />
                        </>
                      )}
                      <TitleBoxWrapper $fullWidth={!showInOutTiming}>
                        {protocol === 'starcg' ? (
                          <CharacterGraphics
                            data={data}
                            resources={resources}
                            isGraphic={isGraphic}
                            initialData={initialData}
                          />
                        ) : (
                          <TitleBox
                            initialData={initialData}
                            type={type}
                            templates={templates}
                            resources={resources}
                            isGraphic={isGraphic}
                          />
                        )}
                      </TitleBoxWrapper>
                      <PlaceholdersWrapper>
                        {hasPlaceholder && (
                          <IconButton
                            title="Delete placeholder"
                            size={24}
                            iconSize={22}
                            usage="text"
                            onClick={onDeletePlaceholder}
                            disableEnhancedIconOpacity
                          >
                            <DeletePlaceholder />
                          </IconButton>
                        )}
                        {!hasPlaceholder && !clipAsset && !isGraphic && (
                          <IconButton
                            title="Create placeholder"
                            size={24}
                            iconSize={22}
                            usage="text"
                            onClick={openPlaceholderDialog}
                            disableEnhancedIconOpacity
                          >
                            <AddPlaceholder />
                          </IconButton>
                        )}
                        {!isGraphic && (
                          <IconButton
                            title="Show details"
                            size={24}
                            iconSize={22}
                            usage="text"
                            onClick={handleOpenDetails}
                            disableEnhancedIconOpacity
                          >
                            <InfoIcon />
                          </IconButton>
                        )}
                        {showOpenAsset && (
                          <IconButton
                            title="Open Asset"
                            size={24}
                            iconSize={22}
                            usage="text"
                            onClick={onOpenMimirPanel}
                            disableEnhancedIconOpacity
                            style={{ pointerEvents: 'all' }}
                          >
                            <OpenIcon />
                          </IconButton>
                        )}

                        {isGraphic && mosobj && (
                          <IconButton
                            title="Edit graphics"
                            size={24}
                            iconSize={22}
                            usage="text"
                            onClick={onOpenPilotEdgePanel}
                            disableEnhancedIconOpacity
                            style={{ pointerEvents: 'all' }}
                          >
                            <OpenIcon />
                          </IconButton>
                        )}
                      </PlaceholdersWrapper>
                    </ContentWrapper>
                  </Box>
                </BoxWrapper>
              </ClipDropZone>
            </GraphicsDropZone>
          </SecondaryDropZone>
        </SelectedElement>
      </DragAndDrop>

      <ConfirmDialog
        open={showDeletePlaceholder}
        confirmLabel="Delete"
        onClick={onDeletePlaceholderConfirm}
        onClose={() => {
          setShowDeletePlaceholder(false);
        }}
        usage="danger"
        title="Remove placeholder"
        message="Are you sure you want to remove the placeholder?"
      />

      {!isGraphic && openDetails && (
        <Dialog
          open={openDetails}
          onClose={handleCloseDetails}
          initialData={initialData}
          type={type}
          element={element}
        />
      )}

      {placeholderDialogOpen && (
        <PlaceholderDialog
          open={placeholderDialogOpen}
          onClose={closePlaceholderDialog}
          element={element}
          itemType={type === 'audio' ? 'audio' : 'video'}
        />
      )}
      {mediaDialogOpen && (
        <MediaDialog asset={clipAsset} open={mediaDialogOpen} onClose={closeMediaDialog} />
      )}
    </div>
  );
};

SecondaryAutomation.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

SecondaryAutomation.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.ACCESSORY,
    children: [],
  },
};

export default memo(SecondaryAutomation);
