import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import useApolloSubscription from 'hooks/useApolloSubscription';

import { updateBoardWithSubscriptionData } from './cacheUpdate';
import { BOARD_UPDATED_SUBSCRIPTION, GET_BOARD } from './graphql';
import { QueryType, SubscriptionType } from './types';
import { parseBoard } from './utils';

/** Fetch board and format board data */
export const useGetBoard = (mId: string) => {
  const { data, loading, error } = useQuery<QueryType>(GET_BOARD, {
    variables: { input: { mId } },
    fetchPolicy: 'cache-first',
  });

  // Subscribe
  const [subscribe, unSubscribe] = useApolloSubscription(BOARD_UPDATED_SUBSCRIPTION, {
    variables: {
      boardId: mId,
    },
    skip: !mId,
    onSubscriptionData: async ({ client, subscriptionData }: SubscriptionType) => {
      if (!subscriptionData?.data?.updatedBoard) return;
      updateBoardWithSubscriptionData(client, subscriptionData.data.updatedBoard);
    },
    source: 'useGetBoardSubscription',
  });

  // Subscribe on rundownId change
  useEffect(() => {
    subscribe();
    return () => {
      unSubscribe();
    };
  }, [mId]);

  const parsedBoard = useMemo(() => {
    if (!data?.getBoard) return null;
    return parseBoard(data?.getBoard);
  }, [data]);

  return { board: parsedBoard, loading, error };
};
