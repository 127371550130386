import { memo, useCallback, useMemo } from 'react';

import { SearchParameters } from 'api/search/useSearch';
import FilterComponent from 'features/searchDeck/components/filters';
import SearchPluginDeck from 'features/searchPlugin/SearchPluginDeck';
import WidgetWrapper, { FilterComponentType } from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';
import { useUpdateWidgetFilters } from 'screens/space/store/widgets';
import { FilterValueType } from 'types/widget';

import { SearchWidgetProps } from './types';
import { getPropFilters } from './utils';

export const isStoryFilter = (
  obj: FilterValueType | undefined,
): obj is { searchParams: SearchParameters } => {
  if (!obj) return false;
  const maybe = obj as Partial<{ searchParams: SearchParameters }>;
  return maybe.searchParams !== undefined;
};

export const getMetadata = (obj: SearchParameters) => {
  if (!obj.mdfId) return undefined;
  return {
    mdfId: obj.mdfId,
    metadata: obj.metadataFilter ?? {},
  };
};

function SearchPluginWidget({
  layout,
  filters,
  mId,
  mRefId,
  selectedDate,
  title,
  writeAccess,
  config,
  federatedSearchString,
}: Readonly<SearchWidgetProps>) {
  const updateWidgetFilters = useUpdateWidgetFilters();

  const handleUpdateFilters = useCallback((newFilters: FilterValueType) => {
    updateWidgetFilters({ id: mId, filters: newFilters });
  }, []);

  const memoizedFilters = useMemo(() => {
    const propFilters = getPropFilters({ filters });
    return propFilters;
  }, [filters]);

  return (
    <WidgetWrapper
      layout={layout}
      mId={mId}
      mRefId={mRefId}
      title={title}
      writeAccess={writeAccess}
      filterComponent={FilterComponent as FilterComponentType}
      filters={memoizedFilters}
      activeFilters={false}
      type={WIDGETS.SEARCH_PLUGIN}
    >
      <SearchPluginDeck
        config={config}
        filters={memoizedFilters}
        selectedDate={selectedDate}
        deckId={mId}
        onSearchStringChange={(val: string) => handleUpdateFilters({ searchString: val })}
        federatedSearchString={federatedSearchString}
      />
    </WidgetWrapper>
  );
}

export default memo(SearchPluginWidget);
