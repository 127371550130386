import styled from '@emotion/styled';

import { VStack } from 'layouts/box/Box';

export const LoadingWrapper = styled('div')`
  width: 90vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PrintViewWrapper = styled(VStack)`
  height: 90vh;
  justify-content: center;
  padding: 1rem 0 0;
`;

export const ErrorWrapper = styled(VStack)`
  height: 100%;
  max-height: 40vh;
  justify-content: center;
  padding: 1rem;
  overflow: auto;
`;

export const RundownPDFViewWrapper = styled('div')`
  display: flex;
  flex: 1;
`;

export const RundownPrintModeWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
`;

export const RadioButtonWithLabel = styled('div')`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding-left: 0.5rem;
  cursor: pointer;
`;
