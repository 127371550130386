import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '0 1px',
  },

  listItem: {
    flex: 1,
    maxHeight: '48px',
    minHeight: '40px',
    '&:hover:not($disabled)': {
      backgroundColor: theme.palette.dina.outlineButtonOnHover,
    },
    '&:hover:not($disabled) $icon': {
      '& path': {
        'fill-opacity': 1,
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    marginRight: '12px',
  },
  listItemText: {
    ...theme.typography.dina.listItemLabel,
    whiteSpace: 'pre-line',
  },
  infoText: {
    ...theme.typography.dina.caption,
    color: theme.palette.dina.mediumEmphasis,
    whiteSpace: 'pre-line',
  },

  divider: {
    width: '100%',
    margin: '4px 0px',
    background: theme.palette.dina.dividerLight,
  },
  iconDivider: {
    width: 'calc(100% - 52px)',
    margin: '4px 0px 4px 52px',
    background: theme.palette.dina.dividerLight,
  },
}));

export default useStyles;
