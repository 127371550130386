import { useMemo } from 'react';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import Dialog from 'components/dialog';
import LoadingIndicator from 'components/loadingIndicator';
import PdfViewer from 'components/pdfViewer/PdfViewer';
import { OrderWithLabel } from 'features/sidepanel/ComponentUtils';
import { Box } from 'layouts/box/Box';
import { useGetOrdersForEntities } from 'screens/space/api/useGetOrdersAndForms';
import { GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

import TasksPrintDoc from './docs/TasksPrintDoc';
import useGetOrdersWithMdf from './hooks/useGetOrdersWithMdf';

import { ErrorWrapper, LoadingWrapper, PrintViewWrapper } from './styled';

interface Props {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  tasks: OrderWithLabel[];
}

const View = ({ tasks }: Pick<Props, 'tasks'>) => {
  const { mdfs, mdfsSeparated, loading, error } = useGetMdfs({ all: true });

  const mIds = useMemo(() => tasks.map((task) => task.mId), [tasks]);

  const {
    orders,
    loading: ordersLoading,
    error: ordersError,
  } = useGetOrdersForEntities(mIds, GetOrderEnum.Resource, TaskStatusEnum.all);

  const { ordersWithMdf: tasksWithMdf } = useGetOrdersWithMdf(tasks, mdfs, mdfsSeparated);
  const { ordersWithMdf: subTasksWithMdf } = useGetOrdersWithMdf(orders, mdfs, mdfsSeparated);

  if (loading || ordersLoading)
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );

  if (error || ordersError) {
    const errors = {
      error: error ?? null,
      ordersError: ordersError ?? null,
    };
    return (
      <ErrorWrapper>
        <Box>Encountered following error while printing</Box>
        <Box>
          <pre>{JSON.stringify(errors, null, 2)}</pre>
        </Box>
      </ErrorWrapper>
    );
  }
  return (
    <PrintViewWrapper>
      <PdfViewer>
        <TasksPrintDoc
          tasks={tasksWithMdf}
          mdfsSeparated={mdfsSeparated}
          subTasks={subTasksWithMdf}
        />
      </PdfViewer>
    </PrintViewWrapper>
  );
};

const TasksPrint = ({ isDialogOpen, onCloseDialog, tasks }: Props) => {
  return (
    <Dialog container={undefined} open={isDialogOpen} onClose={onCloseDialog}>
      <View tasks={tasks} />
    </Dialog>
  );
};

export default TasksPrint;
