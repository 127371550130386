import { useContext } from 'react';
import UserCtx from 'contexts/UserContext';
import useTabs from 'hooks/useTabs';
import updateMessageInConversation from 'operations/utils/cache/notification/updateMessageInConversation';

const convertCrudAction = (crudAction) => {
  if (!crudAction) return 'INSERT';
  if (crudAction === 'DELETE') return 'REMOVE';
  return crudAction;
};

const useContentTabMessage = () => {
  const { updateTabNotifications } = useTabs();
  const { mId: userId } = useContext(UserCtx);

  const updateContentTabNotificationCount = (item) => {
    const { mId: id, message: { crudAction } = {} } = item;
    // Not Implemented: crudAction === 'REMOVE' should decrease the notification count
    if (crudAction === 'INSERT') updateTabNotifications({ id });
  };

  const processContentTabMessageData = ({ client, subscriptionData }) => {
    const item = subscriptionData?.data?.updateContentChat;
    if (!item || item.mUpdatedById === userId) return;
    const { mContent, crudAction } = item;
    item.message = { mContent, crudAction: convertCrudAction(crudAction) };
    updateContentTabNotificationCount(item);
    updateMessageInConversation(client, item);
  };

  return [processContentTabMessageData];
};

export default useContentTabMessage;
