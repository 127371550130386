import { Document, Page, Text, View } from '@react-pdf/renderer';

import { BlockWithLabelAndMdf } from 'api/mdfBlocks/types';
import { OrderWithMdf } from 'types/forms/forms';
import { Mdf } from 'types/graphqlTypes';

import CommonDocFooter from '../components/CommonDocFooter';
import StoryIcon from '../components/StoryIcon';

import { Block } from './utils';

import { styles } from './styles';

const PlanningDocHeader = () => {
  return (
    <View style={styles.header} fixed>
      <View style={[styles.headerContent, { alignItems: 'center' }]}>
        <StoryIcon />
        <Text style={styles.title}>Planning</Text>
      </View>
      <View style={[styles.horizontalLine, { paddingTop: '8' }]} />
    </View>
  );
};

const Contents = ({ blocks, mdfsSeparated, orders, subOrders }: Props) => {
  return (
    <View style={[styles.column, styles.content, { gap: '16px', marginBottom: 16 }]}>
      {blocks.map((block) => {
        const { mTitle, mRefId, mdf, metadata, color } = block;

        return (
          <Block
            key={mRefId}
            metadata={metadata}
            subMdfs={mdfsSeparated.subTypes}
            fields={mdf?.fields}
            layoutSettings={mdf?.views.default}
            permissions={mdf?.permissions}
            blockTitle={mTitle}
            orders={orders.filter((order) => order.mResourceId === mRefId)}
            subOrders={subOrders}
            color={color}
          />
        );
      })}
    </View>
  );
};

interface Props {
  blocks: BlockWithLabelAndMdf[];
  orders: OrderWithMdf[];
  subOrders: OrderWithMdf[];
  mdfsSeparated: {
    defaults: Mdf[];
    custom: Mdf[];
    subTypes: Mdf[];
    instances: Mdf[];
  };
}

const PlanningPrintDoc = (props: Props) => (
  <Document>
    <Page style={styles.body}>
      <PlanningDocHeader />
      <Contents {...props} />
      <CommonDocFooter />
    </Page>
  </Document>
);

export default PlanningPrintDoc;
