import { memo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { ListItem as MaterialListItem } from '@material-ui/core';

import Divider from 'components/divider/divider-view';
import transientOptions from 'theme/helpers';

const ListItemComponent = memo(MaterialListItem);

interface ListItemTextType {
  $whiteSpace?: string;
}

export const ListItemText = styled('span', transientOptions)<ListItemTextType>`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${(props) => props.$whiteSpace};
`;

export const ListItemTextLabel = styled('span')`
  padding-left: 4px;
  ${({ theme }) => css`
    color: ${theme.palette.dina.statusWarning};
  `};
`;

interface ListItemPropType {
  borderRadius: number;
  maxHeight: number;
}
export const ListItem = styled(ListItemComponent, {
  shouldForwardProp: (prop) => prop !== 'borderRadius' && prop !== 'maxHeight',
})`
  &.MuiListItem-root {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-inline: 14px;
    border-radius: ${(props: ListItemPropType) => `${props.borderRadius}px`};
    max-height: ${(props: ListItemPropType) => `${props.maxHeight}px`};
    :hover {
      background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    }
    &.Mui-selected {
      color: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
      background-color: ${({ theme }) => theme.palette.dina.onSelected};
      :hover {
        background-color: ${({ theme }) => theme.palette.dina.onSelected};
      }
    }
    &.Mui-selected ${ListItemText} {
      color: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
    }
  }
`;

export const HDivider = styled(Divider)`
  width: 100%;
  height: 1px;
  margin-block: 4px;
`;
