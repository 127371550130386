import { useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { flatten } from 'lodash';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';

import Divider from 'components/divider';
import Scrollbar from 'components/scrollbar';
import Text from 'components/text/Text';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { User } from 'types/members';
import { getParseMetadata } from 'utils/getParsedMetadata';

import CreateContact from './CreateContact';
import CreateDepartment from './CreateDepartment';
import CreateTeam from './CreateTeam';
import MemberListItem from './MemberListItem';

type Title = 'Users' | 'Teams' | 'Departments' | 'Contacts';

const createDialogs: Record<Title, React.ReactElement | undefined> = {
  Users: undefined,
  Teams: <CreateTeam />,
  Departments: <CreateDepartment />,
  Contacts: <CreateContact />,
};

interface MemberListProps {
  members: User[];
  title: Title;
  total?: number;
  loadMore?: () => Promise<void>;
  search: React.ReactElement;
}

interface Header {
  initial: string;
  count: number;
}

function Row({ index, style, data }: ListChildComponentProps<(Header | User)[]>) {
  const value = data[index];
  return 'initial' in value ? (
    <HStack
      width="100%"
      height="24px"
      padding="0 14px 0 8px"
      justifyContent="space-between"
      style={{ ...style }}
    >
      <Text variant="label" style={{ userSelect: 'none' }}>
        {value.initial}
      </Text>
      <Text variant="label" style={{ userSelect: 'none' }}>
        {value.count}
      </Text>
    </HStack>
  ) : (
    <MemberListItem member={value} style={style} />
  );
}

function MemberList({
  members,
  title,
  total,
  loadMore = () => new Promise((resolve) => resolve()),
  search,
}: Readonly<MemberListProps>) {
  const listRef = useRef<VariableSizeList>(null);
  const groupedMembers = groupBy(
    members
      .filter((member) => !getParseMetadata(member?.metadata)?.notListed)
      .sort((a, b) => ((a?.mTitle ?? '').toLowerCase() > (b?.mTitle ?? '').toLowerCase() ? 1 : -1)),
    ({ mTitle }) => mTitle?.charAt(0).toUpperCase(),
  );

  const flattenMembers = flatten(
    keys(groupedMembers).map((key) => [
      { initial: key, count: groupedMembers[key].length },
      ...groupedMembers[key],
    ]),
  );

  useEffect(() => {
    if (flattenMembers.length && listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [flattenMembers?.length]);

  return (
    <VStack width="100%" height="100%">
      <HStack width="100%" height="40px" padding="0 8px" justifyContent="flex-start">
        <Text variant="h7" color="highEmphasis" style={{ flex: 1 }}>
          {title}
          {total ? (
            <Text
              variant="listItemLabel"
              style={{ marginLeft: 8 }}
            >{`(${members.length} of ${total})`}</Text>
          ) : null}
        </Text>
        {createDialogs[title]}
      </HStack>
      <Divider orientation="horizontal" width="100%" />
      {search}
      <Box flex="1" width="100%">
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              itemCount={total ?? 0}
              loadMoreItems={() => loadMore()}
              isItemLoaded={(index) => Boolean(flattenMembers[index])}
            >
              {({ onItemsRendered }) => (
                <VariableSizeList
                  onItemsRendered={onItemsRendered}
                  ref={listRef}
                  layout="vertical"
                  height={height}
                  itemCount={flattenMembers.length}
                  itemSize={(index) => ('initial' in flattenMembers[index] ? 24 : 54)}
                  width={width}
                  itemData={flattenMembers}
                  overscanCount={5}
                  outerElementType={Scrollbar}
                  style={{ overflow: 'hidden' }}
                >
                  {Row}
                </VariableSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </Box>
    </VStack>
  );
}

export default MemberList;
