import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';

import UserContext from 'contexts/UserContext';
import UPDATE_NOTE from 'operations/mutations/updateNote';
import { EditorValue, Note } from 'types';
import { UpdateNoteInput } from 'types/graphqlTypes';

import destructureEditorValue from '../utils/destructureEditorValue';

type UpdateNoteInputType = {
  input: UpdateNoteInput;
};

type UpdateNoteReturnType = {
  updateNote: Note;
};

const useUpdateNote = () => {
  const user = useContext(UserContext);
  const { mId: userId } = user;
  const [updateMember] = useMutation<UpdateNoteReturnType, UpdateNoteInputType>(UPDATE_NOTE);

  const updateNote = useCallback(
    async (
      note: Note,
      updatedProperties: Note['mProperties'] = note.mProperties,
      editorValue: EditorValue | null = null,
    ) => {
      const { mId, mRefId, locked } = note;

      let mTitle: UpdateNoteInput['mTitle'] = note.mTitle;
      let mDescription: UpdateNoteInput['mDescription'] = note.mDescription;

      if (editorValue && locked && locked === userId) {
        const { mTitle: newMTitle, mDescription: newMDescription } =
          destructureEditorValue(editorValue);
        mTitle = newMTitle ?? mTitle;
        mDescription = newMDescription ?? mDescription;
      }

      const hasUpdatedProperties = Object.keys(updatedProperties).length > 0;

      const input: UpdateNoteInput = {
        mId,
        mRefId,
        mTitle,
        mDescription,
        mProperties: {
          __typename: 'NoteProperties',
          pinned: hasUpdatedProperties
            ? updatedProperties?.pinned ?? false
            : note?.mProperties?.pinned ?? false,
          labelId: hasUpdatedProperties
            ? updatedProperties?.labelId ?? null
            : note?.mProperties?.labelId ?? null,
        },
      };

      await updateMember({
        variables: {
          input,
        },
      });
    },
    [updateMember, userId],
  );

  return { updateNote };
};

export default useUpdateNote;
