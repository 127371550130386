import { isDateRange } from 'api/search/useSearch';
import Text, { TextProps } from 'components/text/Text';
import Tooltip from 'components/tooltip/Tooltip';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { DateRange } from './DatePicker';

import { DateLabel } from './styled';

interface DateDefaultProps extends TextProps {
  date?: string | DateRange;
  onClick: (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  hint?: string;
}

const generateUserVisibleDate = (val?: string | DateRange): string => {
  if (typeof val === 'string') return val;
  if (val && isDateRange(val)) {
    return `${isoToLocaleShort(val.startDate)} - ${isoToLocaleShort(val.endDate ?? val.startDate)}`;
  }
  return 'Select a date';
};

function DateDefault({ date, onClick, variant, color, hint }: Readonly<DateDefaultProps>) {
  const label = generateUserVisibleDate(date);
  return (
    <Tooltip title={hint ?? 'Edit default date'}>
      <DateLabel onClick={onClick}>
        <Text
          variant={variant ?? 'listItemLabel'}
          color={color ?? (date ? 'highEmphasis' : 'mediumEmphasis')}
        >
          {label}
        </Text>
      </DateLabel>
    </Tooltip>
  );
}

export default DateDefault;
