import { ColumnDef } from '@tanstack/react-table';

/** order columns by first default column, mdf columns, and then the rest of the default columns */
export function arrangeColumns<T>(
  defaultDefinitions: ColumnDef<T>[],
  mdfDefinitions: ColumnDef<T>[],
) {
  const defaultColumns = [...defaultDefinitions];
  const titleColumn = defaultColumns.shift();
  return [...(titleColumn ? [titleColumn] : []), ...mdfDefinitions, ...defaultColumns];
}
