import styled from '@emotion/styled';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from 'components/checkbox';
import ColorInput from 'components/input/ColorInput';
import Input from 'components/input/Input';
import Text from 'components/text/Text';
import { HStack } from 'layouts/box/Box';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

import { ChangedEditorCommandType, useChangedEditorCommands } from '../../../../../atomsTs';

import ConfigDropdown, { ConfigType } from './ConfigDropdown';

const Root = styled('div')`
  padding-block: 8px;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: 0;
  .MuiCheckbox-root {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
  }
`;

interface CommandPropertiesProps {
  readonly commandProperties: EditorCommandConfigType;
}

function EditCommandProperties({ commandProperties }: CommandPropertiesProps) {
  const [, setChangedCommands] = useChangedEditorCommands();

  const onChange = (key: keyof ChangedEditorCommandType, value: string | boolean) =>
    setChangedCommands((prev) => {
      const matchedProperty = prev.find((command) => command.mRefId === commandProperties.mRefId);

      if (!matchedProperty) return [...prev, { mRefId: commandProperties.mRefId, [key]: value }];

      return prev.map((command) =>
        command.mRefId === commandProperties.mRefId ? { ...command, [key]: value } : command,
      );
    });

  const updateConfig = (newConfig: ConfigType | null) => {
    if (newConfig?.id) onChange('mSecId', newConfig.id);
    if (newConfig?.type) onChange('mResourceType', newConfig.type);
  };

  const { mTitle, slashCommand, mActive, mSecId, mColor, mResourceType } = commandProperties;

  return (
    <Root>
      <ConfigDropdown disableClearable disabled value={mSecId} onSelectConfig={updateConfig} />
      <Text variant="overline">Title</Text>
      <Input initialValue={mTitle} updateValue={(value) => onChange('mTitle', value)} />
      <Text variant="overline">Slash command</Text>
      <Input initialValue={slashCommand} updateValue={(value) => onChange('slashCommand', value)} />
      <FormControlLabel
        key="active"
        label="Active"
        control={<Checkbox selected={mActive} onClick={() => onChange('mActive', !mActive)} />}
      />
      {mResourceType !== MemberTypeEnum.OrderForm && (
        <HStack height="24px" justifyContent="start" gap="10px">
          <Text variant="overline">Block color:</Text>
          <ColorInput color={mColor} onChange={(val) => onChange('mColor', val)} />
        </HStack>
      )}
    </Root>
  );
}

export default EditCommandProperties;
