import { useRef } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { EditorValue, Note } from 'types';
import { ExportContentInput, MemberType } from 'types/graphqlTypes';

export const NoteScope = createScope<string | undefined>(undefined);
export type NoteScopeObject =
  | { view: 'widget'; viewRef: string }
  | {
      view: 'story';
      paneIndex: number;
    };

const notesMolecule = molecule((_getMol, getScope) => {
  const scopeString = getScope(NoteScope);

  const canUpdateNoteAtom = atom(false);

  const selectedNoteAtom = atom<MemberType | null>(null);

  const toViewHistoryAtom = atom<Note | null>(null);
  const toDeleteNoteAtom = atom<Note | null>(null);
  const toPrintNoteAtom = atom<Note | null>(null);
  const printConfig = atom<ExportContentInput | null>(null);

  const editorValueRef = useRef<EditorValue | null>(null);
  const restoreVersionFnRef = useRef<((content: EditorValue) => Promise<void>) | null>(null);

  const scopeView = atom(() => {
    if (!scopeString) return;
    const scopeObject = JSON.parse(scopeString) as NoteScopeObject;

    return scopeObject.view;
  });

  const scopeViewRef = atom(() => {
    if (!scopeString) return;
    const scopeObject = JSON.parse(scopeString) as NoteScopeObject;

    return scopeObject.view === 'story' ? scopeObject.paneIndex : scopeObject.viewRef;
  });

  return {
    useSelectedNote: () => useAtom(selectedNoteAtom),
    useCanUpdateNote: () => useAtom(canUpdateNoteAtom),
    useToViewHistoryNote: () => useAtom(toViewHistoryAtom),
    useToDeleteNote: () => useAtom(toDeleteNoteAtom),
    useToPrintNote: () => useAtom(toPrintNoteAtom),
    usePrintConfig: () => useAtom(printConfig),
    useScopeViewValue: () => useAtomValue(scopeView),
    useScopeViewRefValue: () => useAtomValue(scopeViewRef),
    editorValueRef,
    restoreVersionFnRef,
  };
});

export const useNotesMolecule = () => useMolecule(notesMolecule);
