import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import LoadingIndicator from 'components/loadingIndicator';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useTabs from 'hooks/useTabs';
import ProtectedProviders from 'providers/protected';
import MainLayout from 'screens/main';
import StoryV2 from 'screens/storyV2/StoryV2';
import { type Content, type Icon, isContentType, useStoryView } from 'store';

const Home = lazy(() => import('screens/home'));
const Feeds = lazy(() => import('screens/feeds'));
const Maps = lazy(() => import('screens/maps'));
const Rundown = lazy(() => import('screens/rundown'));
const StoryV1 = lazy(() => import('screens/story'));
const StoryHub = lazy(() => import('screens/storyHub'));
const Plans = lazy(() => import('screens/planning'));
const Space = lazy(() => import('screens/space'));

function Story() {
  const [checkUserRight] = useCheckUserRight();
  const disableStoryV1 = checkUserRight('feature', 'disable-story-v1');
  const [storyView, setStoryView] = useStoryView();

  if (disableStoryV1) {
    setStoryView('v2');

    return <StoryV2 />;
  }

  return storyView === 'v1' ? <StoryV1 /> : <StoryV2 />;
}

function Protected() {
  const { addTab } = useTabs();
  const { pathname } = useLocation();
  const [, type, id] = pathname.split('/');

  /** On initial load, add content tab if not present in tabs atom */
  useEffect(() => {
    if (isContentType(type) && id) {
      addTab({ type, id });
    }
  }, [type, id, addTab]);

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Outlet />
    </Suspense>
  );
}

/**
 * Wrap the protected Routes to prevent re-rendering of
 * ProtectedProviders on route change.
 */
function ProtectedWrapper() {
  return (
    <ProtectedProviders>
      <MainLayout>
        <Protected />
      </MainLayout>
    </ProtectedProviders>
  );
}

type PathType = '*' | '/' | Icon | Content;

type Route = {
  path: `${PathType}${string}`;
  element: React.ReactElement;
  children?: Route[];
};

export const protectedRoutes: Route[] = [
  {
    path: '/',
    element: <ProtectedWrapper />,
    children: [
      { path: '/', element: <Home /> },
      { path: 'home', element: <Home /> },
      { path: 'feeds', element: <Feeds /> },
      { path: 'maps', element: <Maps /> },
      { path: 'plans', element: <Plans /> },
      { path: 'storyhub', element: <StoryHub /> },
      { path: 'create/:id', element: <Story /> },
      { path: 'pitch/:id', element: <Story /> },
      { path: 'story/:id', element: <Story /> },
      { path: 'story/:id/instances?/:instanceId?', element: <Story /> },
      { path: 'space/:id', element: <Space /> },
      { path: 'rundown/:id', element: <Rundown /> },
      { path: 'rundowntemplate/:id', element: <Rundown /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
];
