import { memo, useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { SearchParameters } from 'api/search/useSearch';
import CommandMenu from 'components/command/Command';
import useDebounce from 'hooks/useDebounce';
import { Box } from 'layouts/box/Box';
import { FilterValueType } from 'types/widget';

function Filters({
  filters = {},
  updateFilters,
}: Readonly<{
  filters: FilterValueType;
  updateFilters: (filters: FilterValueType) => void;
}>) {
  const [searchParams, setSearchParams] = useState<FilterValueType>(filters);
  const debouncedInputs = useDebounce<FilterValueType>(searchParams);

  const updateSearchParams = useCallback((value: SearchParameters) => {
    setSearchParams((values) => ({ ...values, searchParams: value }));
  }, []);

  useEffect(() => {
    if (!isEqual(debouncedInputs, filters)) {
      updateFilters(debouncedInputs);
    }
  }, [debouncedInputs, filters]);

  return (
    <Box container>
      <CommandMenu
        isMounted
        allowStaticSearchOptions={false}
        setSearchParams={updateSearchParams}
        searchParams={filters.searchParams as SearchParameters}
      />
    </Box>
  );
}

export default memo(Filters);
