import React, { memo, useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { ReactEditor, RenderElementProps, useReadOnly, useSelected, useSlate } from 'slate-react';

import { elementTypes } from 'components/editor/constants';
import { useEditorContext } from 'components/editor/EditorContext';
import useToggleSidePanel from 'components/editor/hooks/useToggleSidePanel';
import { TaskItem } from 'features/sidepanel/ComponentUtils';
import { getStatusOptions } from 'features/sidepanel/EditOrderBlock';
import { useSplitViewMolecule } from 'features/splitView/store';
import { useGetOrder } from 'screens/space/api/useGetOrdersAndForms';
import transientOptions from 'theme/helpers';
import { GetOrderEnum } from 'types/graphqlTypes';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import DragAndDrop from '../dragAndDrop';

export const FocusWrapper = styled('div', transientOptions)`
  padding: 8px;
  max-width: 616px;
`;

function OrderBlock({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = {
    type: elementTypes.PARAGRAPH,
    data: {},
    children: [],
  },
}: Readonly<RenderElementProps>) {
  const editor = useSlate();
  const isSelected = useSelected();
  const isReadOnly = useReadOnly();

  const { resourceDetails } = useEditorContext();
  const { toggleSidePanel } = useToggleSidePanel();
  const { useSelectedBlockId, useBlockExpandState } = useSplitViewMolecule();

  const [selectedBlockId, setSelectedBlockId] = useSelectedBlockId();
  const [expandState, setExpandState] = useBlockExpandState();
  const { orderFormMap } = resourceDetails ?? {};

  const { data = {} } = element;
  const { mId, taskType } = data;
  const { order } = useGetOrder(mId, GetOrderEnum.Resource);

  const isOrderSelected = selectedBlockId === mId;

  useEffect(() => {
    if (isSelected && mId) setSelectedBlockId(mId);
  }, [isSelected, isReadOnly]);

  const selectOrderBlock = useCallback(
    (event: React.MouseEvent) => {
      if (isReadOnly) preventDefaultAndPropagation(event);
      else ReactEditor.focus(editor);
      setSelectedBlockId(mId);
    },
    [isReadOnly, isSelected, editor],
  );

  const handleOpen = useCallback(
    (open: boolean) => {
      if (mId) {
        setExpandState({ ...expandState, [mId]: open });
      }
    },
    [mId, expandState],
  );

  const [status, statusOptions] = useMemo(() => {
    const { mFormId } = order ?? {};

    const stsOptions = getStatusOptions(mFormId, orderFormMap);

    return [stsOptions?.find((stsOption) => stsOption.value === order?.mStatus), stsOptions];
  }, [order]);

  const updatedOrder = order
    ? {
        ...order,
        formLabel: taskType ?? '',
        formColor: orderFormMap?.[order.mFormId]?.mColor,
        statusLabel: status?.label ?? order.mStatus,
        statusOptions,
      }
    : null;

  const openSidepanel = useCallback(() => {
    if (!updatedOrder) return;
    setSelectedBlockId(updatedOrder.mId);
    toggleSidePanel(updatedOrder.mId, 'tasks');
  }, [updatedOrder]);

  return (
    <div {...attributes}>
      <DragAndDrop element={element} enableReadOnly={false}>
        {children}
        <FocusWrapper contentEditable={false}>
          <TaskItem
            open={expandState[mId ?? ''] ?? false}
            setOpen={handleOpen}
            order={updatedOrder}
            selected={isReadOnly ? isOrderSelected : isSelected}
            onSelect={selectOrderBlock}
            openSidepanel={openSidepanel}
          />
        </FocusWrapper>
      </DragAndDrop>
    </div>
  );
}

export default memo(OrderBlock);
