import { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import groupBy from 'lodash/groupBy';

import LoadingIndicator from 'components/loadingIndicator';
import SearchTableView from 'components/searchTable';
import UserContext from 'contexts/UserContext';
import useCreateConversation from 'hooks/useCreateConversation';
import GET_CONVERSATIONS_OF_USER from 'operations/queries/getConversationsOfUser';
import { useMembers } from 'store/members';
import { Conversation } from 'types/messageHub';
import conversationTypes from 'utils/constants/conversationTypes';

import { useConversationMolecule } from '../../store/conversation';

import assembleDepartmentData from './utils/assembleDepartmentData';
import assemblePeopleData from './utils/assemblePeopleData';
import assembleTeamData from './utils/assembleTeamData';

const MAX_SELECTED_ITEMS = Infinity;

const columns = [
  {
    field: 'name',
    headerName: 'NAME',
    sortable: true,
    sortField: 'mTitle',
    searchField: 'mTitle',
  },
  {
    field: 'chatHistory',
    headerName: 'CHAT HISTORY',
    sortable: true,
    sortField: 'mUpdatedAt',
    searchField: 'chatHistory',
  },
];
const tabs = [{ label: 'Contacts' }, { label: 'Teams' }, { label: 'Departments' }];

function NewMessageContainer() {
  const { useSetCurrentConversation, useToggleIsCreateNewChat } = useConversationMolecule();
  const setCurrentConversation = useSetCurrentConversation();
  const toggleIsCreateNewChat = useToggleIsCreateNewChat();

  const [members] = useMembers();
  const user = useContext(UserContext);
  const [createConversation] = useCreateConversation();

  const handleCreateConversation = async (newConversationMembers: Conversation[]) => {
    await createConversation(newConversationMembers, setCurrentConversation);
  };

  const [selectedItems, setSelectedItems] = useState<Conversation[]>([]);
  const [tabIndex, setTabIndex] = useState(0);

  const handleItemSelect = (newSelectedItem: Conversation) => {
    if (MAX_SELECTED_ITEMS === 1 || tabIndex !== 0) setSelectedItems([newSelectedItem]);
    else if (
      selectedItems.length < MAX_SELECTED_ITEMS &&
      !selectedItems.find((item) => item.rowId === newSelectedItem.rowId)
    )
      setSelectedItems([...selectedItems, newSelectedItem]);
  };

  const handleOk = () => {
    void handleCreateConversation(selectedItems);
  };

  const handleDeleteSelectedItem = (newItem: Conversation) => {
    setSelectedItems(selectedItems.filter((item) => item.rowId !== newItem.rowId));
  };

  const handleTabChange = (_: string, index: number) => {
    setTabIndex(index);
    setSelectedItems([]);
  };

  const { data, error, loading } = useQuery<{ getConversationsOfUser: Conversation[] }>(
    GET_CONVERSATIONS_OF_USER,
    {
      variables: {
        input: {
          mId: user.mId,
        },
      },
    },
  );

  if (loading) return <LoadingIndicator />;
  if (error) return <div />;

  const groupedData = groupBy(data?.getConversationsOfUser, 'convoType');

  const peopleData = assemblePeopleData(
    members.user,
    user.mId,
    groupedData[conversationTypes.DIRECT],
  );

  const teams = assembleTeamData(members.team);

  const departments = assembleDepartmentData(members.department);

  const rows = [peopleData, teams, departments];

  return (
    <SearchTableView
      onItemSelect={handleItemSelect}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      onClose={toggleIsCreateNewChat}
      columns={columns}
      rows={rows[tabIndex]}
      closeOnSelect={false}
      inputType="chip"
      onOk={handleOk}
      showFooter
      title="New Message"
      onDeleteSelectedItem={handleDeleteSelectedItem}
      tabs={tabs}
      tabIndex={tabIndex}
      onTabChange={handleTabChange}
      timelineItems={undefined}
      storyInformationForTimeline={undefined}
      resourceTimelineDropdownItems={undefined}
      selectedType={undefined}
      onChangeType={undefined}
      showAvailableResourceOnly={undefined}
      setShowAvailableResourceOnly={undefined}
      shouldDisableTypeFilter={undefined}
    />
  );
}

export default NewMessageContainer;
