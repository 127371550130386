import styled from '@emotion/styled';
import { IconButton, Typography } from '@material-ui/core';

import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as Pitch } from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_off.svg';
import { ReactComponent as Warning } from 'assets/icons/systemicons/padlock_on.svg';
import { ReactComponent as Story } from 'assets/icons/systemicons/story_off.svg';

export const CheckBoxLabel = styled(Typography)<{ disabled?: boolean }>`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.dina.disabled : theme.palette.dina.highEmphasis};
  margin-left: 4px;
`;

export const ClearIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  right: 8px;
  :hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const CheckBoxWrapper = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: max-content;
  user-select: none;
`;

export const CloseButton = styled(IconButton)`
  margin-right: -4px;

  :hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const MetadataWrapper = styled('div')`
  padding: 12px 8px 8px 8px;
  height: 100%;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-bottom: none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.dina.backgroundHover};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  height: 40px;
  user-select: none;
  cursor: grab;
  padding-left: 12px;
  ${({ theme }) => theme.typography.dina.h7};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
`;

export const ContentWrapper = styled('div')`
  padding: 8px 12px;
  height: 100%;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CheckBoxWithOptions = styled('div')`
  margin-top: 8px;
`;

export const RestrictedInfo = styled('div')`
  display: flex;
  height: 26px;
  align-items: center;
`;

export const WarningIcon = styled(Warning)`
  width: 18px;
  height: 18px;
  path {
    fill: ${({ theme }) => theme.palette.dina.statusWarning};
    fill-opacity: 1;
  }
`;

export const RestrictedText = styled(Typography)`
  margin-left: 8px;
  text-align: center;
  font-size: 13px;
  line-height: 13px;
`;

export const Footer = styled('div')`
  height: 42px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const ButtonsWrapper = styled('div')`
  display: flex;
  flex: 1 1 auto;
  gap: 5px;
  justify-content: flex-end;
  white-space: pre;
`;

export const PitchIcon = styled(Pitch)`
  path {
    fill: ${({ theme }) => theme.palette.dina.blackHighEmphasis};
    fill-opacity: 1;
  }
`;

export const StoryIcon = styled(Story)`
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 1;
  }
`;
