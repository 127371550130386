import {
  ChangeEvent,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useContextMenu } from 'react-contexify';
import { Fade, InputAdornment } from '@material-ui/core';
import { useAtom } from 'jotai';

import { Block, BlockWithLabel, createBlockInput } from 'api/mdfBlocks/types';
import { useCreateBlock } from 'api/mdfBlocks/useCreateMdfBlock';
import useDeleteBlock from 'api/mdfBlocks/useDeleteMdfBlock';
import { useGetBlocks } from 'api/mdfBlocks/useGetMdfBlocks';
import { ReactComponent as CopyIcon } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/systemicons/print.svg';
import { IconButton } from 'components/buttons';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import { useEditorMolecule } from 'components/editor/store';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip/Tooltip';
import ContextMenu from 'features/contextMenu';
import PlanningPrint from 'features/print/PlanningPrint';
import { SearchField } from 'features/search/styled';
import { useSplitViewMolecule } from 'features/splitView/store';
import useCopyPlanning from 'hooks/useCopyPlanning';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useFuseSearch from 'hooks/useFuseSearch';
import useInputEvents from 'hooks/useInputEvents';
import { ResourceDetails } from 'hooks/useResourceDetails';
import { useEditorCommands, useEditorCommandsKeyed } from 'store';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import { atomWithSessionStorage } from 'utils/atoms/atomWithSessionStorage';

import { Fallback, PLANNING_MENU_ID, PlanningItem } from './ComponentUtils';
import { ToggleList } from './ToggleList';

import {
  CloseIcon,
  FilterWrapper,
  PlusIcon,
  SearchIcon,
  SearchWrapper,
  StyledMenu,
  StyledMenuItem,
} from './styled';

const titleSearchAtom = atomWithSessionStorage<string>('planning-block:titleSearch', '');

interface ControlledPlanningItemProps {
  block: BlockWithLabel;
  resourceId: string;
  updatePane?: () => void;
}
function ControlledPlanningItem({
  block,
  resourceId,
  updatePane,
}: Readonly<ControlledPlanningItemProps>) {
  const [open, setOpen] = useState(false);
  const { useForceOpenId, useSelectedBlockId, useShowComment } = useSplitViewMolecule();
  const [selectedBlockId, setSelectedBlockId] = useSelectedBlockId();
  const [forceOpenId, setForceOpenId] = useForceOpenId();
  const [showComment] = useShowComment();
  const { show } = useContextMenu({ id: PLANNING_MENU_ID });

  const onContextMenu = useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.preventDefault();
      show({
        event: ev,
        props: {
          block,
        },
      });
    },
    [show],
  );

  useEffect(() => {
    if (forceOpenId === block.mRefId) {
      setOpen(true);
    }
  }, [forceOpenId]);

  const onSelect = useCallback(
    (blockId: string) => {
      if (selectedBlockId !== blockId) {
        setSelectedBlockId(blockId);
        setForceOpenId(undefined);
      }
    },
    [selectedBlockId],
  );

  return (
    <PlanningItem
      id={`toggle-${block.mRefId}`}
      resourceId={resourceId}
      block={block}
      open={open}
      setOpen={setOpen}
      onContextMenu={onContextMenu}
      onSelect={() => onSelect(block.mRefId)}
      selected={selectedBlockId === block.mRefId}
      showComment={selectedBlockId === block.mRefId && showComment}
      updatePane={updatePane}
    />
  );
}
interface PlanningProps {
  resourceDetails: ResourceDetails;
  updatePane?: () => void;
}

type MenuClickType = {
  id: string;
  props: {
    block: Block;
  };
};

function Planning({ resourceDetails, updatePane }: Readonly<PlanningProps>) {
  const { errorToast } = useToast();
  const { deleteBlock } = useDeleteBlock();
  const [itemToDelete, setItemToDelete] = useState<Block | null>(null);
  const { resource } = resourceDetails ?? {};
  const { useSelectedBlockId } = useSplitViewMolecule();
  const { usePrintPlanningBlocks } = useEditorMolecule();
  const [, setSelectedBlockId] = useSelectedBlockId();
  const [searchText, setSearchText] = useAtom(titleSearchAtom);
  const [editorCommands] = useEditorCommands();
  const { createBlock } = useCreateBlock();
  const [debouncedSetText] = useDebouncedCallback(setSearchText, 300);
  const [editorCommandsKeyed] = useEditorCommandsKeyed();
  const [printPlanningBlocks, setPrintPlanningBlocks] = usePrintPlanningBlocks();

  const filteredCommands = useMemo(
    () => editorCommands.filter((cmd) => cmd.mResourceType !== MemberTypeEnum.OrderForm),
    [editorCommands],
  );

  const [createAnchor, setCreateAnchor] = useState<(EventTarget & SVGSVGElement) | null>();
  const [localValue, setLocalValue] = useState(searchText);

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(
    setSearchText,
    localValue,
    searchText,
  );

  const { blocks, refetch } = useGetBlocks(resource.mId);

  const fuseSearch = useFuseSearch();

  const matchFunction = useCallback(
    (list: BlockWithLabel[]) =>
      fuseSearch(list, ['mTitle', 'commandLabel'], localValue?.substring(1) || ''),
    [fuseSearch, localValue],
  );

  const blocksWithType: BlockWithLabel[] = useMemo(() => {
    return blocks.map((block) => {
      const cmd = editorCommandsKeyed[block.mSecId];
      return {
        ...block,
        commandLabel: cmd?.mTitle ?? '',
        color: cmd?.mColor,
      };
    });
  }, [blocks, editorCommandsKeyed]);

  const filteredBlocks = useMemo(
    () =>
      matchFunction(blocksWithType).sort((a, b) => a.commandLabel.localeCompare(b.commandLabel)),
    [matchFunction, blocksWithType],
  );

  const { onCopy, loading } = useCopyPlanning(filteredBlocks);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      event.preventDefault();
      const inputValue = event.target.value;
      void debouncedSetText(inputValue);
      setLocalValue(inputValue);
    },
    [debouncedSetText],
  );

  const onClosePlanningBlocksPrint = useCallback(() => {
    setPrintPlanningBlocks(false);
  }, [setPrintPlanningBlocks]);

  const clearSearch: MouseEventHandler<SVGSVGElement> = useCallback(
    (event) => {
      event.preventDefault();
      void debouncedSetText('');
      setLocalValue('');
    },
    [debouncedSetText],
  );

  const openCreateMenu: MouseEventHandler<SVGSVGElement> = useCallback((event) => {
    event.preventDefault();
    setCreateAnchor(event.currentTarget);
  }, []);

  const closeCreateMenu = useCallback(() => {
    setCreateAnchor(null);
  }, []);

  const onSelectMenu = useCallback(
    async (event: MouseEvent<HTMLLIElement>, cmd: EditorCommandConfigType) => {
      event.preventDefault();
      closeCreateMenu();
      const newBlock = await createBlock(createBlockInput(resource, cmd));
      setSelectedBlockId(newBlock?.mRefId);
    },
    [resource],
  );

  const handleDeleteBlock = useCallback(() => {
    if (itemToDelete) {
      deleteBlock({ mId: itemToDelete.mId, mRefId: itemToDelete.mRefId })
        .catch(errorToast)
        .finally(() => {
          refetch().catch(errorToast);
          setItemToDelete(null);
        });
    }
  }, [itemToDelete, deleteBlock, errorToast, refetch]);

  const handleMenuItemClicked = useCallback(
    (data: MenuClickType) => {
      if (data.id === 'removeBlock') setItemToDelete(data.props.block);
    },
    [setItemToDelete],
  );

  return (
    <>
      <ToggleList
        header={
          <SearchWrapper container height="48px" width="100%">
            <FilterWrapper container height="48px" width="24px">
              <Tooltip title="Add new item">
                <PlusIcon className="skipOverride" onMouseDown={openCreateMenu} />
              </Tooltip>
            </FilterWrapper>
            <SearchField
              id="sidepanel-search-input"
              size="small"
              value={localValue}
              onChange={handleChange}
              fullWidth
              InputProps={{
                placeholder: 'Type to find...',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className="skipOverride" />
                  </InputAdornment>
                ),
                endAdornment: localValue && (
                  <InputAdornment position="end" style={{ marginRight: '0px' }}>
                    <CloseIcon className="skipOverride" onMouseDown={clearSearch} />
                  </InputAdornment>
                ),
              }}
              onKeyDown={handleKeydown}
              onBlur={handleBlur}
              inputRef={inputRef}
              variant="outlined"
            />
            <IconButton
              variant="contained"
              usage="contained"
              size={24}
              iconSize={26}
              title="Print"
              onClick={() => {
                setPrintPlanningBlocks(true);
              }}
              disableEnhancedIconOpacity
            >
              <PrintIcon />
            </IconButton>
            <IconButton
              variant="contained"
              usage="contained"
              title="Copy planning items to clipboard"
              size={24}
              iconSize={24}
              disabled={loading}
              onClick={onCopy}
              disableEnhancedIconOpacity
            >
              <CopyIcon />
            </IconButton>
          </SearchWrapper>
        }
        list={
          <>
            {filteredBlocks.length > 0 ? (
              filteredBlocks.map((block) => (
                <ControlledPlanningItem
                  key={block.mRefId}
                  block={block}
                  resourceId={resource.mId}
                  updatePane={updatePane}
                />
              ))
            ) : (
              <Fallback label="No planning items..." />
            )}
          </>
        }
      />
      <StyledMenu
        id="create-menu"
        anchorEl={createAnchor}
        open={Boolean(createAnchor)}
        onClose={closeCreateMenu}
        TransitionComponent={Fade}
      >
        {filteredCommands.map((cmd) => {
          return (
            <StyledMenuItem key={cmd.mRefId} onClick={(event) => void onSelectMenu(event, cmd)}>
              {cmd.mTitle}
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
      <ContextMenu
        id={PLANNING_MENU_ID}
        menuItems={[
          {
            id: 'removeBlock',
            label: 'Delete item',
          },
        ]}
        onClick={handleMenuItemClicked}
      />
      {printPlanningBlocks && (
        <PlanningPrint
          isDialogOpen={true}
          onCloseDialog={onClosePlanningBlocksPrint}
          blocks={filteredBlocks}
        />
      )}
      <DeleteDialog
        open={Boolean(itemToDelete)}
        onClose={() => setItemToDelete(null)}
        onClick={handleDeleteBlock}
        title="Delete item?"
        message="Are you sure you want to delete this planning item? This cannot be undone!"
      />
    </>
  );
}

export default Planning;
