import { Text, View } from '@react-pdf/renderer';

import RtlText from 'components/RtlText/RtlText';
import getDirection from 'utils/text/getDirection';

interface AutoDirTextProps extends Pick<React.ComponentProps<typeof View>, 'style'> {
  value: string;
}

const AutoDirText = ({ value, style }: Readonly<AutoDirTextProps>) => {
  const direction = getDirection(value);

  return direction === 'rtl' ? (
    <View style={style}>
      <RtlText>{value}</RtlText>
    </View>
  ) : (
    <Text style={style}>{value}</Text>
  );
};

export default AutoDirText;
