import { useEffect } from 'react';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { isWithinRange } from 'date-fns';

import useApolloSubscription from 'hooks/useApolloSubscription';
import GET_ALL_RUNDOWN_TEMPLATES from 'operations/queries/getAllRundownTemplates';
import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'operations/queries/getRundownByPublishingDate';
import GET_RUNDOWNS from 'operations/queries/getRundowns';
import CREATE_RUNDOWNS_SUBSCRIPTION from 'operations/subscriptions/createRundowns';
import { useSidebarDatePickerAtom } from 'store/sidebar';
import { GetMembersByPublishingDateInput, MemberTypeEnum, Rundown } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { sanitizeDates } from './dateUtils';

type GetRundownsByPublishingDateReturnType = {
  getRundownsByPublishingDate: Rundown[];
};

type GetRundownsReturnType = {
  getRundowns: Rundown[];
};

type GetRundownTemplatesReturnType = {
  getAllRundownTemplates: Rundown[];
};

type GetRundownsByPublishingDateInputType = {
  input: GetMembersByPublishingDateInput;
};

type GetRundownsInputType = {
  mId: string;
};

type UpdateRundownSubscriptionType = {
  client: ApolloClient<object>;
  subscriptionData: {
    data: {
      createRundownsSubscription: Rundown;
    };
  };
};

const rundownAlreadyExists = (queriedRundowns: Rundown[], rundown: Rundown) =>
  queriedRundowns.find((r) => r.mId === rundown.mId && r.mRefId === rundown.mRefId);

const useSubscribeRundownsUpdate = () => {
  const logger = useLogger('use Subscribe Rundowns Update');

  const [selectedDates] = useSidebarDatePickerAtom();

  const client = useApolloClient();

  const updateMasterRundownQuery = (masterRundown: Rundown) => {
    try {
      const variables = { mId: '' };
      const { getRundowns } = client.readQuery<GetRundownsReturnType, GetRundownsInputType>({
        query: GET_RUNDOWNS,
        variables,
      }) ?? { getRundowns: [] };

      const { getAllRundownTemplates } = client.readQuery<
        GetRundownTemplatesReturnType,
        GetRundownsInputType
      >({
        query: GET_ALL_RUNDOWN_TEMPLATES,
        variables,
      }) ?? { getAllRundownTemplates: [] };

      if (rundownAlreadyExists(getRundowns, masterRundown)) return;

      const items = [masterRundown, ...(getRundowns ?? [])];
      const templateItems = [masterRundown, ...(getAllRundownTemplates ?? [])];

      client.writeQuery({
        query: GET_RUNDOWNS,
        variables,
        data: { getRundowns: items },
      });
      client.writeQuery({
        query: GET_ALL_RUNDOWN_TEMPLATES,
        variables,
        data: { getAllRundownTemplates: templateItems },
      });
    } catch (err) {
      if (err instanceof Error) {
        logger.error(err);
      }
    }
  };

  const updateRundownQuery = (rundown: Rundown) => {
    const { startDate, endDate } = selectedDates;
    const { from, to } = sanitizeDates(startDate, endDate);

    if (!rundown?.mPublishingAt || !isWithinRange(rundown?.mPublishingAt, from, to)) {
      return;
    }

    const variables: GetRundownsByPublishingDateInputType = {
      input: {
        mType: MemberTypeEnum.Rundown,
        startDate: from,
        endDate: to,
      },
    };

    try {
      const { getRundownsByPublishingDate } = client.readQuery<
        GetRundownsByPublishingDateReturnType,
        GetRundownsByPublishingDateInputType
      >({
        query: GET_RUNDOWNS_BY_PUBLISHING_DATE,
        variables,
      }) ?? { getRundownsByPublishingDate: [] };

      if (rundownAlreadyExists(getRundownsByPublishingDate, rundown)) return;

      const { recurrence } = rundown;

      if (recurrence) {
        delete recurrence.dailyExclusive;
        delete recurrence.startTime;
      }

      const items = [rundown, ...getRundownsByPublishingDate];

      client.writeQuery({
        query: GET_RUNDOWNS_BY_PUBLISHING_DATE,
        variables,
        data: {
          getRundownsByPublishingDate: items,
        },
      });
    } catch (err) {
      if (err instanceof Error) {
        logger.error(err);
      }
    }
  };

  const [subscribe, unsubscribe] = useApolloSubscription(CREATE_RUNDOWNS_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData }: UpdateRundownSubscriptionType) => {
      const rundown = subscriptionData?.data?.createRundownsSubscription;
      if (!rundown?.mType) return;
      const { mType } = rundown;
      if (mType === MemberTypeEnum.Rundown) updateRundownQuery(rundown);
      if (mType === MemberTypeEnum.Rundowntemplate) updateMasterRundownQuery(rundown);
    },
  });

  useEffect(() => {
    subscribe();
    return () => {
      unsubscribe();
    };
  }, [selectedDates]);
};

export default useSubscribeRundownsUpdate;
