/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useContext, useEffect, useMemo } from 'react';
import format from 'date-fns/format';
import { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { BlockWithLabel } from 'api/mdfBlocks/types';
import UserContext from 'contexts/UserContext';
import { getSubMdf, hasPermission, shouldFilterField } from 'features/mdf/mdf-utils';
import useGetBlocksWithMdf from 'features/print/hooks/useGetBlocksWithMdf';
import useGetOrdersWithMdf from 'features/print/hooks/useGetOrdersWithMdf';
import { useGetOrdersForEntities } from 'screens/space/api/useGetOrdersAndForms';
import { User } from 'types';
import { Metadata, MiniMember, OrderWithMdf } from 'types/forms/forms';
import {
  FieldTypeEnum,
  GetOrderEnum,
  LayoutSettings,
  Mdf,
  MdfField,
  TaskStatusEnum,
} from 'types/graphqlTypes';

import useCopyText from './useCopyText';
import useGetUser from './useGetUser';

const emptyUserMeta = '{"email": "", "phone": ""}';

export const getUserContent = (user: User | undefined) => {
  let userMeta: Metadata = {
    email: '',
    phone: '',
  };

  try {
    userMeta = JSON.parse(user?.metadata ?? emptyUserMeta) as Metadata;
  } catch (e) {
    //
  }

  return `Name: ${user?.mTitle} \nEmail: ${(userMeta.email as string) ?? ''} \nPhone: ${
    (userMeta.phone as string) ?? ''
  }`;
};

export const getMdfTextContent = (
  field: MdfField,
  settingsMap: Dictionary<LayoutSettings>,
  metadata: Metadata,
  getUserTitle: (userId: string) => string | undefined,
) => {
  const label = settingsMap[field.fieldId].label;
  const fieldValue = metadata[field.fieldId]?.toString() ?? '';

  let content = '';

  switch (field.type) {
    case FieldTypeEnum.text:
    case FieldTypeEnum.number:
      content = `${label}: ${fieldValue} `;
      break;

    case FieldTypeEnum.link:
      content = `${label}: ${fieldValue} `;
      break;

    case FieldTypeEnum.user:
      {
        const userName = getUserTitle(fieldValue);
        content = `${label}: ${userName ?? ''} `;
      }
      break;

    case FieldTypeEnum.treechoice:
      content = `${label}: ${fieldValue.replace(/,/g, ' -> ')} `;
      break;

    case FieldTypeEnum.checkbox:
      {
        const value = Boolean(fieldValue) === true ? `[x] ${label}` : `[ ] ${label}`;
        content = `${label}: ${value} `;
      }
      break;

    case FieldTypeEnum.date:
      content = `${label}: ${format(new Date(fieldValue), 'MMM D YYYY, HH:mm:ss (Z)')} `;
      break;

    case FieldTypeEnum.multiplechoice:
      content = `${label}: ${fieldValue !== '' ? fieldValue.split(',').join(', ') : ''} `;

      break;

    case FieldTypeEnum.choice:
      content = `${label}: ${fieldValue ?? ''} `;
      break;

    case FieldTypeEnum.relation: {
      const actualFieldValue = (metadata[field.fieldId] as MiniMember[]) ?? [];
      const stringifiedFieldValue: string = actualFieldValue
        .map((aFieldValue) => aFieldValue?.connectedId ?? aFieldValue.id)
        .join(', ');

      content = `${label}: ${stringifiedFieldValue ?? ''} `;
      break;
    }

    default:
      content = `${label}: ${fieldValue ?? ''} `;
      break;
  }

  return content;
};

export const getOrderContent = ({
  order,
  groups,
  getUserTitle,
  getUser,
  index,
  subMdfs,
  subOrders,
  title = 'Task',
}: {
  order: OrderWithMdf;
  title?: string;
  getUserTitle: (userId: string) => string | undefined;
  getUser: (userId: string) => User | undefined;
  groups: string[];
  index: number;
  subMdfs?: Mdf[];
  subOrders?: OrderWithMdf[];
}) => {
  const { metadata, mdf } = order;
  const subTypes = keyBy(subMdfs, (subMdf) => subMdf.label);

  const settingsMap = keyBy(mdf.views.default, (setting) => setting.fieldId);

  const visibleFields = mdf.fields?.filter((f) =>
    shouldFilterField(
      f,
      settingsMap,
      settingsMap,
      true,
      hasPermission(mdf?.permissions?.read[f.fieldId], groups),
    ),
  );

  const createdByUser = getUser(order.mCreatedById);
  const assigneeUser = getUser(order.mAssignee ?? '');

  const subOrdersContent: string = subOrders?.length
    ? subOrders
        .map((subOrder, subIndex) =>
          getOrderContent({
            order: subOrder,
            groups,
            index: subIndex,
            title: 'Sub task',
            getUserTitle,
            getUser,
          }),
        )
        .join('\n') ?? ''
    : '';

  const orderInfo = `Created by: \n${getUserContent(createdByUser)}\nAssignee: \n${
    assigneeUser ? getUserContent(assigneeUser) : 'No assignee'
  }`;

  return `\n${title} ${index + 1}\n${orderInfo}\n${
    visibleFields
      ?.map((field) => {
        if (field.type === FieldTypeEnum.subtype) {
          const subMdf = getSubMdf(field, metadata, subTypes);

          return getBlockContent({
            fields: subMdf?.fields,
            layoutSettings: subMdf?.views.default,
            permissions: subMdf?.permissions,
            metadata,
            blockTitle: settingsMap[field.fieldId].label,
            groups,
            getUserTitle,
            getUser,
          });
        }

        return getMdfTextContent(field, settingsMap, metadata, getUserTitle);
      })
      .join('\n') ?? ''
  }\n${subOrdersContent}`;
};

export const getBlockContent = ({
  fields,
  layoutSettings,
  metadata,
  permissions,
  subMdfs,
  blockTitle,
  orders,
  groups,
  getUserTitle,
  getUser,
}: {
  metadata: Metadata;
  groups: string[];
  getUserTitle: (userId: string) => string | undefined;
  getUser: (userId: string) => User | undefined;
  orders?: OrderWithMdf[];
  subMdfs?: Mdf[];
  fields?: MdfField[];
  layoutSettings?: LayoutSettings[];
  permissions?: Mdf['permissions'];
  blockTitle?: string;
}): string => {
  const subTypes = keyBy(subMdfs, (subMdf) => subMdf.label);

  const settingsMap = keyBy(layoutSettings, (setting) => setting.fieldId);

  const visibleFields = fields?.filter((f) =>
    shouldFilterField(
      f,
      settingsMap,
      settingsMap,
      true,
      hasPermission(permissions?.read[f.fieldId] as string[], groups),
    ),
  );

  const fieldContent = visibleFields
    ? visibleFields
        ?.map((field) => {
          if (field.type === FieldTypeEnum.subtype) {
            const subMdf = getSubMdf(field, metadata, subTypes);

            const subContent = getBlockContent({
              fields: subMdf?.fields,
              layoutSettings: subMdf?.views.default,
              permissions: subMdf?.permissions,
              metadata,
              blockTitle: settingsMap[field.fieldId].label,
              groups,
              getUserTitle,
              getUser,
            });

            return `\n${subContent}`;
          }

          return getMdfTextContent(field, settingsMap, metadata, getUserTitle);
        })
        ?.join('\n') ?? ''
    : 'No options selected';

  const orderContent = orders?.length
    ? orders
        ?.map((order, index) => {
          return getOrderContent({ order, groups, getUserTitle, index, subMdfs, getUser });
        })
        .join('\n')
    : '';

  return `${blockTitle}\n${fieldContent ?? 'No options selected'}${
    orders?.length ? `\nTasks${orderContent ? `\n${orderContent}` : ''}` : ''
  }\n`;
};

const useCopyPlanning = (blocks: BlockWithLabel[]) => {
  const { groups } = useContext(UserContext);
  const { getUserTitle, getUser } = useGetUser();
  const { onCopy: copyToClipboard } = useCopyText();

  const { mdfs, mdfsSeparated, loading, error } = useGetMdfs({ all: true });

  const mIds = useMemo(() => blocks.map((block) => block.mRefId), [blocks]);

  const {
    orders,
    loading: ordersLoading,
    error: ordersError,
    refetch: refetchOrders,
  } = useGetOrdersForEntities(mIds, GetOrderEnum.Resource, TaskStatusEnum.all);

  useEffect(() => {
    refetchOrders().then(
      () => {},
      () => {},
    );
  }, [blocks, refetchOrders]);

  const { blocksWithMdf } = useGetBlocksWithMdf(blocks, mdfs, mdfsSeparated);
  const { ordersWithMdf } = useGetOrdersWithMdf(orders, mdfs, mdfsSeparated);

  const onCopy = useCallback(async () => {
    if (ordersLoading || loading || !ordersWithMdf || !blocksWithMdf || ordersError || error)
      return;

    const allContent = `${blocksWithMdf
      .map((block) => {
        const { mTitle, mRefId, mdf, metadata } = block;

        return getBlockContent({
          fields: mdf?.fields,
          metadata,
          groups,
          subMdfs: mdfsSeparated.subTypes,
          layoutSettings: mdf?.views?.default,
          permissions: mdf?.permissions,
          blockTitle: mTitle,
          orders: ordersWithMdf.filter((order) => order.mResourceId === mRefId),
          getUserTitle,
          getUser,
        });
      })
      .join('\n')}`;

    await copyToClipboard(allContent, 'Items copied to clipboard');
  }, [
    blocksWithMdf,
    copyToClipboard,
    error,
    getUserTitle,
    groups,
    loading,
    mdfsSeparated.subTypes,
    ordersError,
    ordersLoading,
    ordersWithMdf,
  ]);

  return { onCopy, loading: loading || ordersLoading };
};

export default useCopyPlanning;
