import { useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';

import { useGetOptionList } from 'api/optionLists/useGetOptionList';
import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import Tooltip, { TooltipPlacement } from 'components/tooltip/Tooltip';
import { Alternative } from 'types/graphqlTypes';

import { FieldHeader } from '../../styled';
import { FieldProps } from '../fields';

import {
  SelectWrapper,
  StyledOption,
  StyledOptionWrapper,
  StyledPopper,
  StyledTextField,
} from './styled';

export function ChoiceField({
  fieldModel,
  value,
  setValue,
  fieldSettings,
  defaultFieldSettings,
  style,
  errorMessage,
  placeholder,
  disableEdit,
}: Readonly<FieldProps>) {
  const [tooltipPlacement, setTooltipPlacement] = useState<TooltipPlacement>(
    TooltipPlacement.BOTTOM,
  );

  const { fieldId, required, optionListId } = fieldModel;
  const { label, hint } = fieldSettings ?? defaultFieldSettings;
  const { optionList } = useGetOptionList(optionListId);
  const alternatives = useMemo(
    () => optionList?.alternatives ?? fieldModel.alternatives ?? [],
    [optionList, fieldModel],
  );

  const selectedValue = useMemo(() => {
    return alternatives.find((opt) => opt.value === value) ?? null;
  }, [value, alternatives]);

  const onFocus = () => {
    setTooltipPlacement(TooltipPlacement.TOP);
  };

  const onBlur = () => {
    setTooltipPlacement(TooltipPlacement.BOTTOM);
  };

  const renderInput = (params: object) => (
    <StyledTextField
      {...params}
      variant="filled"
      placeholder={disableEdit ? '' : placeholder ?? 'Select'}
      error={Boolean(errorMessage)}
      helperText={errorMessage?.length ? errorMessage : undefined}
    />
  );

  const renderOption = (alternative: Alternative) => (
    <StyledOptionWrapper $selected={selectedValue?.value === alternative.value}>
      <StyledOption>{alternative.label ?? alternative.value ?? 'Invalid option!'}</StyledOption>
    </StyledOptionWrapper>
  );

  return (
    <Tooltip title={hint || ''} placement={tooltipPlacement}>
      <SelectWrapper key={fieldId} style={style}>
        <FieldHeader>{label}</FieldHeader>
        <Autocomplete
          onFocus={onFocus}
          onBlur={onBlur}
          openOnFocus
          disableClearable={required}
          fullWidth
          blurOnSelect
          noOptionsText="No options available"
          selectOnFocus={false}
          options={alternatives}
          value={selectedValue}
          getOptionSelected={(alternative: Alternative, v: Alternative) => {
            if (!v) return false;
            return alternative.value === v.value;
          }}
          onChange={(_ev, alternative) => setValue(alternative?.value ?? null)}
          renderInput={renderInput}
          renderOption={renderOption}
          PopperComponent={StyledPopper}
          getOptionLabel={(alternative) => alternative?.label ?? alternative?.value}
          popupIcon={!disableEdit && <ArrowDown />}
        />
      </SelectWrapper>
    </Tooltip>
  );
}
