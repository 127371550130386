import { Document, Page, Text, View } from '@react-pdf/renderer';
import format from 'date-fns/format';

import { EditorValue, Note } from 'types';

import AutoDirText from '../components/AutoDirText';
import CommonDocFooter from '../components/CommonDocFooter';

import { getDocumentComponent } from './utils';

import { styles } from './styles';

const NoteDocHeader = ({ note }: { note: Note }) => {
  const { mTitle, mType, mCreatedAt } = note;

  return (
    <View style={styles.header} fixed>
      <Text style={styles.name}>{mType}</Text>
      <AutoDirText style={styles.title} value={mTitle || 'Untitled'} />
      <Text style={styles.scheduleInfo}>
        Created At: {format(mCreatedAt, 'MMM D YYYY, HH:mm:ss (Z)')}
      </Text>
      <View style={[styles.horizontalLine, { paddingTop: '8' }]} />
    </View>
  );
};

const NoteContents = ({ content }: { content: EditorValue }) => {
  const { document = [] } = content;

  if (document && document.length > 0) {
    return (
      <View style={[styles.column, styles.content]}>
        {document.map((doc) => {
          return getDocumentComponent({ doc });
        })}
      </View>
    );
  }

  return null;
};

interface Props {
  note: Note;
  content: EditorValue;
}

const NotePrintDoc = ({ note, content }: Props) => (
  <Document>
    <Page style={styles.body}>
      <NoteDocHeader note={note} />
      <NoteContents content={content} />
      <CommonDocFooter />
    </Page>
  </Document>
);

export default NotePrintDoc;
