import type { ColumnDef } from '@tanstack/react-table';

import DateCell from 'features/grids/common/components/cells/DateCell';
import MemberCell from 'features/grids/common/components/cells/MemberCell';
import TitleCell from 'features/grids/common/components/cells/TitleCell';
import { ColumnVisibilityMenu } from 'features/grids/common/components/ColumnVisibilityMenu';
import { ParsedMemberType } from 'types/members';

import { type ExtendedTableMeta, RowActions } from './components/actions/RowActions';

/** Configure non-mdf columns for the grid widget. */
export const getGridWidgetColumns = (): ColumnDef<ParsedMemberType>[] => [
  {
    accessorKey: 'mTitle',
    cell: ({ getValue, row }) => <TitleCell getValue={getValue} row={row} />,
    id: 'visibilityMenu',
    header: ({ table }) => <ColumnVisibilityMenu table={table}>Title</ColumnVisibilityMenu>,
    size: 300,
    enableHiding: false,
  },
  {
    accessorKey: 'mCreatedById',
    cell: ({ getValue }) => <MemberCell getValue={getValue} />,
    header: 'Created by',
    size: 160,
  },
  {
    accessorKey: 'eCreatedAt',
    cell: ({ getValue }) => getValue(),
    header: 'Created by 2',
    size: 160,
  },
  {
    accessorKey: 'mCreatedAt',
    cell: ({ getValue }) => <DateCell getValue={getValue} />,
    header: 'Created',
    size: 120,
  },
  {
    accessorKey: 'mUpdatedAt',
    cell: ({ getValue }) => <DateCell getValue={getValue} />,
    header: 'Updated',
    size: 120,
  },
  {
    cell: ({ row, table }) => (
      <RowActions row={row} tableMeta={table.options.meta as ExtendedTableMeta} />
    ),
    id: 'actions',
    enableHiding: false,
    enableResizing: false,
    enableSorting: false,
    maxSize: 56,
  },
];
