import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Chip } from '@material-ui/core';

import transientOptions from 'theme/helpers';
import { ReactComponent as ImageSvg } from 'assets/icons/systemicons/filetype/photo_on.svg';
import { ReactComponent as VideoSvg } from 'assets/icons/systemicons/filetype/video_on.svg';
import { ReactComponent as DocSvg } from 'assets/icons/systemicons/filetype/text_on.svg';
import { ReactComponent as AudioSvg } from 'assets/icons/systemicons/filetype/audio_on.svg';
import { ReactComponent as AddIconSvg } from 'assets/icons/systemicons/plus_ultraSmall_circle.svg';

export const FeedItem = styled('li', transientOptions)`
  user-select: none;
  cursor: pointer;
  background-color: ${({ $isBreaking, theme }) =>
    $isBreaking ? theme.palette.dina.prioBreakingRow : 'transparent'};
  :hover {
    background-color: ${({ $isBreaking, theme }) =>
      $isBreaking ? theme.palette.dina.prioBreakingRow : theme.palette.dina.whiteHoverOverlay};
    filter: brightness(108%);
    .addButton {
      path {
        fill-opacity: 0.3;
      }
    }
  }
`;

export const Item = styled('div')`
  display: flex;
  flex: 1 1 auto;
  margin-left: 12px;
  padding-top: 8px;
`;

export const Thumbnail = styled('img')`
  width: 32px;
  height: 32px;
  margin: 0 8px 12px 0;
  border-radius: 4px;
  object-fit: cover;
`;

export const Content = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Metadata = styled('span')`
  ${({ theme }) => theme.typography.dina.caption};
  margin-bottom: 4px;
  display: flex;
  gap: 4px;
  width: 100%;
`;

export const SectionChip = styled(Chip)`
  min-width: 40px;
  background-color: ${({ theme }) => theme.palette.dina.chipBackground};
  height: 16px;
  .MuiChip-label {
    ${({ theme }) => css`
      ${theme.typography.dina.captionSmall};
      color: ${theme.palette.dina.mediumEmphasis};
    `}
  }
`;

export const TextWrapper = styled('div')`
  padding-right: 14px;
  display: flex;
  flex: 1;
  align-self: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const Title = styled('p')`
  ${({ theme }) => theme.typography.dina.listItemLabelMedium};
  display: -webkit-box !important;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin: 0;
`;

export const Updated = styled('p')`
  white-space: nowrap;
  ${({ theme }) => theme.typography.dina.caption};
  margin: 0;
  position: absolute;
  right: 16px;
`;

export const Associations = styled('span')`
  margin-top: 6px;
  padding-left: 4px;
  height: 16px;
  display: flex;
  align-items: center;
`;

export const AssetIcons = styled('span')`
  height: 100%;
  padding-right: 9px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  min-width: 22px;
  :empty {
    border-right: transparent;
  }
`;

const FileIconStyle = ({ theme }) => css`
  height: 100%;
  width: 100%;
  path {
    fill: ${theme.palette.dina.mediumEmphasis};
  }
`;

export const IconWrapper = styled('div')`
  max-height: 12px;
  max-width: 12px;
  display: flex;
`;

export const ImageIcon = styled(ImageSvg)`
  ${FileIconStyle}
`;

export const VideoIcon = styled(VideoSvg)`
  ${FileIconStyle}
`;

export const DocIcon = styled(DocSvg)`
  ${FileIconStyle}
`;

export const AudioIcon = styled(AudioSvg)`
  ${FileIconStyle}
`;

export const Association = styled('span')``;

export const StoryCount = styled('span')`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.statusWarning};
  `}
  height: 100%;
  margin-right: -4px;
`;

export const Button = styled('div')`
  margin-left: 16px;
  display: flex;
  align-items: center;
  path {
    &.opacity-up {
      fill-opacity: 1;
    }
    fill-opacity: 0.54;
  }
  :hover {
    svg {
      path {
        fill-opacity: 0.8;
        transition: fill-opacity 0.2s ease-in;
      }
    }
    .addButton {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const AddButton = styled(AddIconSvg)`
  margin-left: -3px;
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0;
  }
`;
