import { memo, useMemo } from 'react';

import { useGetOptionList } from 'api/optionLists/useGetOptionList';
import Text from 'components/text/Text';
import LWSelect from 'features/orderForm/components/LWSelect';

import { CellProps } from '../fields';

function ChoiceCell({ fieldModel, setValue, value, disableEdit }: Readonly<CellProps>) {
  const { optionListId } = fieldModel;
  const { optionList } = useGetOptionList(optionListId);
  const alternatives = useMemo(
    () => optionList?.alternatives ?? fieldModel.alternatives ?? [],
    [optionList, fieldModel],
  );

  if (!alternatives?.length) {
    return (
      <Text variant="captionItalic" color="mediumEmphasis">
        No options
      </Text>
    );
  }

  return (
    <LWSelect
      disabled={disableEdit}
      value={typeof value === 'string' ? value : ''}
      setValue={setValue}
      options={alternatives}
    />
  );
}

export default memo(ChoiceCell);
