import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { type Table } from '@tanstack/react-table';

import { TableHeader, TableRow } from 'components/dataTable/Table';

import { ColumnFilterProps } from '../types';

import { TableHead } from './TableHead';
interface DataTableHeaderProps<TData> {
  /** The table instance */
  table: Table<TData>;
  /** Optional column filters */
  ColumnFilters?: React.ComponentType<ColumnFilterProps<TData>>;
}

/** Data table header with sort, group, and column filter support*/
export function DataTableHeader<TData>({
  table,
  ColumnFilters,
}: Readonly<DataTableHeaderProps<TData>>) {
  const state = table.getState();
  const items = state.columnOrder;
  return (
    <TableHeader
      style={{
        display: 'grid',
        position: 'sticky',
        top: '0px',
      }}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow style={{ display: 'flex', width: '100%' }} key={headerGroup.id}>
          <SortableContext items={items} strategy={horizontalListSortingStrategy}>
            {headerGroup.headers.map((header, index) => {
              return (
                <TableHead
                  header={header}
                  key={`${header.id}_${index}`}
                  ColumnFilters={ColumnFilters}
                  meta={table.options.meta}
                />
              );
            })}
          </SortableContext>
        </TableRow>
      ))}
    </TableHeader>
  );
}
