import { useEffect, useMemo } from 'react';

import Editor from 'components/editor';
import { Update } from 'components/editor/types';
import useResourceDetails from 'hooks/useResourceDetails';
import { useStoryToolbar } from 'screens/storyV2/store/toolbar';
import { useDoubleClickToLockEditor, useUsers } from 'store';
import clickIfNothingSelected from 'utils/clickIfNothingSelected';
import { getUserLockToken } from 'utils/lock/lockToken';

import useInstanceCore from '../hooks/useInstanceCore';
import useInstanceMetadata from '../hooks/useInstanceMetadata';
import useInstancePermissions from '../hooks/useInstancePermissions';
import useInstanceViewUtils from '../hooks/useInstanceViewUtils';
import useLoadAndSetContent from '../hooks/useLoadAndSetContent';
import { useInstanceMolecule } from '../store/instance';
import { EditorContentWrapper } from '../styled';

import EmbedView from './embedView';

const Body = () => {
  const {
    useInstanceValue,
    useDisableEdit,
    usePlatformVariant,
    useWriteLock,
    useReadLock,
    useView,
    instanceRef,
    editorValueRef,
    onDoneHotkeyVersion,
    loadingRef,
  } = useInstanceMolecule();
  const { canShowNewDesign, canShowCmsIframe, canUploadMediaBySignedURL } =
    useInstancePermissions();
  const {
    loading,
    handleEditorUpdate,
    editorValue,
    handleLockInstance,
    cancelDebounce,
    saveInstanceWithContent,
    userId,
    onDone,
  } = useInstanceCore();
  const {
    thumbnail,
    isCMSInstance,
    onCmsEditingClick,
    onSetEditor,
    placeholder,
    textDirection,
    variant,
    isCmsBlock,
    getPlaceholderConfigs,
    showFacebookEmbed,
    showTwitterEmbed,
    showInstagramEmbed,
    showYoutubeEmbed,
    resetSelection,
  } = useInstanceViewUtils();
  const { hostReadSpeed } = useInstanceMetadata();

  const [users] = useUsers();
  const instance = useInstanceValue();
  const resourceDetails = useResourceDetails({
    resource: instance,
  });
  const [disableEdit] = useDisableEdit();
  const [platformVariant] = usePlatformVariant();
  const [{ editorFontSize }] = useStoryToolbar();
  const [writeLock] = useWriteLock();
  const [readLock] = useReadLock();
  const [view] = useView();
  const [doubleClickToLockEditor] = useDoubleClickToLockEditor();

  useLoadAndSetContent();

  useEffect(() => {
    resetSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeLock]);

  useEffect(() => {
    const unlockBeforeUnload = (e?: BeforeUnloadEvent) => {
      if (e) {
        e.preventDefault();
        delete e.returnValue;
      }

      if (loadingRef.current || instanceRef.current?.locked !== getUserLockToken(userId)) {
        return;
      }

      cancelDebounce();

      const params = {
        instance: { ...instanceRef.current },
        unlock: true,
        audit: { source: 'instanceBody:unlockBeforeUnload' },
        ...(editorValueRef.current && { content: editorValueRef.current }),
      };
      saveInstanceWithContent(params).then(
        () => {},
        () => {},
      );
    };

    window.addEventListener('beforeunload', (e) => {
      unlockBeforeUnload(e);
    });

    return () => {
      unlockBeforeUnload();
      window.removeEventListener('beforeunload', (e) => {
        unlockBeforeUnload(e);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedEditor = useMemo(
    () => (
      <Editor
        height="100%"
        doLock={() => {
          handleLockInstance().catch(() => {});
        }}
        readOnly={!writeLock}
        readLock={readLock}
        update={handleEditorUpdate as Update}
        thumbnail={thumbnail}
        platformStructure={platformVariant?.platformStructure}
        value={editorValue}
        isAllowed={canShowNewDesign || (isCMSInstance && canShowCmsIframe)}
        onCmsEditing={onCmsEditingClick}
        enableEditorCommand
        setEditor={onSetEditor}
        placeholder={placeholder}
        withSignedUrl={canUploadMediaBySignedURL}
        direction={textDirection}
        resourceDetails={resourceDetails}
        users={users}
        variant={variant}
        hostReadSpeed={hostReadSpeed}
        isPublished={!!instance?.mPublishingAt}
        isCmsBlock={isCmsBlock}
        editorFontSize={editorFontSize}
        getPlaceholderConfigs={getPlaceholderConfigs}
        platformId={platformVariant?.id}
        platformKind={instance?.mProperties?.platformKind}
        renderToolbar={disableEdit ? () => null : undefined}
        onSave={() => onDone(onDoneHotkeyVersion)}
      />
    ),
    [
      writeLock,
      resourceDetails,
      readLock,
      handleEditorUpdate,
      handleLockInstance,
      thumbnail,
      platformVariant?.platformStructure,
      platformVariant?.id,
      editorValue,
      canShowNewDesign,
      isCMSInstance,
      canShowCmsIframe,
      onCmsEditingClick,
      onSetEditor,
      placeholder,
      canUploadMediaBySignedURL,
      textDirection,
      users,
      variant,
      hostReadSpeed,
      instance?.mPublishingAt,
      instance?.mProperties?.platformKind,
      isCmsBlock,
      editorFontSize,
      getPlaceholderConfigs,
      disableEdit,
      onDoneHotkeyVersion,
    ],
  );

  const content =
    view === 'edit' ? (
      renderedEditor
    ) : (
      <EmbedView
        showTwitterEmbed={showTwitterEmbed}
        showYoutubeEmbed={showYoutubeEmbed}
        showFacebookEmbed={showFacebookEmbed}
        showInstagramEmbed={showInstagramEmbed}
        provider={instance?.mProperties?.provider}
        readLock={readLock}
        writeLock={writeLock}
      />
    );

  return (
    <EditorContentWrapper
      role="presentation"
      onClick={(ev) => {
        if (doubleClickToLockEditor) return;
        clickIfNothingSelected(ev, handleLockInstance);
      }}
      onDoubleClick={() => {
        if (!doubleClickToLockEditor) return;
        handleLockInstance().then(
          () => {},
          () => {},
        );
      }}
      $disabled={loading}
    >
      {content}
    </EditorContentWrapper>
  );
};

export default Body;
