/* eslint-disable max-len */

/** Top level [media topics](https://iptc.org/standards/media-topics/) used for categorization.  */
export const topLevelMediaTopics = [
  {
    name: 'arts, culture and entertainment',
    url: 'https://cv.iptc.org/newscodes/mediatopic/01000000?lang=en',
    definition:
      'Matters pertaining to the advancement and refinement of the human mind, of interests, skills, tastes and emotions ',
    id: 'medtop:01000000',
    label: 'arts, culture and entertainment (medtop:01000000)',
  },
  {
    name: 'crime, law and justice',
    url: 'https://cv.iptc.org/newscodes/mediatopic/02000000?lang=en',
    definition:
      'Establishment and/or statement of the rules of behaviour in society, the enforcement of these rules, breaches of the rules and the punishment of offenders. Organisations and bodies involved in these activities. ',
    id: 'medtop:02000000',
    label: 'crime, law and justice (medtop:02000000)',
  },
  {
    name: 'disaster, accident and emergency incident',
    url: 'https://cv.iptc.org/newscodes/mediatopic/03000000?lang=en',
    definition:
      'Man made and natural events resulting in loss of life or injury to living creatures and/or damage to inanimate objects or property. ',
    id: 'medtop:03000000',
    label: 'disaster, accident and emergency incident (medtop:03000000)',
  },
  {
    name: 'economy, business and finance',
    url: 'https://cv.iptc.org/newscodes/mediatopic/04000000?lang=en',
    definition: 'All matters concerning the planning, production and exchange of wealth. ',
    id: 'medtop:04000000',
    label: 'economy, business and finance (medtop:04000000)',
  },
  {
    name: 'education',
    url: 'https://cv.iptc.org/newscodes/mediatopic/05000000?lang=en',
    definition: 'All aspects of furthering knowledge of human individuals from birth to death. ',
    id: 'medtop:05000000',
    label: 'education (medtop:05000000)',
  },
  {
    name: 'environment',
    url: 'https://cv.iptc.org/newscodes/mediatopic/06000000?lang=en',
    definition:
      'All aspects of protection, damage, and condition of the ecosystem of the planet earth and its surroundings. ',
    id: 'medtop:06000000',
    label: 'environment (medtop:06000000)',
  },
  {
    name: 'health',
    url: 'https://cv.iptc.org/newscodes/mediatopic/07000000?lang=en',
    definition: 'All aspects pertaining to the physical and mental welfare of human beings. ',
    id: 'medtop:07000000',
    label: 'health (medtop:07000000)',
  },
  {
    name: 'human interest',
    url: 'https://cv.iptc.org/newscodes/mediatopic/08000000?lang=en',
    definition:
      'Items about individuals, groups, animals, plants or other objects with a focus on emotional facets ',
    id: 'medtop:08000000',
    label: 'human interest (medtop:08000000)',
  },
  {
    name: 'labour',
    url: 'https://cv.iptc.org/newscodes/mediatopic/09000000?lang=en',
    definition:
      'Social aspects, organisations, rules and conditions affecting the employment of human effort for the generation of wealth or provision of services and the economic support of the unemployed. ',
    id: 'medtop:09000000',
    label: 'labour (medtop:09000000)',
  },
  {
    name: 'lifestyle and leisure',
    url: 'https://cv.iptc.org/newscodes/mediatopic/10000000?lang=en',
    definition:
      'Activities undertaken for pleasure, relaxation or recreation outside paid employment, including eating and travel. ',
    id: 'medtop:10000000',
    label: 'lifestyle and leisure (medtop:10000000)',
  },
  {
    name: 'politics',
    url: 'https://cv.iptc.org/newscodes/mediatopic/11000000?lang=en',
    definition:
      'Local, regional, national and international exercise of power, or struggle for power, and the relationships between governing bodies and states. ',
    id: 'medtop:11000000',
    label: 'politics (medtop:11000000)',
  },
  {
    name: 'religion and belief',
    url: 'https://cv.iptc.org/newscodes/mediatopic/12000000?lang=en',
    definition:
      'All aspects of human existence involving theology, philosophy, ethics and spirituality. ',
    id: 'medtop:12000000',
    label: 'religion and belief (medtop:12000000)',
  },
  {
    name: 'science and technology',
    url: 'https://cv.iptc.org/newscodes/mediatopic/13000000?lang=en',
    definition:
      'All aspects pertaining to human understanding of nature and the physical world and the development and application of this knowledge ',
    id: 'medtop:13000000',
    label: 'science and technology (medtop:13000000)',
  },
  {
    name: 'society',
    url: 'https://cv.iptc.org/newscodes/mediatopic/14000000?lang=en',
    definition: 'Aspects of the life of humans affecting its relationships ',
    id: 'medtop:14000000',
    label: 'society (medtop:14000000)',
  },
  {
    name: 'sport',
    url: 'https://cv.iptc.org/newscodes/mediatopic/15000000?lang=en',
    definition:
      'Competitive exercise involving physical effort. Organizations and bodies involved in these activities. ',
    id: 'medtop:15000000',
    label: 'sport (medtop:15000000)',
  },
  {
    name: 'conflicts, war and peace',
    url: 'https://cv.iptc.org/newscodes/mediatopic/16000000?lang=en',
    definition:
      'Acts of socially or politically motivated protest and/or violence and actions to end them ',
    id: 'medtop:16000000',
    label: 'conflicts, war and peace (medtop:16000000)',
  },
  {
    name: 'weather',
    url: 'https://cv.iptc.org/newscodes/mediatopic/17000000?lang=en',
    definition: 'The study, reporting and prediction of meteorological phenomena. ',
    id: 'medtop:17000000',
    label: 'weather (medtop:17000000)',
  },
];
