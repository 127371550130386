import { useContext } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';

import UserContext from 'contexts/UserContext';
import useGetUser from 'hooks/useGetUser';
import { OrderWithMdf } from 'types/forms/forms';
import { Mdf } from 'types/graphqlTypes';

import CommonDocFooter from '../components/CommonDocFooter';
import StoryIcon from '../components/StoryIcon';

import { Order } from './utils';

import { styles } from './styles';

interface Props {
  tasks: OrderWithMdf[];
  subTasks: OrderWithMdf[];
  mdfsSeparated: {
    defaults: Mdf[];
    custom: Mdf[];
    subTypes: Mdf[];
    instances: Mdf[];
  };
}

const PlanningDocHeader = () => {
  return (
    <View style={styles.header} fixed>
      <View style={[styles.headerContent, { alignItems: 'center' }]}>
        <StoryIcon />
        <Text style={styles.title}>Tasks</Text>
      </View>
      <View style={[styles.horizontalLine, { paddingTop: '8' }]} />
    </View>
  );
};

const Contents = ({ tasks, mdfsSeparated, subTasks }: Props) => {
  const { groups } = useContext(UserContext);
  const { getUserTitle, getUser } = useGetUser();
  return (
    <View style={[styles.column, styles.content, { gap: '16px', marginBottom: 16 }]}>
      {tasks.map((order, index) => {
        return (
          <Order
            key={order.mId}
            order={order}
            getUserTitle={getUserTitle}
            getUser={getUser}
            groups={groups}
            oddIndex={index % 2 !== 0}
            subMdfs={mdfsSeparated.subTypes}
            subOrders={subTasks.filter((subTask) => subTask.mResourceId === order.mId)}
          />
        );
      })}
    </View>
  );
};

const TasksPrintDoc = (props: Props) => (
  <Document>
    <Page style={styles.body}>
      <PlanningDocHeader />
      <Contents {...props} />
      <CommonDocFooter />
    </Page>
  </Document>
);

export default TasksPrintDoc;
