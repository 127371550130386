import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { flexRender, type Header, type TableMeta } from '@tanstack/react-table';

import { ReactComponent as Caret } from 'assets/icons/systemicons/arrows/arrow_double_unboxed.svg';
import { ReactComponent as SortDown } from 'assets/icons/systemicons/sort_down.svg';
import { ReactComponent as SortUp } from 'assets/icons/systemicons/sort_up.svg';
import { ReactComponent as Hide } from 'assets/icons/systemicons/visibility_off.svg';
import { Resizer, SortHandle } from 'components/dataTable/styled';
import { TableHead as Head } from 'components/dataTable/Table';
import { SortDirection, SortIcon } from 'components/dataTable/TableHeadSortIcons';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuShortcut,
  ContextMenuTrigger,
} from 'lib/contextMenu';

import { ColumnFilterProps } from '../types';

interface TableHeadProps<TData> {
  /** The table instance */
  header: Header<TData, unknown>;
  /** Optional column filters */
  ColumnFilters?: React.ComponentType<ColumnFilterProps<TData>>;
  meta?: TableMeta<TData>;
}

/** Data table header with sort, group, and column filter support*/
export function TableHead<TData>({ header, ColumnFilters, meta }: Readonly<TableHeadProps<TData>>) {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: header.column.id,
    // Disable temporarily until its implemented properly, (column reorder)
    disabled: true,
  });

  const column = header.column;

  const style: React.CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
    alignContent: 'center',
  };

  return (
    <Head colSpan={header.colSpan} style={style} {...attributes} {...listeners}>
      {header.isPlaceholder ? null : (
        <>
          <ContextMenu>
            <ContextMenuTrigger>
              <SortHandle onClick={() => column.toggleSorting()} ref={setNodeRef}>
                {flexRender(column.columnDef.header, header.getContext())}
                {{
                  asc: <SortIcon direction={SortDirection.ASC} />,
                  desc: <SortIcon direction={SortDirection.DESC} />,
                }[header.column.getIsSorted() as string] ?? null}
              </SortHandle>
            </ContextMenuTrigger>
            <ContextMenuContent>
              {column.getCanSort() ? (
                <>
                  {column.getIsSorted() === 'asc' ? null : (
                    <ContextMenuItem onClick={() => column.toggleSorting(false)}>
                      Sort ascending
                      <ContextMenuShortcut>
                        <SortDown />
                      </ContextMenuShortcut>
                    </ContextMenuItem>
                  )}
                  {column.getIsSorted() === 'desc' ? null : (
                    <ContextMenuItem onClick={() => column.toggleSorting(true)}>
                      Sort descending
                      <ContextMenuShortcut>
                        <SortUp />
                      </ContextMenuShortcut>
                    </ContextMenuItem>
                  )}
                  {column.getIsSorted() === 'asc' || column.getIsSorted() === 'desc' ? (
                    <ContextMenuItem onClick={() => column.clearSorting()}>
                      Clear sorting
                      <ContextMenuShortcut>
                        <Caret />
                      </ContextMenuShortcut>
                    </ContextMenuItem>
                  ) : null}
                  <ContextMenuSeparator />
                </>
              ) : null}
              {column.getCanGroup() ? (
                <>
                  <ContextMenuItem onClick={() => column.toggleGrouping()}>
                    {column.getIsGrouped() ? 'Ungroup' : 'Group'} by this column
                    <ContextMenuShortcut>
                      <Caret />
                    </ContextMenuShortcut>
                  </ContextMenuItem>
                  <ContextMenuSeparator />
                </>
              ) : null}
              {column.getCanHide() ? (
                <>
                  <ContextMenuItem onClick={() => column.toggleVisibility()}>
                    Hide column
                    <ContextMenuShortcut>
                      <Hide />
                    </ContextMenuShortcut>
                  </ContextMenuItem>
                </>
              ) : null}
            </ContextMenuContent>
          </ContextMenu>
          {ColumnFilters && header.column.getCanFilter() ? (
            <ColumnFilters column={header.column} meta={meta} />
          ) : null}
          {header.column.getCanResize() && (
            <Resizer
              onDoubleClick={() => column.resetSize()}
              onMouseDown={header.getResizeHandler()}
              onTouchStart={header.getResizeHandler()}
              className={`${column.getIsResizing() ? 'isResizing' : ''}`}
            />
          )}
        </>
      )}
    </Head>
  );
}
