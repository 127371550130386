import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import UserContext from 'contexts/UserContext';
import memberTypes from 'operations/memberTypes';
import GET_COMMENTS from 'operations/queries/getComments';
import { ConversationTypeEnum } from 'types/graphqlTypes';
import { Conversation } from 'types/messageHub';
import useLogger from 'utils/useLogger';

type QueryResult = {
  getMessagesOfConversation: { items: Conversation[] };
};

const CREATE_COMMENT = gql`
  mutation CreateMessage($input: CreateMessageInput) {
    createMessage(input: $input) {
      mId
      mRefId
      mType
      mTitle
      mContent
      mCreatedAt
      mUpdatedAt
      mCreatedById
      mUpdatedById
      convoType
      contentIds
      mResourceId
      mResourceType
      mStoryId
      mSecId
      mSecRefId
      mTertId
      mTertRefId
      mState
    }
  }
`;

const DELETE_COMMENT = gql`
  mutation DeleteMessage($input: CreateMessageInput) {
    deleteMessage(input: $input) {
      mId
      mRefId
      crudAction
      contentIds
      mResourceId
      mResourceType
      mStoryId
      mSecId
      mSecRefId
      mTertId
      mTertRefId
      mState
    }
  }
`;

const UPDATE_COMMENT = gql`
  mutation UpdateMessage($input: CreateMessageInput) {
    updateMessage(input: $input) {
      mId
      mRefId
      mType
      mTitle
      mContent
      mCreatedAt
      mUpdatedAt
      mCreatedById
      mUpdatedById
      convoType
      crudAction
      contentIds
      mResourceId
      mResourceType
      mStoryId
      mSecId
      mSecRefId
      mTertId
      mTertRefId
      mState
    }
  }
`;

interface CreateProps {
  mId: string;
  mContent?: string;
  mRefId?: string;
  crudAction?: 'UPDATE' | 'DELETE';
  mResourceId?: string; // mStoryId
  mResourceType?: string; // mType of resource
  mStoryId?: string; // rundownId
  mSecId?: string; // spaceId
  mSecRefId?: string; // formId
  mTertId?: string; // mOwner
  mTertRefId?: string; // mAssignee
  mState?: 'created' | 'done';
}

const useCreateMessage = () => {
  const logger = useLogger('use create comment');
  const [createMessage] = useMutation(CREATE_COMMENT);
  const [deleteMessage] = useMutation(DELETE_COMMENT);
  const [updateMessage] = useMutation(UPDATE_COMMENT);

  const user = useContext(UserContext);
  const now = new Date().toISOString();

  const create = async ({
    mId,
    mContent,
    mRefId,
    crudAction,
    mResourceId,
    mResourceType,
    mStoryId,
    mSecId,
    mSecRefId,
    mTertId,
    mTertRefId,
    mState,
  }: CreateProps) => {
    if (crudAction === 'UPDATE') {
      try {
        await updateMessage({
          variables: {
            input: {
              mId,
              mRefId,
              mContent,
              convoType: ConversationTypeEnum.Comment,
              crudAction,
              mState: mState ?? undefined,
            },
          },
          update: (cache, mutationResult) => {
            const { updateMessage: updateResult } = (mutationResult?.data ?? {}) as {
              updateMessage: Conversation;
            };
            const { getMessagesOfConversation } =
              cache.readQuery<QueryResult>({
                query: GET_COMMENTS,
                variables: { input: { mId } },
              }) ?? {};
            const items = getMessagesOfConversation?.items || [];
            const updateIndex = items.findIndex(
              (message) => message?.mRefId === updateResult?.mRefId,
            );
            if (updateIndex !== -1) {
              const updatedItems = [...items];
              updatedItems[updateIndex] = {
                ...updateResult,
                mCreatedById: updatedItems[updateIndex].mCreatedById,
              };

              cache.writeQuery({
                query: GET_COMMENTS,
                variables: { input: { mId } },
                data: {
                  getMessagesOfConversation: {
                    ...getMessagesOfConversation,
                    items: updatedItems,
                  },
                },
              });
            }
          },
        });
      } catch (e) {
        logger.error(e as string);
      }
    } else if (crudAction === 'DELETE') {
      try {
        await deleteMessage({
          variables: {
            input: {
              mId,
              mRefId,
              convoType: ConversationTypeEnum.Comment,
              crudAction,
            },
          },
          update: (cache) => {
            const { getMessagesOfConversation } =
              cache.readQuery<QueryResult>({
                query: GET_COMMENTS,
                variables: { input: { mId } },
              }) ?? {};

            const items = getMessagesOfConversation?.items || [];
            const newItems = mState ? [] : items.filter((message) => message.mRefId !== mRefId);

            cache.writeQuery({
              query: GET_COMMENTS,
              variables: { input: { mId } },
              data: {
                getMessagesOfConversation: {
                  ...getMessagesOfConversation,
                  items: newItems,
                },
              },
            });
          },
        });
      } catch (e) {
        logger.error(e as string);
      }
    } else {
      try {
        await createMessage({
          variables: {
            input: {
              mId,
              convoType: ConversationTypeEnum.Comment,
              mContent: mContent,
              mResourceId: mResourceId ?? undefined,
              mResourceType: mResourceType ?? undefined,
              mStoryId: mStoryId ?? undefined,
              mSecId: mSecId ?? undefined,
              mSecRefId: mSecRefId ?? undefined,
              mTertId: mTertId ?? undefined,
              mTertRefId: mTertRefId ?? undefined,
              mState: mState ?? undefined,
            },
          },
          update: (cache, mutationResult) => {
            const { createMessage: createMessageResult } = (mutationResult?.data ?? {}) as {
              createMessage: Conversation;
            };

            const { getMessagesOfConversation } =
              cache.readQuery<QueryResult>({
                query: GET_COMMENTS,
                variables: { input: { mId } },
              }) ?? {};

            const items = getMessagesOfConversation?.items || [];
            const isFound = items.find((message) => message.mRefId === createMessageResult.mRefId);
            const newMessageList = isFound ? items : [createMessageResult, ...items];

            cache.writeQuery({
              query: GET_COMMENTS,
              variables: { input: { mId } },
              data: {
                getMessagesOfConversation: {
                  ...getMessagesOfConversation,
                  items: newMessageList,
                },
              },
            });
          },
          optimisticResponse: {
            __typename: 'Mutation',
            createMessage: {
              mId,
              mRefId: now,
              mTitle: null,
              mContent,
              convoType: ConversationTypeEnum.Comment,
              mType: memberTypes.MESSAGE,
              mUpdatedAt: now,
              mCreatedAt: now,
              mCreatedById: user.mId,
              mUpdatedById: user.mId,
              contentIds: [mId, mResourceId, mSecId, mSecRefId, mTertId, mTertRefId].filter(
                Boolean,
              ),
              mResourceId: mResourceId ?? null,
              mResourceType: mResourceType ?? null,
              mStoryId: mStoryId ?? null,
              mSecId: mSecId ?? null,
              mSecRefId: mSecRefId ?? null,
              mTertId: mTertId ?? null,
              mTertRefId: mTertRefId ?? null,
              mState: mState ?? null,
              __typename: 'MessageType',
            },
          },
        });
      } catch (e) {
        logger.error(e as string);
      }
    }
  };

  return [create];
};

export default useCreateMessage;
