import { Fragment } from 'react';
import { Table } from '@tanstack/react-table';

import { ReactComponent as Settings } from 'assets/icons/systemicons/columns.svg';
import DropdownMenu from 'components/dropdownMenu/DropdownMenu';
import { HStack, VStack } from 'layouts/box/Box';
import { CheckboxWithLabel } from 'lib/checkbox';

interface VisibilityMenuProps<T> {
  table: Table<T>;
  children: React.ReactNode;
}

export function ColumnVisibilityMenu<T>({ table, children }: Readonly<VisibilityMenuProps<T>>) {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <HStack justifyContent="flex-start">
          <Settings style={{ height: '18px', width: '18px' }} />
        </HStack>
      </DropdownMenu.Trigger>
      {children}
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          <div onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
            <DropdownMenu.Header>Choose columns to show</DropdownMenu.Header>
            <DropdownMenu.Item
              onSelect={(e) => {
                e.preventDefault();
                table.toggleAllColumnsVisible();
              }}
            >
              <CheckboxWithLabel label="All" checked={table.getIsAllColumnsVisible()} />
            </DropdownMenu.Item>
            <DropdownMenu.Separator />
            <VStack alignItems="flex-start" overflow="auto">
              {table.getAllLeafColumns().map((column, index) => {
                if (
                  column.id === 'visibilityMenu' ||
                  column.id === 'actions' ||
                  !column.getCanHide()
                )
                  return <Fragment key={`${column.id}_${index}`} />;

                return (
                  <DropdownMenu.Item
                    key={`${column.id}_${index}`}
                    onSelect={(e) => {
                      e.preventDefault();
                      column.toggleVisibility();
                    }}
                  >
                    <CheckboxWithLabel
                      label={(column.columnDef.header as string) ?? column.id}
                      checked={column.getIsVisible()}
                    />
                  </DropdownMenu.Item>
                );
              })}
            </VStack>
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
}
