import { useEffect, useMemo, useState } from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { isEmpty } from 'lodash';

import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import useMdfErrorMap, { getErrorMap } from 'hooks/useMdfErrorMap';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { Mdf } from 'types/graphqlTypes';

const getParsedMD = (val: string) => {
  try {
    return JSON.parse(val ?? '{}') as Metadata;
  } catch (err) {
    return null;
  }
};

interface MetadataViewProps {
  mdf: Mdf;
  data: Record<string, string>;
  onUpdate: (metadata: string) => void;
  updateErrorTooltip?: (val: EmotionJSX.Element | undefined) => void;
}

function MetadataView({ data, mdf, onUpdate, updateErrorTooltip }: Readonly<MetadataViewProps>) {
  const parsedMetadata = useMemo(() => {
    return getParsedMD(data.metadata) ?? {};
  }, [data.metadata]);
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const { errorMap, validFieldMap, errorTooltip } = useMdfErrorMap(mdf, metadata ?? {}, 'default');

  const updateFieldValues = (val: NewFieldValue[]) => {
    const updatedMd: Metadata = {};
    for (const update of val) {
      updatedMd[update.fieldId] = update.value;
    }
    const newMetadata = { ...metadata, ...updatedMd };
    setMetadata(newMetadata);

    const shouldSave = isEmpty(getErrorMap(validFieldMap, newMetadata));
    if (shouldSave) onUpdate(JSON.stringify(newMetadata));
  };

  useEffect(() => {
    setMetadata(parsedMetadata);
  }, [parsedMetadata]);

  useEffect(() => {
    updateErrorTooltip?.(errorTooltip);
  }, [errorTooltip, updateErrorTooltip]);

  return (
    <MdfEditor
      errorMap={errorMap}
      view="default"
      fields={mdf.fields}
      moreVerticalSpace={true}
      defaultLayoutSettings={mdf.views.default}
      layoutSettings={mdf.views.default}
      metadata={metadata ?? parsedMetadata}
      permissions={mdf.permissions}
      updateFieldValue={updateFieldValues}
    />
  );
}

export default MetadataView;
