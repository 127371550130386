import { lazy, Suspense, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Drawer from '@material-ui/core/Drawer';

import LoadingIndicator from 'components/loadingIndicator';
import DailyNote from 'features/dailyNote';
import { useExternalUrls } from 'store/externalUrls';
import { type RightSelection, useRightHidden, useRightSelection } from 'store/sidebar';

import VerticalNavbar, { navbarPosition } from '../verticalNavbar/index';

import Chat from './Chat';
import Contacts from './Contacts';
import Departments from './Departments';
import Teams from './Teams';
import Users from './Users';

const Iframe = lazy(() => import('./iframe/Iframe'));
const IframeTrint = lazy(() => import('./iframe/IframeTrint'));

const Container = styled('div')`
  position: relative;
  width: 100%;
  & .MuiDrawer-root.MuiDrawer-docked {
    width: calc(100% - 48px);
  }
`;

const MUIDrawer = styled(Drawer)`
  z-index: 50;
  position: relative;
  transition: all 200ms;
  height: 100%;
  width: 100%;
  overflow: hidden;
  & .MuiBackdrop-root {
    display: none;
  }
  & .MuiDrawer-paper {
    position: relative;
    width: 100%;
  }
  & .MuiDrawer-paperAnchorRight {
    ${({ theme }) => css`
      box-shadow: -8px 0px 8px ${theme.palette.dina.boxShadowDark},
        0px 0px 1px ${theme.palette.dina.boxShadowDark};
    `}
  }
`;

const PanelWrapper = styled('div')<{ show?: boolean }>`
  height: 100%;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  overflow: hidden;
`;

const panels: Record<RightSelection, React.ReactElement | undefined> = {
  users: <Users />,
  tea_usr: <Teams />,
  dpr_usr: <Departments />,
  contacts: <Contacts />,
  chat: <Chat />,
  dailyNote: <DailyNote />,
  mos: (
    <Suspense fallback={<LoadingIndicator />}>
      <Iframe external="mos" />
    </Suspense>
  ),
  graphicsbox: (
    <Suspense fallback={<LoadingIndicator />}>
      <Iframe external="pilotedge" />
    </Suspense>
  ),
  storybox: (
    <Suspense fallback={<LoadingIndicator />}>
      <Iframe external="mimir" />
    </Suspense>
  ),
};

function RightPanel() {
  const [rightHidden, setRightHidden] = useRightHidden();
  const [rightSelection, setRightSelection] = useRightSelection();

  const [externalUrls] = useExternalUrls();
  const externalUrlsToShow = useMemo(
    () => externalUrls.filter((url) => url?.showInRightPanel === 'true'),
    [externalUrls],
  );

  const toggleRightSidebar = useCallback(
    () => setRightHidden(!rightHidden),
    [rightHidden, setRightHidden],
  );

  const handleSelectionChanged = useCallback(
    (value: RightSelection) => {
      setRightSelection(value);
    },
    [setRightSelection],
  );

  return (
    <Container>
      <VerticalNavbar
        hidden={rightHidden}
        selectionType={rightSelection}
        toggleSidebar={toggleRightSidebar}
        selectionChanged={handleSelectionChanged}
        position={navbarPosition.RIGHT}
      />
      <MUIDrawer variant="persistent" anchor="right" open={!rightHidden && !!rightSelection}>
        {(Object.keys(panels) as RightSelection[]).map((panel) => (
          <PanelWrapper key={panel} show={rightSelection === panel}>
            {panels[panel]}
          </PanelWrapper>
        ))}
        {externalUrlsToShow.map((url) => (
          <PanelWrapper key={url.id} show={rightSelection === `generic-${url.id}`}>
            <Suspense fallback={<LoadingIndicator />}>
              {url.label === 'Trint' ? (
                <IframeTrint external={url.mTitle} />
              ) : (
                <Iframe external={url.mTitle} />
              )}
            </Suspense>
          </PanelWrapper>
        ))}
      </MUIDrawer>
    </Container>
  );
}

export default RightPanel;
