import { useContext, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import Divider from 'components/divider';
import LoadingIndicator from 'components/loadingIndicator';
import MessageInput from 'components/messageInput';
import UserContext from 'contexts/UserContext';
import useCreateMessage from 'hooks/useCreateMessage';
import useTabs from 'hooks/useTabs';
import useUpdateConvoReadAt from 'hooks/useUpdateConvoReadAt';
import GET_MESSAGES_OF_CONVERSATION from 'operations/queries/getMessagesOfConversation';
import { useCurrentTabValue, useUsers } from 'store';
import conversationTypes from 'utils/constants/conversationTypes';
import useLogger from 'utils/useLogger';

import Messages from './messages-view';

const RootWrapper = styled('div')`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
`;

const MessageInputWrapper = styled('div')`
  margin: 8px;
  z-index: 1;
`;

const MessageContainer = styled('div')`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  padding-bottom: 8px;
`;

const supportedConversationTypes = [
  conversationTypes.STORY,
  conversationTypes.PITCH,
  conversationTypes.RUNDOWN,
  conversationTypes.SPACE,
  conversationTypes.TEAM,
  conversationTypes.DEPARTMENT,
];

const MessagesContainer = ({ mId, convoType, replacementPanel }) => {
  const logger = useLogger('Story/Rundown Chat');
  const user = useContext(UserContext);
  const [users] = useUsers();
  const lastMessageRef = useRef(null);
  const [createMessage] = useCreateMessage();
  const [updateConvoReadAt] = useUpdateConvoReadAt();
  const currentTab = useCurrentTabValue();
  const { resetTabNotification } = useTabs();

  const handleDeleteMessage = async (mRefId) => {
    await createMessage(mId, '', convoType, mRefId, 'DELETE');
  };

  const handleCreateMessage = async (newMessage) => {
    await createMessage(mId, JSON.stringify(newMessage), convoType);
  };

  const handleUpdateMessage = async (mRefId, mContent) => {
    await createMessage(mId, JSON.stringify(mContent), convoType, mRefId, 'UPDATE');
  };

  const handleResetNotification = () => {
    if (currentTab) resetTabNotification(currentTab);
  };

  useEffect(() => {
    lastMessageRef.current = null;
    updateConvoReadAt(mId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mId]);

  const variables = {
    input: { mId },
    limit: 25,
  };

  const { data, loading, error, fetchMore } = useQuery(GET_MESSAGES_OF_CONVERSATION, {
    variables,
    skip: !mId,
    fetchPolicy: 'cache-and-network',
  });

  if (!mId || !supportedConversationTypes.includes(convoType)) return <div />;
  if (loading) return <LoadingIndicator />;
  if (error) {
    logger.error(error);
    return <div />;
  }

  const messages = data?.getMessagesOfConversation?.items || [];
  const hasMore = Boolean(data?.getMessagesOfConversation?.nextToken);

  const sortedMessages = [...messages].sort((a, b) => (a.mCreatedAt > b.mCreatedAt ? -1 : 1));

  const handleLoadMore = async () => {
    if (hasMore) {
      const [lastMessage] = sortedMessages.slice(-1);
      lastMessageRef.current = lastMessage.mRefId;
      await fetchMore({
        variables: {
          ...variables,
          nextToken: data.getMessagesOfConversation.nextToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          getMessagesOfConversation: {
            items: [
              ...previousResult.getMessagesOfConversation.items,
              ...fetchMoreResult.getMessagesOfConversation.items,
            ],
            nextToken: fetchMoreResult.getMessagesOfConversation.nextToken,
            __typename: 'PaginatedMessageType',
          },
        }),
      });
    }
  };

  return (
    <RootWrapper onClick={handleResetNotification}>
      <MessageContainer>
        <Messages
          messages={sortedMessages}
          userId={user.mId}
          hasMore={hasMore}
          onLoadMore={handleLoadMore}
          lastMessageRef={lastMessageRef}
          onDeleteMessage={handleDeleteMessage}
          onUpdateMessage={handleUpdateMessage}
          suggestedUsers={users}
        />
      </MessageContainer>
      <Divider />
      {replacementPanel ?? (
        <MessageInputWrapper>
          <MessageInput onSend={handleCreateMessage} users={users} />
        </MessageInputWrapper>
      )}
    </RootWrapper>
  );
};

MessagesContainer.propTypes = {
  /** mId of the story/pitch/rundown */
  mId: PropTypes.string,
  /** supported conversation types (i.e. 'story', 'pitch', 'rundown', 'space') */
  convoType: PropTypes.string,
  /** Draws in place of the input field if so provided */
  replacementPanel: PropTypes.node,
};

MessagesContainer.defaultProps = {
  mId: '',
  convoType: '',
  replacementPanel: null,
};

export default MessagesContainer;
