import { useMemo } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { TableBody } from 'components/dataTable/Table';
import { EmptyState } from 'features/grids/common/components/EmptyState';
import { ReorderRows } from 'features/grids/widget/components/dnd/ReorderRows';
import { ParsedMemberType } from 'types';

import { DataTableDndBody } from '../../types';

import { DndRow } from './TableDndRow';

export function DndBody({
  table,
  virtualColumns,
  rowVirtualizer,
  onClick,
  itemIds = [],
  boardId,
  groupId,
}: Readonly<DataTableDndBody<ParsedMemberType>>) {
  const virtualRows = rowVirtualizer.getVirtualItems();
  const { rows } = table.getRowModel();

  const mdfs = useMemo(() => table.options.meta?.mdfs ?? [], [table.options.meta?.mdfs]);

  return (
    <TableBody
      style={{
        display: 'grid',
        height: `${rowVirtualizer.getTotalSize()}px`,
        position: 'relative',
      }}
    >
      {virtualRows?.length ? (
        <ReorderRows itemIds={itemIds} tableId={boardId} itemGroupId={groupId}>
          <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];

              return (
                <DndRow
                  key={row.id}
                  table={table}
                  row={row}
                  onClick={onClick}
                  rowVirtualizer={rowVirtualizer}
                  virtualColumns={virtualColumns}
                  mdfs={mdfs}
                  virtualRow={virtualRow}
                />
              );
            })}
          </SortableContext>
        </ReorderRows>
      ) : (
        <EmptyState>{table.options.meta?.emptyStateText}</EmptyState>
      )}
    </TableBody>
  );
}
