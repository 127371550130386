/* eslint-disable no-console */
import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';
import { v4 } from 'uuid';

export interface ShowToast {
  title: string | null;
  type: 'info' | 'error' | 'success';
  description?: string;
}

interface InternalToast extends ShowToast {
  id: string;
  setOpen: (val: boolean) => void;
}

const toastsAtom = atom<InternalToast[]>([]);
const useInternalToastsState = () => useAtom(toastsAtom);

const useToast = () => {
  const [toasts, setToasts] = useInternalToastsState();

  const toast = useCallback(
    (t: ShowToast) => {
      const id = v4();
      const newToast: InternalToast = {
        ...t,
        id,
        setOpen: (val: boolean) => {
          if (!val) {
            setToasts((prevToasts) => [...prevToasts.filter((to) => to.id !== id)]);
          }
        },
      };
      setToasts((prevToasts) => [...prevToasts, newToast]);
    },
    [setToasts],
  );

  const errorToast = useCallback(
    (err: unknown, description?: string) => {
      console.error(JSON.stringify(err, null, 2));
      toast({
        title: 'Error',
        description: description ? description : 'Something has gone wrong',
        type: 'error',
      });
    },
    [toast],
  );

  return { toast, errorToast, toasts };
};

export default useToast;
