import { Table } from 'components/dataTable/Table';
import { TableInstance } from 'features/grids/common/types';

import { ReorderColumnsWrapper } from './column/ReorderColumnWrapper';

interface DataTableProps<T> extends TableInstance<T> {
  children: React.ReactNode;
}

/** Table root component with keyboard nav and column sizing */
export function TableRoot<T>({ children, table }: Readonly<DataTableProps<T>>) {
  return (
    <ReorderColumnsWrapper table={table}>
      <Table style={{ display: 'grid', width: table.getCenterTotalSize() }}>{children}</Table>
    </ReorderColumnsWrapper>
  );
}
