import styled from '@emotion/styled';
import { CellContext } from '@tanstack/react-table';

import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/side_preview.svg';
import { Button } from 'components/buttons';
import { Icon } from 'components/memberIcon/MemberIcon';
import Text from 'components/text/Text';
import { HStack } from 'layouts/box/Box';
import { useSetPreview } from 'store/preview';
import { ParsedMemberType } from 'types/members';

type TitleCellProps = Pick<CellContext<ParsedMemberType, string>, 'row' | 'getValue'>;

export const StyledHStack = styled(HStack)`
  justify-content: flex-start;
  gap: 4px;
  position: relative;

  button {
    width: 70px;
    height: 24px;
    right: 10px;
    display: none;
    position: absolute;
  }

  :hover button {
    display: flex;
    background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  }
`;

export default function TitleCell({ getValue, row }: Readonly<TitleCellProps>) {
  const title = getValue() ?? '';

  const setPreview = useSetPreview();

  return (
    <StyledHStack>
      <Icon member={row.original} />
      <Text variant="listItemLabel" truncate>
        {title}
      </Text>
      <Button
        variant="outlined"
        usage="outlined"
        startIcon={<SidebarActive />}
        onClick={() => setPreview(row.original)}
      >
        Open
      </Button>
    </StyledHStack>
  );
}
