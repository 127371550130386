import styled from '@emotion/styled';

const ROW_HEIGHT = '36px';

export const StyledTable = styled('table')`
  width: 100%;
  border-spacing: 0;
  height: 100%;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  overflow: hidden;
  thead {
    background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
    ${({ theme }) => theme.typography.dina.overline};
  }
  tr {
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  }
  th,
  td {
    overflow: hidden;
    height: ${ROW_HEIGHT};
    user-select: none;
    padding-inline: 8px;
    border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
    :empty {
      cursor: not-allowed;
    }
    :hover {
      background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    }
  }
`;

export const SortHandle = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  gap: 4px;
  max-height: 14px;
  overflow: hidden;
  white-space: nowrap;
`;

export const Resizer = styled('div')`
  position: absolute;
  top: 0;
  height: 100%;
  right: -1.5px;
  width: 5px;
  border-radius: 2px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  :hover {
    background: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &.isResizing {
    background: ${({ theme }) => theme.palette.dina.onFocus};
    opacity: 1;
  }
`;
