/* eslint-disable sort-imports */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { omit } from 'lodash';

import {
  isUnsavedMdfField,
  UnsavedMdfField,
} from 'components/editMdfDialog/components/FieldModelRow';
import { Mdf, MdfField, MdfFieldEntity, MdfType, UpdateMdfInput } from 'types/graphqlTypes';

import { STANDARD_MDF_KEYS } from '../commonKeys';

const CREATE_MDF = gql`
  mutation UpdateMdf($input: UpdateMdfInput) {
    updateMdf(input: $input) {
      ${STANDARD_MDF_KEYS}
    }
  }
`;

interface UpdateMdf {
  updateMdf: Mdf;
}

interface Input {
  input: UpdateMdfInput;
}

const toMdfFieldEntity = (field: UnsavedMdfField | MdfField): MdfFieldEntity => {
  const copy: MdfField = isUnsavedMdfField(field)
    ? { ...omit(field, ['isUnsaved', 'existsElseWhere']) }
    : { ...field };
  return {
    ...copy,
    defaultValue: JSON.stringify(field.defaultValue),
    constraint: field.constraint ? JSON.stringify(field.constraint) : undefined,
    filters: field.filters ? JSON.stringify(field.filters) : undefined,
  };
};

const toMdfEntity = (mdf: Mdf): MdfType => {
  const copy = { ...mdf };
  delete copy.isSubtype;
  return {
    ...copy,
    fields: mdf.fields.map(toMdfFieldEntity),
    permissions: {
      read: JSON.stringify(mdf.permissions.read),
      write: JSON.stringify(mdf.permissions.write),
    },
  };
};

export const useUpdateMdf = () => {
  const [updateMutation] = useMutation<UpdateMdf, Input>(CREATE_MDF);
  const updateMdf = useCallback(
    async (mdfToSave: Mdf) => {
      const result = await updateMutation({
        variables: {
          input: toMdfEntity(mdfToSave),
        },
      });
      return result?.data?.updateMdf;
    },
    [updateMutation],
  );
  return { updateMdf };
};
