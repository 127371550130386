/* eslint-disable sort-imports */
import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { capitalize, ClickAwayListener, TableRow } from '@material-ui/core';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as ResetNumberingIcon } from 'assets/icons/systemicons/rundown/gridIcons/reset.svg';
import Checkbox from 'components/checkbox';
import { StyledFormControl } from 'components/command/toolbar/styled';
import { RowCell } from 'components/editMdfDialog/components/FieldModelRow';
import { Input } from 'components/input/styled';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Popper from 'components/shared/popper';
import Tooltip from 'components/tooltip';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import LWSelect from 'features/orderForm/components/LWSelect';
import { Box, HStack, VStack } from 'layouts/box/Box';
import {
  Alternative,
  AuthType,
  ConfigurableActionMTypes,
  IntegrationType,
  IntegrationUserType,
} from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { configurableActionMTypes } from './constants';

const DeleteButton = styled(DeleteIcon)`
  cursor: pointer;
  height: 18px;
  width: 18px;
  &:hover {
    path {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
`;

const ResetButton = styled(ResetNumberingIcon)`
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-right: 6px;
  &:hover {
    path {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
`;

const StyledRowCell = styled(RowCell)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

const StyledTableRow = styled(TableRow)<{ $selected: boolean }>`
  ${({ $selected, theme }) =>
    $selected &&
    css`
      td {
        background: ${theme.palette.dina.selectedNotActive};
        color: ${theme.palette.dina.highEmphasis};
      }
    `}
`;

const StyledDiv = styled('div')`
  width: 100%;
  max-width: 200px;
  padding: 3px;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.palette.dina.backgroundHover};
  }
`;

const authOptions: Alternative[] = [
  {
    id: AuthType.None,
    label: 'None',
    value: AuthType.None,
  },
  {
    id: AuthType.Apikey,
    label: 'API key',
    value: AuthType.Apikey,
  },
];

interface RowProps {
  action: IntegrationType;
  isSelected: boolean;
  hasChanges: boolean;
  availableSearchPluginTypes: IntegrationUserType[];
  onSelect: (action: IntegrationType) => void;
  onDeleteAction: (id: string) => void;
  onUpdateAction: (updates: Partial<IntegrationType>) => void;
  onResetAction: (id: string) => void;
}

export function EditActionRow({
  action,
  hasChanges,
  onDeleteAction,
  onUpdateAction,
  onResetAction,
  isSelected,
  onSelect,
  availableSearchPluginTypes,
}: Readonly<RowProps>) {
  const {
    mTitle,
    mDescription,
    auth,
    mTypes,
    connectedIds = [],
    iconUrl,
    mUpdatedAt,
    endpoint,
    mActive,
    externalId,
  } = action;
  const [title, setTitle] = useState(mTitle);
  const [localEndpoint, setLocalEndpoint] = useState(endpoint ?? '');
  const [localIconUrl, setLocalIconUrl] = useState(iconUrl ?? '');
  const [description, setDescription] = useState(mDescription ?? '');
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const toggleMType = useCallback(
    (type: ConfigurableActionMTypes) => {
      const currentTypes = mTypes ?? [];
      const newTypes = currentTypes.includes(type)
        ? [...currentTypes.filter((f) => f !== type)]
        : [...currentTypes, type];
      onUpdateAction({ mTypes: newTypes });
    },
    [mTypes],
  );

  const togglePlugin = useCallback(
    (config: IntegrationUserType) => {
      const currentPlugins = connectedIds ?? [];
      const newPlugins = currentPlugins.includes(config.mRefId)
        ? [...currentPlugins.filter((f) => f !== config.mRefId)]
        : [...currentPlugins, config.mRefId];
      onUpdateAction({ connectedIds: newPlugins });
    },
    [mTypes],
  );

  const selectedLabel = useMemo(() => {
    const types = mTypes?.join(', ');
    const pluginLabels = connectedIds
      ?.map((id) => {
        const plugin = availableSearchPluginTypes.find((p) => p.mRefId === id);
        if (plugin) {
          return plugin.mTitle;
        }
      })
      .join(', ');

    if (types && pluginLabels) return `${types}, ${pluginLabels}`;
    else if (types) return types;
    else if (pluginLabels) return pluginLabels;
    return '';
  }, [mTypes, connectedIds]);

  return (
    <StyledTableRow $selected={isSelected}>
      <StyledRowCell
        style={hasChanges ? { color: 'orange' } : undefined}
        onClick={() => onSelect(action)}
      >
        <Tooltip title="Test action">
          <span>
            {externalId}
            {hasChanges ? '*' : ''}
          </span>
        </Tooltip>
      </StyledRowCell>
      <RowCell>
        <Input
          value={title}
          onChange={(ev) => setTitle(ev.target.value)}
          onBlur={() => onUpdateAction({ mTitle: title })}
          placeholder="Type menu label here.."
        />
      </RowCell>
      <RowCell>
        <Input
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
          onBlur={() =>
            onUpdateAction({ mDescription: description.length === 0 ? null : description })
          }
          placeholder="Optional description"
        />
      </RowCell>
      <RowCell>
        <Input
          value={localIconUrl}
          onChange={(ev) => setLocalIconUrl(ev.target.value)}
          onBlur={() =>
            onUpdateAction({ iconUrl: localIconUrl.length === 0 ? null : localIconUrl })
          }
          placeholder="https://url-to-your-icon.com"
        />
      </RowCell>
      <RowCell>
        <StyledDiv onClick={(ev) => setRef(ev.currentTarget)}>{selectedLabel}</StyledDiv>
        <Popper anchorEl={ref ?? null} position="bottom">
          <ClickAwayListener onClickAway={() => setRef(null)}>
            <Box padding="5px 15px 5px 5px">
              <VStack alignItems="start">
                <Box margin="0 0 4px 0">Types</Box>
                {configurableActionMTypes.map((type) => {
                  return (
                    <div key={type}>
                      <StyledFormControl
                        control={
                          <Checkbox
                            onClick={() => toggleMType(type)}
                            selected={(mTypes ?? []).includes(type)}
                          />
                        }
                        label={capitalize(type)}
                      />
                    </div>
                  );
                })}
                <Box margin="4px 0 4px 0">Search plugins</Box>
                {availableSearchPluginTypes.map((plugin) => {
                  return (
                    <div key={plugin.mRefId}>
                      <StyledFormControl
                        control={
                          <Checkbox
                            onClick={() => togglePlugin(plugin)}
                            selected={(connectedIds ?? []).includes(plugin.mRefId)}
                          />
                        }
                        label={capitalize(plugin.mTitle)}
                      />
                    </div>
                  );
                })}
              </VStack>
              <CloseIcon
                style={{ position: 'absolute', top: '3px', right: '3px' }}
                onClick={() => setRef(null)}
              />
            </Box>
          </ClickAwayListener>
        </Popper>
      </RowCell>
      <RowCell>
        <LWSelect
          value={auth?.type ?? AuthType.None}
          options={authOptions}
          setValue={(val) =>
            onUpdateAction({ auth: { type: val as AuthType, __typename: 'Auth' } })
          }
        />
      </RowCell>
      <RowCell>
        <Input
          value={localEndpoint}
          onChange={(ev) => setLocalEndpoint(ev.target.value)}
          onBlur={() =>
            onUpdateAction({ endpoint: localEndpoint.length === 0 ? null : localEndpoint })
          }
          placeholder="REST endpoint"
        />
      </RowCell>
      <RowCell>
        <LWCheckbox selected={mActive} setValue={() => onUpdateAction({ mActive: !mActive })} />
      </RowCell>
      <RowCell>{isoToLocaleShort(mUpdatedAt)}</RowCell>
      <RowCell>
        <HStack justifyContent="start">
          <Tooltip title="Discard changes">
            <ResetButton onMouseDown={() => onResetAction(action.mRefId)} />
          </Tooltip>
          <Tooltip title="Delete field">
            <DeleteButton onMouseDown={() => onDeleteAction(action.mRefId)} />
          </Tooltip>
        </HStack>
      </RowCell>
    </StyledTableRow>
  );
}
