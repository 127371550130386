import { memo, useCallback, useMemo, useState } from 'react';
import { Dictionary } from 'lodash';
import capitalize from 'lodash/capitalize';

import { useGetMdf } from 'api/mdf/useGetMdf';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import AddMemberDropdown from 'components/addMember';
import MemberLabel from 'components/addMember/MemberLabel';
import Input from 'components/input/Input';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import { FieldHeader } from 'components/mdfEditor/styled';
import LWSelect from 'features/orderForm/components/LWSelect';
import { useGetOrder } from 'screens/space/api/useGetOrdersAndForms';
import useUpdateOrder from 'screens/space/api/useUpdateOrder';
import { useAllMembersKeyed } from 'store';
import { AssignedMember } from 'types';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { GetOrderEnum } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';
import getRelativeDateTime from 'utils/getRelativeDateTime';

import { FieldWrapper } from './styled';

const formatLocale = (date: string) =>
  capitalize(getRelativeDateTime(date, undefined, true, 'MMM D YYYY,  hh:mm a', 'hh:mm a'));

export const getStatusOptions = (
  mFormId?: string,
  orderFormMap?: Dictionary<OrderFormMemberType>,
) => {
  if (mFormId && orderFormMap) {
    const formConfigs = orderFormMap[mFormId]?.configs;
    const statusesConfig = formConfigs?.find((config) => config.key === 'statuses');
    return (
      statusesConfig?.alternatives?.map((alt) => ({
        ...alt,
        title: alt.label,
        value: alt.value.split('#')[1],
      })) ?? []
    );
  }
  return [];
};

interface EditOrderProps {
  orderId?: string;
  taskType?: string;
}

function EditOrderBlock({ orderId, taskType }: Readonly<EditOrderProps>) {
  const [members] = useAllMembersKeyed();
  const { order } = useGetOrder(orderId, GetOrderEnum.Resource);
  const { updateOrder } = useUpdateOrder();
  const { mdf } = useGetMdf(order?.mdfId);
  const { orderForms } = useGetOrderForms();

  const orderMap = useMemo(() => {
    const orderFormMap: Record<string, OrderFormMemberType> = {};
    orderForms.forEach((o) => {
      if (o.mSecId) {
        orderFormMap[o.mRefId] = o;
      }
    });
    return orderFormMap;
  }, [orderForms]);

  const [isFindingAssignee, setIsFindingAssignee] = useState<boolean>(false);
  const [isFindingOwner, setIsFindingOwner] = useState<boolean>(false);

  const assignee = useMemo(
    () => (order?.mAssignee ? members[order.mAssignee] : null),
    [order?.mAssignee],
  );
  const owner = useMemo(() => (order?.mOwner ? members[order.mOwner] : null), [order?.mOwner]);

  const onRemoveClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
  };

  const statusOptions = useMemo(() => getStatusOptions(order?.mFormId, orderMap) ?? [], [orderMap]);

  const updateOrderProperty = useCallback(
    (key: string, value: string) => {
      if (!order) return;

      updateOrder({
        mId: order.mId,
        mResourceId: order.mResourceId,
        [key]: value,
      }).then(
        () => {},
        () => {},
      );
    },
    [order],
  );

  const updateFieldValues = useCallback(
    (val: NewFieldValue[]) => {
      const updatedMd: Metadata = {};
      for (const update of val) {
        updatedMd[update.fieldId] = update.value;
      }
      const changedMetadata = { ...(order?.metadata ?? {}), ...updatedMd };
      updateOrderProperty('metadata', JSON.stringify(changedMetadata));
    },
    [order?.metadata],
  );

  return (
    mdf && (
      <>
        {taskType && (
          <FieldWrapper>
            <FieldHeader variant="overline">Task type</FieldHeader>
            <Input initialValue={taskType} readOnly />
          </FieldWrapper>
        )}
        <MdfEditor
          view="default"
          fields={mdf.fields}
          permissions={mdf.permissions}
          updateFieldValue={updateFieldValues}
          metadata={order?.metadata ?? {}}
          layoutSettings={[]}
          defaultLayoutSettings={mdf.views.default}
        />
        {order?.mStatus && (
          <FieldWrapper>
            <FieldHeader>Status</FieldHeader>
            <LWSelect
              value={order.mStatus}
              options={statusOptions}
              setValue={(value: string) => updateOrderProperty('mStatus', value)}
            />
          </FieldWrapper>
        )}
        <FieldWrapper>
          <FieldHeader>Assignee</FieldHeader>
          {isFindingAssignee ? (
            <AddMemberDropdown
              variant="all"
              autoFocus
              singleChoice
              disableClearable={true}
              selectedMembers={assignee ? [assignee] : []}
              setSelectedMembers={([newMember]: AssignedMember[]) =>
                updateOrderProperty('mAssignee', newMember.mId)
              }
              placeholderText="Set assignee"
              noOptionsText="No matches found"
              injectedMembers={undefined}
              onClose={() => setIsFindingAssignee(false)}
            />
          ) : (
            <MemberLabel
              variant="form"
              memberId={order?.mAssignee}
              onClick={() => setIsFindingAssignee(true)}
              onRemoveClick={onRemoveClick}
            />
          )}
        </FieldWrapper>
        <FieldWrapper>
          <FieldHeader>Owner</FieldHeader>
          {isFindingOwner ? (
            <AddMemberDropdown
              variant="all"
              singleChoice
              autoFocus
              disableClearable={true}
              selectedMembers={owner ? [owner] : []}
              setSelectedMembers={([newMember]: AssignedMember[]) =>
                updateOrderProperty('mOwner', newMember.mId)
              }
              placeholderText="Set owner"
              noOptionsText="No matches found"
              injectedMembers={undefined}
              onClose={() => setIsFindingOwner(false)}
            />
          ) : (
            <MemberLabel
              variant="form"
              memberId={order?.mOwner}
              onClick={() => setIsFindingOwner(true)}
              onRemoveClick={onRemoveClick}
            />
          )}
        </FieldWrapper>
        {order?.mCreatedAt && (
          <FieldWrapper>
            <FieldHeader>Created</FieldHeader>
            <Input initialValue={formatLocale(order.mCreatedAt)} readOnly />
          </FieldWrapper>
        )}
        {order?.mUpdatedAt && (
          <FieldWrapper>
            <FieldHeader>Updated</FieldHeader>
            <Input initialValue={formatLocale(order.mUpdatedAt)} readOnly />
          </FieldWrapper>
        )}
      </>
    )
  );
}

export default memo(EditOrderBlock);
