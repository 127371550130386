import styled from '@emotion/styled';

import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { HStack, VStack } from 'layouts/box/Box';
import { ConversationTypeEnum } from 'types/graphqlTypes';

import { useConversationMolecule } from '../../store/conversation';
import AvatarView from '../Avatar';

const HeaderWrapper = styled(HStack)`
  height: 40px;
  width: 100%;
  padding-inline: 12px;
  gap: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

function Header() {
  const { useCurrentConversation } = useConversationMolecule();
  const currentConversation = useCurrentConversation();
  const { name, convoType, mAssignedMembers } = currentConversation ?? {};
  return (
    <HeaderWrapper>
      <AvatarView conversation={currentConversation} />
      <Tooltip title={name ?? ''} enterDelay={700}>
        <VStack flex="1" alignItems="flex-start">
          <Text truncate variant="listItemLabelMedium" color="highEmphasis">
            {name}
          </Text>
          {convoType && convoType === ConversationTypeEnum.Group && (
            <Text
              variant="captionSmall"
              color="mediumEmphasis"
            >{`${mAssignedMembers?.length} members`}</Text>
          )}
        </VStack>
      </Tooltip>
    </HeaderWrapper>
  );
}

export default Header;
