import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { isEmpty, uniqBy } from 'lodash';

import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import { isMiniMember } from 'components/mdfEditor/fields/relation/relation-utils';
import { CommandToolbarProps } from 'features/command/command-types';
import { isObject } from 'types';
import { FieldValue, Metadata, SearchMetadata } from 'types/forms/forms';
import {
  MemberType,
  PaginatedItemType,
  SearchItemInput,
  SearchItemTypeEnum,
} from 'types/graphqlTypes';

import {
  SEARCH,
  SEARCH_CONTACTS,
  SEARCH_STORY_HUB_DEFAULT,
  SEARCH_STORY_HUB_HOUR,
  SEARCH_STORY_HUB_INSTANCES,
} from './queries';

export const Queries = {
  default: SEARCH,
  storyHub: SEARCH_STORY_HUB_DEFAULT,
  storyHubInstances: SEARCH_STORY_HUB_INSTANCES,
  storyHubHour: SEARCH_STORY_HUB_HOUR,
  contacts: SEARCH_CONTACTS,
};

export interface SearchResult {
  searchItem: PaginatedItemType;
}

export interface GetInput {
  input: SearchItemInput;
}

export type SearchDateRange = {
  from: string;
  to: string;
};

export interface SearchProps {
  skip: boolean;
  searchString: string;
  toolbarState: CommandToolbarProps;
  perPagelimit?: number;
  metadataFilter?: Metadata;
  mdfId?: string;
  doPoll?: boolean;
  pollInterval?: number;
  fetchAll?: boolean;
  queryType?: keyof typeof Queries;

  // If provided, provided searchString will be applied
  // to the provided prop, for now only mTitle is supported
  searchableKey?: SearchableKeys;

  // Will use metadataFilterByType if present
  forceMetadataMTypes?: SearchItemTypeEnum[];
}

/**
 * Currently only date type can be an object in Metadata values.
 */
export const isDateRange = (obj: FieldValue): obj is DateRange => {
  if (obj === null || !isObject(obj) || Array.isArray(obj)) return false;
  return true;
};

// TODO - instead of guessing the values, we should use the schemas to resolve
// the field types.
const process = (metadata: Metadata): SearchMetadata => {
  const copy: SearchMetadata = {};
  Object.entries(metadata).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value.trim().length > 0) copy[key] = value;
    } else if (isDateRange(value)) {
      copy[key] = {
        from: value.startDate,
        to: value.endDate ?? value.startDate,
      };
    } else if (Array.isArray(value) && value.length > 0) {
      if (isMiniMember(value[0])) {
        copy[key] = value[0].id;
      } else {
        copy[key] = value;
      }
    } else if (value !== null && value !== undefined) {
      copy[key] = value;
    }
  });
  return copy;
};

const getMDFfilter = (forceMTypes: SearchItemTypeEnum[], metadataFilter: Metadata) => {
  if (isEmpty(metadataFilter)) return undefined;
  if (forceMTypes.length > 0) {
    const mdFilter: Record<string, SearchMetadata> = {};
    for (const mType of forceMTypes) {
      mdFilter[mType] = process(metadataFilter);
    }
    return JSON.stringify(mdFilter);
  }
  return undefined;
};

const replacementTypes: Partial<{ [key in SearchItemTypeEnum]: SearchItemTypeEnum }> = {
  [SearchItemTypeEnum.story]: SearchItemTypeEnum.res_story,
  [SearchItemTypeEnum.pitch]: SearchItemTypeEnum.res_pitch,
  [SearchItemTypeEnum.archived_story]: SearchItemTypeEnum.archived_res_story,
  [SearchItemTypeEnum.archived_pitch]: SearchItemTypeEnum.archived_res_pitch,
};

const convertToRestrictedOnlyTypes = (mTypes: SearchItemTypeEnum[]): SearchItemTypeEnum[] => {
  return mTypes.map((type) => replacementTypes[type] ?? type);
};

export const getInput = (
  searchString: string,
  searchableKey: SearchableKeys | undefined,
  state: CommandToolbarProps,
  perPagelimit: number,
  metadataFilter: Metadata,
  mdfId: string | undefined,
  forceMetadataMTypes: SearchItemTypeEnum[] = [],
): SearchItemInput => {
  const {
    rangeBy,
    semanticSearch,
    statusFilter,
    mTypes,
    platformTypes,
    sortBy,
    order,
    assignedIds,
    createdByIds,
    isScheduled,
    matchAllAssignees,
    showRestricted,
    restrictedItemsOnly,
  } = state;
  const derivedSearchString = searchString.length > 0 ? searchString : undefined;
  return {
    perPagelimit,
    mdfId,
    mTypes: restrictedItemsOnly ? convertToRestrictedOnlyTypes(mTypes) : mTypes,
    rangeBy: rangeBy ?? undefined,
    searchString: !searchableKey ? derivedSearchString : undefined,
    mStates: statusFilter.length ? statusFilter : undefined,
    orderBy: sortBy !== 'best' ? { [sortBy]: order } : undefined,
    assignedMemberIds: !matchAllAssignees && assignedIds.length ? assignedIds : undefined,
    assignedMemberIdsMustOccur: matchAllAssignees && assignedIds.length ? assignedIds : undefined,
    createdByIds: createdByIds.length ? createdByIds : undefined,
    metaDataFilter: forceMetadataMTypes.length > 0 ? '{}' : JSON.stringify(process(metadataFilter)),
    metaDataFilterByType:
      forceMetadataMTypes.length > 0
        ? getMDFfilter(forceMetadataMTypes, metadataFilter)
        : undefined,
    platformTypes: platformTypes?.length ? platformTypes : undefined,
    semanticSearch: semanticSearch === true ? true : undefined,
    ...(searchableKey && { [searchableKey]: derivedSearchString }),
    isScheduled,
    showRestricted: showRestricted || restrictedItemsOnly,
  };
};

export type SearchParameters = Omit<SearchProps, 'doPoll' | 'queryType'>;
type SearchableKeys = keyof Pick<MemberType, 'mTitle'>;

export const useSearch = ({
  skip,
  searchString,
  searchableKey,
  perPagelimit = 25,
  metadataFilter = {},
  mdfId,
  toolbarState,
  doPoll,
  pollInterval = 15000,
  queryType = 'default',
  forceMetadataMTypes = [],
  fetchAll = false,
}: SearchProps) => {
  const input = useMemo(
    () =>
      getInput(
        searchString,
        searchableKey,
        toolbarState,
        perPagelimit,
        metadataFilter,
        mdfId,
        forceMetadataMTypes,
      ),
    [searchString, toolbarState, perPagelimit, metadataFilter, mdfId, forceMetadataMTypes],
  );

  const searchResult = useQuery<SearchResult, GetInput>(Queries[queryType], {
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
    skip,
  });

  const { data, error, loading, fetchMore, startPolling, stopPolling, refetch } = useMemo(
    () => searchResult,
    [searchResult],
  );

  useEffect(() => {
    if (doPoll) startPolling(pollInterval);

    return () => {
      if (doPoll) stopPolling();
    };
  }, [doPoll, pollInterval]);

  const items = useMemo(() => data?.searchItem?.items ?? [], [data?.searchItem?.items]);

  const handleLoadMore = useCallback(async () => {
    if (data?.searchItem.nextToken && items.length < data?.searchItem.total) {
      await fetchMore({
        variables: {
          input: {
            ...input,
            searchAfter: data?.searchItem.nextToken,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            searchItem: {
              items: uniqBy(
                [...previousResult.searchItem.items, ...fetchMoreResult.searchItem.items],
                'mId',
              ),
              nextToken: fetchMoreResult?.searchItem.nextToken,
              total: fetchMoreResult?.searchItem.total,
              __typename: 'PaginatedItemType',
            },
          };
        },
      });
    }
  }, [data?.searchItem.nextToken, data?.searchItem.total, items.length, fetchMore, input]);

  useEffect(() => {
    if (fetchAll && data?.searchItem.nextToken && items.length < data?.searchItem.total)
      handleLoadMore().catch(() => {});
  }, [data?.searchItem.nextToken, data?.searchItem.total, fetchAll, handleLoadMore, items.length]);

  const hasMore =
    typeof data?.searchItem.total === 'number' ? items.length < data?.searchItem.total : false;

  return {
    items,
    error,
    loading,
    total: data?.searchItem?.total ?? 0,
    handleLoadMore,
    hasMore,
    refetch,
  };
};
