import { BlockWithLabel, BlockWithLabelAndMdf } from 'api/mdfBlocks/types';
import useCustomMemo from 'hooks/useCustomMemo';
import { Mdf } from 'types/graphqlTypes';
import { getDefaultValues } from 'utils/mdf/utils';

const useGetBlocksWithMdf = (
  blocks: BlockWithLabel[],
  mdfs: Mdf[],
  mdfsSeparated: {
    defaults: Mdf[];
    custom: Mdf[];
    subTypes: Mdf[];
    instances: Mdf[];
  },
) => {
  const mdfsMap = useCustomMemo(
    () =>
      mdfs.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {} as { [key: string]: Mdf }),
    [mdfs],
  );

  const blocksWithMdf = useCustomMemo(() => {
    return blocks.map((block) => {
      const { mdfId } = block;
      const mdf = mdfsMap[mdfId];

      if (mdf) {
        const defaultValues = getDefaultValues(block.metadata, mdf, mdfsSeparated.subTypes);
        return { ...block, mdf, metadata: { ...block.metadata, ...defaultValues } };
      }

      return { ...block, mdf } as BlockWithLabelAndMdf;
    });
  }, [blocks, mdfsMap, mdfsSeparated.subTypes]);
  return { blocksWithMdf };
};

export default useGetBlocksWithMdf;
