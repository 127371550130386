import { memo } from 'react';

import { GridWidget } from 'features/grids/widget/GridWidget';
import useGetData from 'features/grids/widget/hooks/useGetData';
import WidgetWrapper from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';

import { WidgetProps } from './types';

type DataTableWidgetProps = Omit<WidgetProps, 'filters'>;

function DataTableWidget({ mId, ...wrapperProps }: Readonly<DataTableWidgetProps>) {
  const { itemGroupId, items, itemIds, loading } = useGetData(mId);

  if (loading) return <div>Loading...</div>;

  return (
    <WidgetWrapper mId={mId} type={WIDGETS.TABLE} {...wrapperProps}>
      <GridWidget boardId={mId} items={items} groupId={itemGroupId as string} itemIds={itemIds} />
    </WidgetWrapper>
  );
}

export default memo(DataTableWidget);
