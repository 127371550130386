import { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { flexRender } from '@tanstack/react-table';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { TableCell } from 'components/dataTable/Table';
import { HStack } from 'layouts/box/Box';

import { DataTableCell } from '../types';

export function Cell<T>({
  cell,
  updateFieldValues,
  metadata,
  errorMap,
  mdf,
  row,
}: Readonly<DataTableCell<T>>) {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });
  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition: 'width transform 0.2s ease-in-out',
    width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
    alignContent: 'center',
    // Background color for placeholder and aggregated cells
    background:
      cell.getIsPlaceholder() || cell.getIsAggregated()
        ? // eslint-disable-next-line max-len
          'repeating-linear-gradient(45deg, rgba(0,0,0,0.2), rgba(0,0,0,0.2) 2px, transparent 2px, transparent 4px)'
        : 'revert-layer',
  };

  const split = cell.column.id.split('_');
  const val = split.length > 0 ? split[split.length - 1] : undefined;

  return (
    <TableCell style={style} ref={setNodeRef}>
      {cell.getIsGrouped() ? (
        <HStack
          justifyContent="flex-start"
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: {
              cursor: row.getCanExpand() ? 'pointer' : 'normal',
            },
          }}
        >
          {row.getIsExpanded() ? <ArrowDown /> : <ArrowRight />}{' '}
          {flexRender(cell.column.columnDef.cell, {
            ...cell.getContext(),
            errorValue: errorMap[val ?? ''],
            updateFieldValues,
            metadata,
            mdf,
          })}{' '}
          ({row.subRows.length})
        </HStack>
      ) : cell.getIsAggregated() ? (
        <HStack
          justifyContent="flex-start"
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: {
              cursor: row.getCanExpand() ? 'pointer' : 'normal',
              width: '100%',
              height: '100%',
            },
          }}
        >
          {flexRender(
            cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
            cell.getContext(),
          )}
        </HStack>
      ) : cell.getIsPlaceholder() ? null : (
        flexRender(cell.column.columnDef.cell, {
          ...cell.getContext(),
          errorValue: errorMap[val ?? ''],
          updateFieldValues,
          metadata,
          mdf,
        })
      )}
    </TableCell>
  );
}
