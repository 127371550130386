import { memo } from 'react';
import CommandMenu from 'components/command/Command';
import memberTypes from 'operations/memberTypes';
import useSearchBar from 'hooks/useSearchBar';
import InstanceContainer from './instances';
import Rundown from './rundown';
import Stories from './stories';

const Sidebar = ({ selectionType }) => {
  const {
    filterModel,
    updateFilterModel,
    history,
    updateHistory,
    currentSearch,
    updateCurrentSearch,
    assignedMembers,
    searchString,
    platformsFilter,
    instanceAssignedMembers,
  } = useSearchBar({ historyLimit: 5 });

  if (selectionType === 'search') {
    return <CommandMenu isMounted allowStaticSearchOptions={false} />;
  }

  if (selectionType === memberTypes.RUNDOWN_USER) {
    return <Rundown />;
  }

  if (selectionType === memberTypes.INSTANCE) {
    return (
      <InstanceContainer
        filterModel={filterModel}
        updateFilterModel={updateFilterModel}
        history={history}
        updateHistory={updateHistory}
        currentSearch={currentSearch}
        updateCurrentSearch={updateCurrentSearch}
        searchString={searchString}
        platformsFilter={platformsFilter}
        assignedMembers={instanceAssignedMembers}
      />
    );
  }
  return (
    <Stories
      selectionType={selectionType}
      filterModel={filterModel}
      updateFilterModel={updateFilterModel}
      history={history}
      updateHistory={updateHistory}
      currentSearch={currentSearch}
      updateCurrentSearch={updateCurrentSearch}
      assignedMembers={assignedMembers}
      searchString={searchString}
    />
  );
};

export default memo(Sidebar);
