import { gql, useMutation } from '@apollo/client';
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions';

import { Instance } from 'types';
import { getUserLockToken } from 'utils/lock/lockToken';
import useLogger from 'utils/useLogger';

const LOCK_INSTANCE = gql`
  mutation lockInstance($input: LockMemberInput) {
    lockMember(input: $input) {
      mId
      mRefId
      mMetaData {
        key
        value
        autoValue
        manual
        mostag
      }
      mTitle
      locked
      mUpdatedAt
      mContentKey
    }
  }
`;

const useLockInstance = () => {
  const logger = useLogger('use lock instance');
  const [lockInstanceMutation] = useMutation<{ lockMember: Instance }>(LOCK_INSTANCE);

  const lock = async (instanceId: string, userId: string, fetchPolicy?: MutationFetchPolicy) => {
    const userLockToken = getUserLockToken(userId);
    const input = {
      mId: instanceId,
      userId: userLockToken,
    };

    try {
      const result = await lockInstanceMutation({
        variables: { input },
        fetchPolicy: fetchPolicy || 'network-only',
      });

      return result;
    } catch (e) {
      // eslint-disable-next-line no-console
      logger.log(e);
      return null;
    }
  };

  return [lock];
};

export default useLockInstance;
