import { useCallback } from 'react';

import { usePolicies } from 'store';
import { AccessTypeEnum, ResourcePolicy } from 'types/graphqlTypes';

const getResourcePermission = (resource: ResourcePolicy, action: string, defaultValue: boolean) => {
  const { permissions = [] } = resource ?? {};
  const permission = permissions.find((p) => p.action === action);
  if (!permission) return defaultValue;
  if (permission.access && permission.access === AccessTypeEnum.Allow) return true;
  return false;
};

const useCheckUserRight = () => {
  const [policies] = usePolicies();

  const checkPermission = useCallback(
    (resourceName: string, action: string) => {
      const defaultPermission = resourceName !== 'feature';
      const resource = policies.find((r) => r.resourceName === resourceName);
      if (!resource) return defaultPermission;
      return getResourcePermission(resource, action, defaultPermission);
    },
    [policies],
  );

  const checkPermissionStrict = useCallback(
    (resourceName: string, action: string) => {
      const resource = policies.find((r) => r.resourceName === resourceName);
      if (!resource) return false;
      return getResourcePermission(resource, action, false);
    },
    [policies],
  );

  const check = useCallback(
    (resourceName: string, action: string, strict = false) => {
      if (!strict) return checkPermission(resourceName, action);
      return checkPermissionStrict(resourceName, action);
    },
    [checkPermission, checkPermissionStrict],
  );

  return [check];
};

/**
 * Also hides the search in the side bar as it is experimental as part of this feature for now
 */
export const useCanSeeGridDecks = () => {
  const [checkUserRight] = useCheckUserRight();
  const canAccessGridDecks = checkUserRight('feature', 'grid_decks');
  return { canAccessGridDecks };
};

export const useCanSeeDataTable = () => {
  const [checkUserRight] = useCheckUserRight();
  const canAccessDataTableWidget = checkUserRight('feature', 'data_table_widget');
  return { canAccessDataTableWidget };
};

export const useCanSeePlugins = () => {
  const [checkUserRight] = useCheckUserRight();
  const canAccessPlugins = checkUserRight('feature', 'search_plugin');
  return { canAccessPlugins };
};

export const useCanSeeOrderManagement = () => {
  const [checkUserRight] = useCheckUserRight();
  const canAccessSpaces = checkUserRight('feature', 'spaces');
  const canAccessOrderManagement = checkUserRight('feature', 'ordermanagement') && canAccessSpaces;
  return { canAccessOrderManagement };
};

export default useCheckUserRight;
