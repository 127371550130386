import { useCallback, useState } from 'react';

import useToast from 'components/toast/useToast';

/**
 * A custom hook for handling text copying and managing copy tooltips. This hook can also
 * be used without using the tooltip feature.
 *
 * @param initialTooltip - The initial tooltip message displayed when no text is copied.
 *
 * @returns An object containing the current copy tooltip message and a function to initiate
 * text copying.
 */
const useCopyText = (initialTooltip: string = 'Copy item') => {
  const [copyTooltip, setCopyTooltip] = useState<string>(initialTooltip);
  const { toast } = useToast();

  /**
   * Copy the specified text to the clipboard and update the copy tooltip message.
   *
   * @param textToCopy - The text to copy to the clipboard.
   * @param copyMessage - The message to display as the copy tooltip after successful copying.
   */
  const onCopy = useCallback(async (textToCopy: string, copyMessage: string = 'Item Copied!') => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      toast({ title: 'Copied to clipboard', type: 'success' });
      if (copyMessage) {
        setCopyTooltip(copyMessage);
        setTimeout(() => setCopyTooltip(initialTooltip), 2000);
      }
    } catch (error) {
      setCopyTooltip('Copy failed');
    }
  }, []);

  return { copyTooltip, onCopy };
};

export default useCopyText;
