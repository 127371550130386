import Text from 'components/text/Text';
import { Box, HStack } from 'layouts/box/Box';

import { type MultiSelectContextProps } from '../types';

import { SelectedBadge } from './InputBadge';

export type ChipsType = Pick<
  MultiSelectContextProps,
  'onChange' | 'selected' | 'setSelected' | 'setInputValue' | 'handleUnselect'
>;

export const Chips = ({
  onChange,
  handleUnselect,
  setInputValue,
  selected,
  setSelected,
}: ChipsType) => {
  const onClearAllClick = () => {
    setSelected(selected.filter((s) => s.fixed));
    setInputValue('');
    onChange?.(selected.filter((s) => s.fixed));
  };

  return (
    <HStack gap="4px" flexWrap="wrap" justifyContent="flex-start" margin="4px 0 0 0">
      {selected.map((option) => {
        return (
          <SelectedBadge
            key={option.value}
            {...{
              handleUnselect,
              option,
            }}
          />
        );
      })}

      <Box cursor="pointer" onClick={onClearAllClick}>
        <Text variant="captionLink" color="textUrl">
          Clear all
        </Text>
      </Box>
    </HStack>
  );
};
