import React from 'react';

import Tooltip from 'components/tooltip/Tooltip';
import Slot, { Slottable } from 'lib/slot';

import { DisableWrapper, StyledButton } from './styled';

function TooltipWrapper({
  children,
  title,
  disabled,
}: {
  children: React.ReactElement;
  title?: string | React.ReactElement;
  disabled?: boolean;
}) {
  return title ? (
    <Tooltip title={title}>
      {disabled ? <DisableWrapper>{children}</DisableWrapper> : children}
    </Tooltip>
  ) : (
    children
  );
}

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'title'> {
  // Accessibility
  ariaLabel?: string;
  // Functionality
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  // Styling
  align?: 'left' | 'center' | 'right';
  autoWidth?: boolean;
  className?: string;
  dark?: boolean;
  padding?: number;
  required?: boolean;
  selected?: boolean;
  style?: React.CSSProperties;
  usage?: 'cta' | 'danger' | 'outlined' | 'pitch' | 'significant' | 'story' | 'text' | 'warning';
  variant?: 'contained' | 'discreet' | 'outlined' | 'dashed';
  width?: number | string;
  height?: number | string;
  // Content
  children: React.ReactNode;
  startIcon?: React.ReactElement;
  /** Used for tooltip */
  title?: string | React.ReactElement;
  autoFocus?: boolean;
  // Slot
  asChild?: boolean;
}

function Button({
  align = 'center',
  ariaLabel = '',
  autoWidth = undefined,
  dark = undefined,
  disabled = false,
  title = '',
  usage = 'cta',
  variant = 'contained',
  type = 'button',
  onClick = () => {},
  startIcon,
  asChild,
  children,
  ...rest
}: Readonly<ButtonProps>) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) onClick?.(e);
  };

  const Comp = asChild ? Slot : StyledButton;

  return (
    <TooltipWrapper title={title} disabled={disabled}>
      <Comp
        // functionality
        disabled={disabled}
        aria-label={ariaLabel}
        onClick={handleClick}
        type={type}
        // styling
        align={align}
        autoWidth={autoWidth}
        usage={usage}
        dark={dark}
        variant={variant}
        {...rest}
      >
        {startIcon}
        <Slottable>{children}</Slottable>
      </Comp>
    </TooltipWrapper>
  );
}

export default Button;
