import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { dialogBoxShadow } from 'theme/utils/commonStyles';

export const Root = styled('div')<{
  $minWidth: string;
  $maxWidth: string;
  $dark: boolean;
  $isDragging: boolean;
  $isOver: boolean;
  $drawLeftBorder: boolean;
}>`
  height: 100%;
  width: 100%;
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};
  border-radius: 8px;
  ${dialogBoxShadow};
  background-color: ${({ theme, $dark }) =>
    $dark ? theme.palette.dina.surfaceAppBackgroundNavLevel1 : theme.palette.dina.surfaceCard};
  display: flex;
  flex-flow: column;
  user-select: none;
  opacity: ${({ $isDragging }) => ($isDragging ? '0.6' : '1')};
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: 0px;
    width: 4px;
    top: 0;
    height: 100%;
    ${({ $drawLeftBorder }) => ($drawLeftBorder ? 'left: -6px' : 'right: -6px')};
    background: ${({ $isOver, theme }) => ($isOver ? theme.palette.dina.onFocus : 'transparent')};
  }
`;

export const Header = styled('div')`
  height: 40px;
  display: flex;
  align-items: center;
  padding-inline: 4px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background-color: ${({ theme }) => theme.palette.dina.overlaysHeaderFooter};
`;

export const HeaderTitle = styled('h2')`
  ${({ theme }) => theme.typography.dina.h6};
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: 4px;
  flex: 1 1 auto;
  padding: 0;
`;

export const Input = styled('input')`
  ${({ theme }) => theme.typography.dina.h6};
  background: transparent;
  border: none;
  outline: none;
  flex: 1 1 auto;
  border: 1px solid transparent;
  border-radius: 6px;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
    border-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
  }
  :focus-within {
    border: 1px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
    background: ${({ theme }) => theme.palette.dina.statusOnFocusedDiscreetFill};
  }
`;

export const Toolbar = styled('div')`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 3px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const Filters = styled('div')`
  height: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;

export const SwitchControl = styled('div')`
  display: flex;
`;

export const SwitchLabel = styled('p')`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.highEmphasis};
  `}
  position: relative;
  left: -5px;
`;

export const Body = styled('div')<{ $paddingX: number; $paddingY: number }>`
  height: calc(100% - 44px);
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: ${({ $paddingX }) => $paddingX}px;
  padding-right: ${({ $paddingX }) => $paddingX}px;
  padding-top: ${({ $paddingY }) => $paddingY}px;
  padding-bottom: ${({ $paddingY }) => $paddingY}px;
  overflow: hidden;
`;

export const Footer = styled('div')`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 12px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const ControlButtons = styled('div')`
  display: flex;
  gap: 8px;
`;
