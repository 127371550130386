import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { capitalize, ClickAwayListener, TableRow } from '@material-ui/core';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as ResetNumberingIcon } from 'assets/icons/systemicons/rundown/gridIcons/reset.svg';
import Checkbox from 'components/checkbox';
import { StyledFormControl } from 'components/command/toolbar/styled';
import { RowCell } from 'components/editMdfDialog/components/FieldModelRow';
import { Input } from 'components/input/styled';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Popper from 'components/shared/popper';
import Tooltip from 'components/tooltip';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { ConfigurableActionMTypes, IntegrationType } from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { configurableActionMTypesHook } from './constants';

const DeleteButton = styled(DeleteIcon)`
  cursor: pointer;
  height: 18px;
  width: 18px;
  &:hover {
    path {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
`;

const ResetButton = styled(ResetNumberingIcon)`
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-right: 6px;
  &:hover {
    path {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
`;

const StyledRowCell = styled(RowCell)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

const StyledTableRow = styled(TableRow)<{ $selected: boolean }>`
  ${({ $selected, theme }) =>
    $selected &&
    css`
      td {
        background: ${theme.palette.dina.selectedNotActive};
        color: ${theme.palette.dina.highEmphasis};
      }
    `}
`;

const StyledDiv = styled('div')`
  width: 100%;
  max-width: 200px;
  padding: 3px;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.palette.dina.backgroundHover};
  }
`;

interface RowProps {
  webhook: IntegrationType;
  isSelected: boolean;
  hasChanges: boolean;
  onSelect: (action: IntegrationType) => void;
  onDeleteWebhook: (id: string) => void;
  onUpdateWebhook: (updates: Partial<IntegrationType>) => void;
  onResetWebhook: (id: string) => void;
}

export function EditWebhookRow({
  webhook,
  hasChanges,
  onDeleteWebhook,
  onUpdateWebhook,
  onResetWebhook,
  isSelected,
  onSelect,
}: Readonly<RowProps>) {
  const {
    mRefId,
    mTitle,
    mDescription,
    mProvider,
    mAllowedEntities,
    mInvokeUrl,
    mTypes,
    mUpdatedAt,
    endpoint,
    mActive,
    externalId,
  } = webhook;

  const [title, setTitle] = useState(mTitle);
  const [localEndpoint, setLocalEndpoint] = useState(endpoint ?? mInvokeUrl ?? '');
  const [description, setDescription] = useState(mDescription ?? '');
  const [provider, setProvider] = useState(mProvider ?? '');
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const toggleMType = useCallback(
    (type: ConfigurableActionMTypes) => {
      onUpdateWebhook({ mTypes: [type] });
    },
    [mTypes, onUpdateWebhook],
  );

  const selectedLabel = useMemo(() => {
    const selectedMtypes = mTypes ?? mAllowedEntities?.mType ?? [];
    const types = selectedMtypes?.join(', ');
    if (types) return types;
    return '';
  }, [mTypes, mAllowedEntities]);

  const handleBlur = useCallback(() => {
    onUpdateWebhook({
      mTitle: title,
      mDescription: description.length === 0 ? null : description,
      mProvider: provider ?? null,
      endpoint: localEndpoint ?? null,
    });
  }, [title, description, provider, localEndpoint, onUpdateWebhook]);

  return (
    <StyledTableRow $selected={isSelected}>
      <StyledRowCell
        style={hasChanges ? { color: 'orange' } : undefined}
        onClick={() => onSelect(webhook)}
      >
        <span>
          {externalId || mRefId}
          {hasChanges ? '*' : ''}
        </span>
      </StyledRowCell>
      <RowCell>
        <Tooltip title={title}>
          <Input
            value={title}
            onChange={(ev) => setTitle(ev.target.value)}
            onBlur={handleBlur}
            placeholder="Type menu label here.."
          />
        </Tooltip>
      </RowCell>
      <RowCell>
        <Tooltip title={description}>
          <Input
            value={description}
            onChange={(ev) => setDescription(ev.target.value)}
            onBlur={handleBlur}
            placeholder="Optional description"
          />
        </Tooltip>
      </RowCell>
      <RowCell>
        <Tooltip title={provider}>
          <Input
            value={provider}
            onChange={(ev) => setProvider(ev.target.value)}
            onBlur={handleBlur}
            placeholder="Provider"
          />
        </Tooltip>
      </RowCell>
      <RowCell>
        <StyledDiv onClick={(ev) => setRef(ev.currentTarget)}>{selectedLabel}</StyledDiv>
        <Popper anchorEl={ref ?? null} position="bottom">
          <ClickAwayListener onClickAway={() => setRef(null)}>
            <Box padding="5px 15px 5px 5px">
              <VStack alignItems="start">
                {configurableActionMTypesHook
                  .filter((item) => item.functional)
                  .map((item) => {
                    return (
                      <div key={item.type}>
                        <StyledFormControl
                          control={
                            <Checkbox
                              onClick={() => toggleMType(item.type)}
                              selected={(mTypes ?? mAllowedEntities?.mType ?? []).includes(
                                item.type,
                              )}
                            />
                          }
                          label={capitalize(item.type)}
                        />
                      </div>
                    );
                  })}
              </VStack>
              <CloseIcon
                style={{ position: 'absolute', top: '3px', right: '3px' }}
                onClick={() => setRef(null)}
              />
            </Box>
          </ClickAwayListener>
        </Popper>
      </RowCell>
      <RowCell>
        <Tooltip title={localEndpoint}>
          <Input
            value={localEndpoint}
            onChange={(ev) => setLocalEndpoint(ev.target.value)}
            onBlur={handleBlur}
            placeholder="REST endpoint"
          />
        </Tooltip>
      </RowCell>
      <RowCell>
        <LWCheckbox selected={mActive} setValue={() => onUpdateWebhook({ mActive: !mActive })} />
      </RowCell>
      <RowCell>{isoToLocaleShort(mUpdatedAt)}</RowCell>
      <RowCell>
        <HStack justifyContent="start">
          <Tooltip title="Discard changes">
            <ResetButton onMouseDown={() => onResetWebhook(webhook.mRefId)} />
          </Tooltip>
          <Tooltip title="Delete field">
            <DeleteButton onMouseDown={() => onDeleteWebhook(webhook.mRefId)} />
          </Tooltip>
        </HStack>
      </RowCell>
    </StyledTableRow>
  );
}
