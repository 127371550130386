import styled from '@emotion/styled';

import { ResizableHandle } from 'lib/resize';

export const VerticalResizeHandle = styled(ResizableHandle)`
  height: 100%;
  width: 6px;
  position: relative;
  &[data-resize-handle-state='hover']:after,
  &[data-resize-handle-state='drag']:after {
    background: ${({ theme }) => theme.palette.dina.onFocus};
  }
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 3px;
    left: 2px;
  }
`;
export const HorizontalResizeHandle = styled(ResizableHandle)`
  width: 100%;
  height: 6px;
  position: relative;
  &[data-resize-handle-state='hover']:after,
  &[data-resize-handle-state='drag']:after {
    background: ${({ theme }) => theme.palette.dina.onFocus};
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    top: 2px;
  }
`;
