import { useMemo } from 'react';
import { Item, Separator, Submenu } from 'react-contexify';

import 'react-contexify/dist/ReactContexify.css';

import { Menu } from './styled';

const MENU_ID = 'menu-id';

function ContextMenu({ id = MENU_ID, menuItems = [], onClick, ...rest }) {
  const children = useMemo(() => {
    const elements = [];

    menuItems.forEach((item) => {
      if (item.separator) {
        elements.push(<Separator key={item.id} />);
        return;
      }

      if (item.children?.length > 0) {
        elements.push(
          <Submenu label={item.label} id={item.id ?? item.label} key={item.id ?? item.label}>
            {item.children.map((subItem) => {
              if (subItem.separator) return <Separator key={subItem.id} />;
              return (
                <Item id={subItem.id ?? subItem.label} key={subItem.label} onClick={onClick}>
                  {subItem.label}
                </Item>
              );
            })}
          </Submenu>,
        );
        return;
      }

      elements.push(
        <Item id={item.id || item.label} key={item.label} onClick={onClick}>
          {item.label}
        </Item>,
      );
    });

    return elements;
  }, [menuItems]);

  return (
    <Menu id={id} style={{ zIndex: 2400 }} {...rest}>
      {children}
    </Menu>
  );
}

export default ContextMenu;
