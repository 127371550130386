import { useEffect, useMemo, useState } from 'react';
import {
  closestCenter,
  // eslint-disable-next-line sort-imports
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  // eslint-disable-next-line sort-imports
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useGetOptionList } from 'api/optionLists/useGetOptionList';
import { useGetOptionLists } from 'api/optionLists/useGetOptionLists';
import { ReactComponent as Add } from 'assets/icons/systemicons/plus_small.svg';
import { Button } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import { ChoiceField } from 'components/mdfEditor/fields/choice/ChoiceField';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Scrollbar from 'components/scrollbar';
import { HStack, VStack } from 'layouts/box/Box';
import { Alternative, FieldTypeEnum, OptionList } from 'types/graphqlTypes';

import { FakeInput, MultiChoiceWrapper, OptionWrapper, Remove } from '../styled';
import { createAlternative } from '../utils';

import { SortableItemWrapper } from './SortableItemWrapper';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  alternatives: Alternative[];
  savedOptionList: string | null;
  doUpdate: (list: OptionList | null, alts: Alternative[]) => void;
  openOptionList: (id: string) => void;
}

export function EditAlternativesDialog({
  open,
  setOpen,
  alternatives,
  savedOptionList,
  openOptionList,
  doUpdate,
}: Readonly<Props>) {
  const [options, setOptions] = useState<Alternative[]>([]);
  const [selectedListId, setSelectedListId] = useState<string | null>(savedOptionList);
  const { optionLists } = useGetOptionLists();
  const { optionList, loading } = useGetOptionList(selectedListId, true);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setOptions(alternatives);
  }, [alternatives]);

  useEffect(() => {
    setSelectedListId(savedOptionList);
  }, [savedOptionList]);

  const optionListAlternatives: Alternative[] = useMemo(() => {
    return optionLists.map((list) => {
      return {
        id: list.id,
        value: list.id,
        label: list.label,
      };
    });
  }, [optionLists]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const oldIndex = options.findIndex((item) => item.id === active.id);
    const newIndex = options.findIndex((item) => item.id === over?.id);
    const updatedOrder = arrayMove(options, oldIndex, newIndex);
    setOptions(updatedOrder);
  };

  const confirmChanges = () => {
    doUpdate(optionList, options);
    setOpen(false);
  };

  const removeAlternative = (alternative: Alternative) => {
    setOptions([...options.filter((alt) => alt.id !== alternative.id)]);
  };

  const addOption = () => {
    const newOption = createAlternative('value');
    setOptions([...options, newOption]);
  };

  const handleOptionChange = (
    newValue: string,
    prop: 'label' | 'value',
    alternative: Alternative,
    index: number,
  ) => {
    const copy = [...options];
    const newAlt: Alternative =
      prop === 'label'
        ? { id: alternative.id, label: newValue, value: alternative.value }
        : { id: alternative.id, label: alternative.label, value: newValue };
    copy.splice(index, 1, newAlt);
    setOptions([...copy]);
  };

  const duplicateValues = useMemo(() => {
    const fSet = new Set<string>();
    const duplicates: string[] = [];
    options.forEach((opt) => {
      if (fSet.has(opt.value)) {
        duplicates.push(opt.value);
      } else {
        fSet.add(opt.value);
      }
    });
    return duplicates;
  }, [options]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '600px', maxWidth: '800px' }}
    >
      <Dialog.Header>Configure options</Dialog.Header>
      <Dialog.Body>
        <HStack alignItems="end" gap="10px" justifyContent="space-between" margin="0 0 10px 0">
          <ChoiceField
            editorId="AlternativesDialog"
            fieldModel={{
              fieldId: 'optionLists',
              type: FieldTypeEnum.choice,
              defaultValue: { value: null },
              alternatives: optionListAlternatives,
            }}
            fieldSettings={null}
            defaultFieldSettings={{
              fieldId: 'optionLists',
              label: 'External option list',
              visible: true,
              hint: '',
            }}
            value={selectedListId}
            setValue={(val) => setSelectedListId(val as string | null)}
            errorMessage={undefined}
            style={{ width: '240px' }}
            view="default"
          />
          <Button
            width={120}
            height={32}
            variant="outlined"
            usage="outlined"
            onClick={addOption}
            title={
              optionList !== null
                ? 'External lists can only be edited from the list itself'
                : 'Add option'
            }
            disabled={optionList !== null}
          >
            <Add className="skipOverride" />
            Add option
          </Button>
          {optionList && (
            <Button
              width={120}
              height={32}
              variant="outlined"
              usage="outlined"
              onClick={() => openOptionList(optionList.id)}
            >
              Go to list
            </Button>
          )}
        </HStack>
        <MultiChoiceWrapper>
          <Scrollbar
            valueChanged={undefined}
            closeToBottom={undefined}
            top={undefined}
            bottom={undefined}
            dark={undefined}
            showHorizontal={undefined}
          >
            {optionList ? (
              <VStack>
                {optionList.alternatives.map((alt) => (
                  <HStack
                    key={alt.id}
                    justifyContent="start"
                    width="100%"
                    gap="20px"
                    margin="4px 0 0 0 "
                  >
                    <FakeInput>{alt.label}</FakeInput>
                    <FakeInput>{alt.value}</FakeInput>
                  </HStack>
                ))}
              </VStack>
            ) : (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext items={options} strategy={verticalListSortingStrategy}>
                  {options.map((alt, i) => (
                    <SortableItemWrapper key={alt.id} item={alt}>
                      <OptionWrapper>
                        <StyledTextField
                          variant="filled"
                          value={alt.label}
                          onChange={(ev) => handleOptionChange(ev.target.value, 'label', alt, i)}
                        />
                        <StyledTextField
                          name="value"
                          variant="filled"
                          value={alt.value}
                          onChange={(ev) => handleOptionChange(ev.target.value, 'value', alt, i)}
                          error={Boolean(duplicateValues.includes(alt?.value))}
                        />
                        <Remove className="remove" onClick={() => removeAlternative(alt)} />
                        {duplicateValues.includes(alt?.value) && (
                          <FormControl error>
                            <FormHelperText id={alt?.value}>Duplicate Value</FormHelperText>
                          </FormControl>
                        )}
                      </OptionWrapper>
                    </SortableItemWrapper>
                  ))}
                </SortableContext>
              </DndContext>
            )}
          </Scrollbar>
        </MultiChoiceWrapper>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label="Confirm"
          onConfirm={confirmChanges}
          title={duplicateValues.length > 0 ? 'Duplicate options detected' : ''}
          disabled={loading || duplicateValues.length > 0}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
