import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { keyBy } from 'lodash';

import { STANDARD_ORDER_FORM_KEYS } from 'api/commonKeys';
import { MemberType } from 'types/graphqlTypes';
import { invariant } from 'types/invariant';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

export const ORDER_FORM_QUERY = gql`
  query GetAllOrderForms($input: GetMemberOfTypeInput) {
    getMembersOftype(input: $input) {
      ${STANDARD_ORDER_FORM_KEYS}
    }
  }
`;

export const ORDER_FORM_SPACE_QUERY = gql`
  query GetOrderFormsForMember($input: GetMemberInput) {
    getMembers(input: $input) {
      ${STANDARD_ORDER_FORM_KEYS}
    }
  }
`;

export interface FetchForms {
  getMembersOftype: MemberType[];
}

export interface FetchBySpaceInput {
  getMembers: MemberType[];
}

export const normalizeOrderForm = (member: MemberType): OrderFormMemberType => {
  invariant(member.mId, 'Expected mId for order_form');
  invariant(member.mRefId, 'Expected mRefId for order_form');
  invariant(member.configs, 'Expected configs for order_form');

  return {
    mId: member.mId,
    mRefId: member.mRefId,
    mSecId: member.mSecId,
    mDescription: member.mDescription ?? 'Form',
    mTitle: member.mTitle ?? 'Menu',
    mColor: member.mColor,
    configs: member.configs,
  };
};

export const useGetOrderFormsForSpace = (spaceId: string) => {
  const { data, loading, error } = useQuery<FetchBySpaceInput>(ORDER_FORM_SPACE_QUERY, {
    variables: {
      input: {
        mId: spaceId,
        mType: 'order_form',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const orderForms = data?.getMembers?.map(normalizeOrderForm) ?? [];

  return { orderForms, loading, error };
};

/**
 * Retrieve all order forms
 * @returns array of OrderFormMemberType
 */
export const useGetOrderForms = () => {
  const { data, loading } = useQuery<FetchForms>(ORDER_FORM_QUERY, {
    variables: {
      input: {
        mType: 'order_form',
      },
    },
    fetchPolicy: 'cache-first',
  });

  const orderForms = data?.getMembersOftype?.map(normalizeOrderForm) ?? [];

  const keyedOrderForms = useMemo(() => {
    return keyBy(orderForms, (form) => form.mRefId);
  }, [orderForms]);

  return { orderForms, keyedOrderForms, loading };
};
